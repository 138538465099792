import plusButton from '../../images/icons/plus-icon.png'
import { useFirestore, useFirestoreMeasureMoments, useFirestoreID, useFirestoreGeneral, useFirestoreOrderBy } from "../../firebase/useFirestore";
import { useState, useContext, useEffect } from "react";
import { client } from '../../hooks/Client';
import uuid from "react-uuid";
import deleteIcon from '../../images/icons/delete-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import EditIcon from "../../images/icons/edit-icon.png";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import MeasureMoment from "../../components/Research/MeasureMoment";
import Tooltip from "../../components/common/Tooltip";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import { ResearchBuilderSystemMessage, ResearchBuilderToolCallContent, ResearchBuilderSaveMessage } from "../../hooks/impactAI/Prompts"
import DeleteModal from "../../components/common/DeleteModal";
import TargetgroupMeta from '../../components/stakeholders/TargetgroupMeta';
import useSettings from "../../hooks/Settings";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PageHeader from "../../components/layout/PageHeader.jsx";
import PageNotice from "../../components/layout/PageNotice";
const ResearchBuilder = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        position: [postition, setPosition],
        extraData: [extraData, setExtraData]
    } = useContext(ImpactAI);

    // State
    const [editQuestionnaire, setEditQuestionnaire] = useState('none')
    const [showTable, setShowTable] = useState('none')
    const [title, setTitle] = useState(null)
    const [editTargetgroup, setEditTargetgroup] = useState('none')
    const [pageDocid, setPageDocid] = useState('123')
    const [pageID, setPageID] = useState('123')
    const [researchHasQuestionnaire, setResearchHasQuestionnaire] = useState(false)
    const [questionnaireID, setQuestionnaireID] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')

    // Hooks
    const route = Location()[3]
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    Modal.setAppElement('#root');
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    // Firestore
    const researches = useFirestoreID('Research', route ? route : '123')
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')
    const researchQuestionnaireFields = useFirestoreGeneral('QuestionnaireFields', 'QuestionnaireID', questionnaireID ? questionnaireID : '123')
    const measureMoments = useFirestoreMeasureMoments(route ? route : '123')
    const stakeholders = useFirestore('Stakeholders')

    console.log(researches)

    const deleteResearch = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .delete()

    }

     //ImpactAI
     const pageSystemMessage = ResearchBuilderSystemMessage(title)
     const pageToolCallContent = ResearchBuilderToolCallContent()
     const pageSaveMessage = ResearchBuilderSaveMessage()
     const pageStartMessage = `Welkom, ${auth?.ForName}. Hier gaan we het onderzoek "${title}" verder inhoud geven.
     Kan ik je ergens mee helpen?`
     const pageCollection = 'MeasureMoments'
     const pageField = 'Title'
     const pageParentId = pageID
     const pageType = 'researchBuilder'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
        setPosition(measureMoments ? measureMoments.length + 1 : 0)
    }, [auth,title, measureMoments, pageID])

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setDeleteModal(!deleteModal)
    }

    // Hide the table when there is no questionnaire selected
    const hideTable = () => {
        if(measureMoments && measureMoments.length > 0){
            setShowTable('none')
        } else {
            setShowTable('block')
        }
    }

    useEffect(() => {
        hideTable()
    }, [researches])

    // Set researchmeta in state
    useEffect(() => {
        researches && researches.map(research => (
            setTitle(research.Title),
            setPageDocid(research.docid),
            setPageID(research.ID),
            setQuestionnaireID(research.QuestionnaireID),
            setResearchHasQuestionnaire(research.QuestionnaireID !== ''),
            setExtraData(research.QuestionnaireID)
        ))
    }, [researches])

    // Add measure moment
    const addMoment = (e) => {

        const questionnaireID = e.target.dataset.questionnaireid
        const researchID = e.target.dataset.researchid
        const researchTitle = e.target.dataset.researchtitle

        const position = measureMoments.length + 1

        db.collection('MeasureMoments')
        .doc()
        .set({
            ResearchID: researchID,
            ResearchTitle: researchTitle,
            Moment: new Date(),
            Title: '',
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            QuestionnaireID: questionnaireID,
            Responses: 0,
            Position: position
        })
    }

    // Select questionnaire
    const selectQuestionnaire = (e) => {
        const questionnaireID = e.target.dataset.id
        const docid = e.target.dataset.researchdocid

        db.collection('Research')
        .doc(docid)
        .update({
            QuestionnaireID: questionnaireID,
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Connect questionnaire to research
    const connectQuestionnaire = (questionnaireID, questionnaireTitle, docid) => {

    db.collection('Research')
        .doc(docid)
        .update({
            QuestionnaireID: questionnaireID,
            QuestionnaireTitle: questionnaireTitle

        })
        .then(() => {
            setSaved('flex')
            setIsOpen(false);
            setEditQuestionnaire('none')
        })
    }

    // Create questionnaire
    const addQuestionnaireHandler = (e) => {

        const docid = e.target.dataset.docid
        const researchTitle = e.target.dataset.researchtitle

        const questionaireId = uuid()
        const title = `Vragenlijst onderzoek ${researchTitle}`

        db.collection('Questionnaires')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: questionaireId,
            Timestamp: timestamp,
            Title: title,
            Description: '',
            Position: questionnaires.length + 1
        })
        .then(() => {
            connectQuestionnaire(questionaireId, title, docid)
        })
    }

    // Delete questionnaire
    const deleteQuestionnaire = () => {

        db.collection('Questionnaires')
        .doc(deleteDocid)
        .delete()
        .then(() => {
            setSaved('flex')
        })
    }

    // Change research title
    const changeResearchTitle = (e) => {
        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('Research')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Change targetgroup
    const changeTargetgroupHandler = (e) => {
            
        const targetgroupID = e.target.options[e.target.selectedIndex].value
        const targetgroupTitle = e.target.options[e.target.selectedIndex].dataset.title
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .update({
            TargetgroupID: targetgroupID,
            TargetgroupTitle: targetgroupTitle

        })
        .then(() => {
            setSaved('flex')
            setEditTargetgroup('none')
        })
    }

    // Toggle option to send a copy of the research results to the repondent after the questionaire is send.
    const toggleSendCopyOption = (e) => {

        const value = e.target.dataset.value 
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .update({
            SendCopyOption: value
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

  return (
    <div className="main">
        <div className="main-container" >
            <PageHeader 
                heading={'Onderzoek aanpassen'}
                description={`Hier kun je de onderzoeken aanpassen. Voeg meetmomenten toe en koppel een vragenlijst.`}
            />
            <PageNotice 
                text={<span dangerouslySetInnerHTML={{ __html: 'Vergeet niet om de vragenlijsten aan te passen nadat je er een hebt aangemaakt' }} />}
            />
            <div id='research-details-container'>
                {researches && researches.map(research => (
                    <div id='research-details-item-container' key={research.ID}>
                        <div className="table-container dashboard-container">
                            <h3>Titel</h3>
                            <input type="text" data-docid={research.docid} defaultValue={research.Title} onChange={changeResearchTitle} />
                        </div>

                        <div className="table-container dashboard-container">
                            <h3>Doelgroep</h3>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                                </tr>
                                <tr>
                                    <td> 
                                        <div className='edit-type-container'>
                                            {research.TargetgroupID 
                                            ?
                                                <TargetgroupMeta targetgroupID={research.TargetgroupID}/>
                                            :
                                                <p>Nog geen doelgroep geselecteerd</p>
                                            }
                                            <Tooltip content={'Doelgroep selecterem'}  top='-60px'>
                                                <img src={EditIcon} alt="" onClick={() => editTargetgroup === 'none' ? setEditTargetgroup('block') : setEditTargetgroup('none')}/>
                                            </Tooltip>
                                        </div>
                                        <select name="" id="" data-docid={research.docid} defaultValue={research.QuestionnaireID} onChange={changeTargetgroupHandler} style={{display:editTargetgroup}}>
                                            <option value="">-- Selecteer een doelgroep --</option>
                                            {stakeholders && stakeholders.map(item => (
                                                <option key={item.ID} value={item.ID} data-title={item.Organisation} >{item.Organisation}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="table-container dashboard-container" >
                            <h3>Meetmomenten</h3>
                            <MeasureMoment research={research}/>
                            <div className='add-item-table-row-container'>
                                <Tooltip content={'Meetmoment toevoegen'} width={'200px'} top='-45px'>
                                    <img 
                                    className='add-item-button' 
                                    data-questionnaireid={research.QuestionnaireID} 
                                    src={plusButton} alt="" 
                                    data-researchid={research.ID} 
                                    data-researchtitle={research.Title} 
                                    onClick={addMoment} 
                                    />
                                </Tooltip>
                            </div>
                            <p className='table-delete-icon' data-docid={research.docid} onClick={deleteResearch} src={deleteIcon} alt="" />
                        </div>

                        <div className="table-container dashboard-container" style={{display: measureMoments.length > 0 ? 'block' : 'none'}}>
                            <h3>Vragenlijsten</h3>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>SELECTEER</th>
                                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                                    <th style={{backgroundColor: secundairyColor}}>AANTAL VRAGEN</th>
                                    <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                                    <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                                </tr>
                                {questionnaires && questionnaires.map(questionnaire => (
                                    <tr key={questionnaire.ID} >
                                        <td>
                                        <input 
                                        type="radio" 
                                        name={`questionnaire-${research.ID}`} 
                                        data-researchdocid={research.docid} 
                                        defaultChecked={research.QuestionnaireID === questionnaire.ID} 
                                        data-id={questionnaire.ID} 
                                        onChange={selectQuestionnaire} 
                                        />

                                        </td>
                                        <td>{questionnaire.Title}</td>
                                        <td>{researchQuestionnaireFields.length}</td>
                                        <td>
                                            <EditOutlinedIcon
                                            data-questionnaireid={questionnaire.ID} 
                                            data-title={questionnaire.Title} 
                                            data-docid={research.docid} 
                                            onClick={() => history.push(`/${client}/questionairebuilder/${questionnaire.ID}/${research.ID}`)}
                                            />
                                        </td>
                                        <td>
                                            <img className='table-delete-icon' src={deleteIcon} data-deletename={questionnaire.Title} data-docid={questionnaire.docid} onClick={deleteModalHandler}/>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                            <div className='add-item-table-row-container'>
                                <Tooltip content={'Vragenlijst toevoegen'} width={'250px'} top='-45px'>
                                    <img 
                                    className='add-item-button' 
                                    data-questionnaireid={research.QuestionnaireID} 
                                    src={plusButton} alt="" 
                                    data-researchid={research.ID} 
                                    data-researchtitle={research.Title} 
                                    onClick={addQuestionnaireHandler} 
                                    />
                                </Tooltip>
                            </div>

                              
                        </div>

                        <div className="table-container dashboard-container">
                            <h3>Instellingen</h3>
                            <div className='reasearch-builder-option-container'>
                                <div className='research-builder-options-title-container'>
                                    <p><b>Resultaten downloaden</b></p>
                                    <p>Laat repondenten een kopie van de resultaten downloaden na het afronden van de vragenlijst.</p>
                                </div>
                                <div className='research-builder-options-state-container'>
                                    <p 
                                    style={{
                                        color: research.SendCopyOption === 'on' ? 'green' : 'black',
                                        fontWeight: research.SendCopyOption === 'on' ? 'bold' : 'normal' 
                                    }} 
                                    data-docid={research.docid} data-value={'on'} 
                                    onClick={toggleSendCopyOption}
                                    >
                                        Aan
                                    </p>
                                    <p>/</p>
                                    <p
                                    style={{
                                        color: research.SendCopyOption === 'off' || research.SendCopyOption === undefined ? 'red' : 'black',
                                        fontWeight: research.SendCopyOption === 'off' || research.SendCopyOption === undefined ? 'bold' : 'normal'
                                      }}
                                    data-docid={research.docid}
                                    data-value={'off'}
                                    onClick={toggleSendCopyOption}
                                    >
                                    Uit
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteQuestionnaire} deleteName={deleteName} />
        </div>
    </div>
  )
}

export default ResearchBuilder
