import { useEffect, useState } from "react";
import { useFirestoreGeneral} from "../../firebase/useFirestore";
import OutputMultiLineGraph from '../../components/Visualisations/outputs/OutputMultiLineGraph'
import useSettings from "../../hooks/Settings";

const StatusData = () => {
    // State 
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor;

     // Firestore
     const personasActive = useFirestoreGeneral('Personas', 'Status', 'active')
     const personasPre = useFirestoreGeneral('Personas', 'Status', 'pre-registration')
     const personasInactive = useFirestoreGeneral('Personas', 'Status', 'inactive')
     const personasOut = useFirestoreGeneral('Personas', 'Status', 'outflow')

     useEffect(() => {

        setData([
            { name: 'Vooraanmelding', value: personasPre.length },
            { name: 'Actief', value: personasActive.length },
            { name: 'Inactief', value: personasInactive.length },
            { name: 'Uitstroom', value: personasOut.length }
            ])
        }
        ,[personasPre, personasActive, personasInactive, personasOut]
    )

    const bars = [
        { Title: 'value', Color: secundairyColor },
    ]


  return (
    <OutputMultiLineGraph
        isLoading={isLoading}
        data={data}
        graphType={'bar'}
        outputs={bars}
        growth={{ average: 0, perOutput: {} }}
        height={200}
    />
  )
}

export default StatusData