import { useFirestoreAllArrayContains } from '../../firebase/useFirestore';
import { client } from '../../hooks/Client';
import PageHeader from '../../components/layout/PageHeader';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import deleteIcon from '../../images/icons/delete-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow';
import Tooltip from '../../components/common/Tooltip';
import { useState, useContext } from 'react';
import useSettings from '../../hooks/Settings';
import DeleteModal from "../../components/common/DeleteModal";
import plusButton from '../../images/icons/plus-icon.png'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { useHistory } from "react-router-dom";
import TOCProgress from '../../hooks/TOCProgress';
import NewProjectModal from '../../components/portfolio/NewProjectModal';
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/app';
import { Auth } from '../../StateManagment/Auth'
import AddExcistingProjectModal from '../../components/portfolio/AddExcistingProjectModal';
import { db } from '../../firebase/config';
import SyncCounter from '../../components/portfolio/SyncCounter';

const Projects = () => {
    // Context
    const [auth] = useContext(Auth)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [projectId, setProjectId] = useState('')
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [newProjectModal, setNewProjectModal] = useState(false)
    const [projectName, setProjectName] = useState('')
    const [logo, setLogo] = useState('')
    const [addExcistingModal, setAddExcistingModal] = useState(false)
    const [selectedProjects, setSelectedProjects] = useState([]);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    const progress = TOCProgress()

    // Firestore
    const projects = useFirestoreAllArrayContains('CompagnyMeta', 'Parent', client)

    // Delete modal 
    const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename
        const id = e.target.dataset.id

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setProjectId(id)
        setDeleteModal(!deleteModal)
    }

    // Add project
    const addProject = async () => {
        
        const id = uuid()

        // Create a new project
        const createProjectAccount = firebase.functions().httpsCallable('createProjectAccount');

        // Data for new project
        const projectData = {
            compagny: projectName,
            logo: logo,
            trialType: 'compleet',
            ImpactManager: 'Deccos',
            Parent: client,
            Subscription: 'paid',
            CompagnyId: id,
            Auth: auth.docid
        }

        // Create project and save user as team member
        await createProjectAccount({ data: projectData })
        
        // Navigate to new project
        history.push(`/${client}/portfolioprojectdetails/${id}`)
        
    }

    // Delete project
    const deleteProject = () => {

        db.collection('Notifications')
        .add({
            Type: 'delete-project',
            Reciever: projectId,
            Sender: client,
            Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            Read: false,
            Accepted: false
        })
    }

    // Add excisting project
    const addExcistingProject = () => {
        
        selectedProjects.forEach(async (project) => {

            await db.collection('Notifications').add({
                Type: 'parent-request',
                Reciever: project,
                Sender: client,
                Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                Read: false,
                ID: uuid(),
                Accepted: false
            });

            setSelectedProjects([])
            setAddExcistingModal(false)
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Projecten'}
                description={`Hier vind je een overzicht van alle projecten uit je portfolio`}
                AI={false}
            />
        <div className="table-container dashboard-container">
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                    <th style={{backgroundColor: secundairyColor}}>SYNCHRONISATIES</th>
                    <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                    <th style={{backgroundColor: secundairyColor}}>PROJECT OMGEVING</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                    {projects && projects.map((project, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={project}
                            input={
                                    <p>{project.CommunityName}</p>
                            }
                            parentIndex={index}
                            collection={'CompagnyMeta'}
                            itemArray={projects}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={project.CommunityName}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td>
                                    <img 
                                    className='table-delete-icon' 
                                    src={plusButton} 
                                    alt="" 
                                    onClick={() => history.push(`/${client}/synchronisationsportfolio/${project.CompagnyID}`)} 
                                    />
                                    <div className='portfolio-projects-sync-counter-container' id='indicator-effect-icons-container'>
                                        <p><SyncCounter id={project.CompagnyID} /></p>
                                    </div> 
                                </td>
                                <td>
                                    <SearchOutlinedIcon onClick={() => history.push(`/${client}/portfolioprojectdetails/${project.CompagnyID}`)} />
                                </td>
                                <td>
                                    <a href={`https://impactdashboard.deccos.nl/${project.CompagnyID}/home`} target="_blank"><LaunchOutlinedIcon/></a>
                                    
                                </td>
                                <td>
                                    <Tooltip content='Project verwijderen' top='-60px'>
                                        <img className='table-delete-icon' data-id={project.CompagnyID} data-docid={project.docid} data-deletename={project.CommunityName} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Project toevoegen'} onClick={() => setShowAddContainer(true)} />
                <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                    <div className="add-options-container-item-container" onClick={() => setNewProjectModal(true)}>
                        <img src={plusButton} alt="" />
                        <p>Nieuw project toevoegen</p>
                    </div>
                    <div className="add-options-container-item-container" onClick={() => setAddExcistingModal(true)}>
                        <img src={plusButton} alt="" />
                        <p>Bestaand project toevoegen</p>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal 
        deleteModal={deleteModal} 
        setDeleteModal={setDeleteModal} 
        setDeleteDocid={setDeleteDocid}
        deleteItem={deleteProject} 
        deleteName={deleteProject} 
        />
        <NewProjectModal 
        newProjectModal={newProjectModal} 
        setNewProjectModal={setNewProjectModal} 
        setProjectName={setProjectName} 
        setLogo={setLogo} 
        logo={logo}
        addProject={addProject} 
        />
        <AddExcistingProjectModal
        addExcistingModal={addExcistingModal}   
        setAddExcistingModal={setAddExcistingModal}
        addExcistingProject={addExcistingProject}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        />
    </div>
  )
}

export default Projects