import { useFirestoreGeneral } from '../../firebase/useFirestore'


const SelectOutput = ({activityId}) => {

    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityId ? activityId : '')

  return (
    <>
        {outputs && outputs.map((item) => (
            <option key={item.ID} value={item.ID}>{item.Title}</option>
        ))}
    </>
  )
}

export default SelectOutput