import Modal from 'react-modal';
import { useFirestoreAllOrderBy } from '../../firebase/useFirestore';
import Select from 'react-select';

const AddExcistingProjectModal = ({setAddExcistingModal, addExcistingModal, addExcistingProject, selectedProjects, setSelectedProjects}) => {

    // Firestore
    const projects = useFirestoreAllOrderBy('CompagnyMeta', 'CommunityName', 'asc');

    // Modal settings
    Modal.setAppElement('#root');

    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    // Update the selectedProjected state to use the selected options in react-select
    const handleProjectsSelectChange = (selectedOptions) => {
        setSelectedProjects(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

     // Format the data for react-select
     const projectOptions = projects && projects.map((project) => ({
        value: project.ID,
        label: project.CommunityName,
    }));

  return (
    <Modal
    isOpen={addExcistingModal}
    onRequestClose={addExcistingModal}
    style={modalStyles}
    contentLabel="Add excisting project"
    >
      <div>
        <div id='delete-modal-title-container'>
          <h1>Bestaand project toevoegen</h1>
        </div>
        <div>
        <Select
            isMulti
            options={projectOptions}
            onChange={handleProjectsSelectChange}
            value={projectOptions && projectOptions.filter(option => selectedProjects.includes(option.value))}
        />
        </div>
        
        <div id='delete-modal-button-container'>
          <button onClick={() => setAddExcistingModal(false)}>Annuleren</button>
          <button id='delete-modal-delete-button' onClick={addExcistingProject} >Opslaan</button>
        </div>
      </div>
  </Modal>
  )
}

export default AddExcistingProjectModal