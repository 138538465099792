import { useState, useEffect } from 'react';
import { useFirestoreGeneral, useFirestoreGeneralOrderBy } from '../../firebase/useFirestore';
import MixedBarChart from '../../components/Visualisations/personas/MixedBarChart';

const LiveReportPersonaPresence = ({persona, startDate, endDate}) => {
    // State
    const [totalSheduledPresence, setTotalSheduledPresence] = useState(0);
    const [totalPresent, setTotalPresent] = useState(0);
    const [presenceData, setPresenceData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Firestore
    // Firestore
    const presenceReports = useFirestoreGeneralOrderBy(
        'PersonaPresenceReports',
        'PersonaID',
        persona.ID ? persona.ID : '',
        'Position',
        'asc',
      );

    // Helper function to parse a custom date string like "maandag 22 juli 2024" into a JavaScript Date object
  const parseCustomDate = (customDateString) => {
    const monthsOfYear = {
      januari: 0,
      februari: 1,
      maart: 2,
      april: 3,
      mei: 4,
      juni: 5,
      juli: 6,
      augustus: 7,
      september: 8,
      oktober: 9,
      november: 10,
      december: 11
    };

    const parts = customDateString ?  customDateString && customDateString.split(' ') : ''; 
    const day = parseInt(parts[1]);
    const month = monthsOfYear[parts[2]];
    const year = parseInt(parts[3]);

    return new Date(year, month, day);
  };

    // Helper function to filter reports by the start and end dates
  const filterReportsByDate = () => {
    if (!presenceReports || presenceReports.length === 0) {
      return [];
    }

    const filteredReports = presenceReports.filter(report => {

      if(report.Date === undefined) {
        return false;
      }

    // Convert the custom date string to a Date object if it's not already
    let reportDate = report.Date;
    if(typeof report.Date === 'string') {
      reportDate = parseCustomDate(report.Date);  
    }

      const start = startDate.toDate();
      const end = endDate.toDate();

    //   console.log(reportDate, start, end)

      // Filter the report if it falls within the selected date range
      return reportDate >= start && reportDate <= end;
    });

    console.log(filteredReports)

    return filteredReports;
  };

  // Helper function to get the sheduled presence for a specific date
  const getSheduledPresence = (startTime, endTime) => {

    const start = new Date(`1970-01-01T${startTime}:00`)
    const end =  new Date(`1970-01-01T${endTime}:00`)
    const diff = end - start;
    return diff / 1000 / 60 / 60;
    
  };

  // Helper function to get the minutes between two times
  const getMinutesBetweenTimes = (startTime, endTime, correctedStartTime, correctedEndTime, type) => {
    // If the report type is 'absent', return 0
    if(type === 'absent') {
      return 0;
    }

    // If the corrected start and end times are provided, use those instead of the original times
    const start = correctedStartTime !== "" ? new Date(`1970-01-01T${correctedStartTime}:00`) : new Date(`1970-01-01T${startTime}:00`)
    const end = correctedEndTime !== "" ? new Date(`1970-01-01T${correctedEndTime}:00`) : new Date(`1970-01-01T${endTime}:00`)
    const diff = end - start;
    return diff / 1000 / 60 / 60;
  };

   // Helper function to get the simplified date
   const getSimplifiedDate = (date) => {
    const dateObject = new Date(date);
    return dateObject.getTime();
  };

  // Helper function to normalize the date
  const normalizeDate = (reportDate) => {
    if (typeof reportDate === 'string') {
      return reportDate;
    }
    if (reportDate.seconds !== undefined && reportDate.nanoseconds !== undefined) {
      const milliseconds = reportDate.seconds * 1000 + reportDate.nanoseconds / 1000000;
      const dateObject = new Date(milliseconds);
      return formatDateToDutchString(dateObject);
    }
  };

  // Helper function to format the date to a Dutch string
  const formatDateToDutchString = (date) => {
    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} ${monthName} ${year}`;
  };

    // Main function to create the presence data for the selected date range to be shown in the table or graph
  const createPresenceData = () => {
    const presenceData = [];
    let addedTotalHoursOfPresence = 0;
    let addedTotalHoursOfSheduledPresence = 0;

    const filteredReports = filterReportsByDate();  // Get the filtered reports based on date range

    filteredReports && filteredReports.map(report => {
      const startTime = report.Start;
      const endTime = report.End;
      const correctedStartTime = report.CorrectedStart;
      const correctedEndTime = report.CorrectedEnd;
      const type = report.Type;

      const totalHoursOfSheduledPresence = getSheduledPresence(startTime, endTime);
      const totalHoursOfPresence = getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime, type);

      if (!isNaN(totalHoursOfPresence)) {
        addedTotalHoursOfPresence += totalHoursOfPresence;
      }

      addedTotalHoursOfSheduledPresence += totalHoursOfSheduledPresence;

      const simplifiedDate = getSimplifiedDate(report.Date);

      // The 'Geroosterd and Aanwezigheid' fields are displayed in the graph. The absent and present fields are used for the calculations.
      presenceData.push({
        date: normalizeDate(report.Date),
        type: report.Type,
        absent: report.Absent,
        reason: report.Reason,
        Aanwezigheid: report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime),
        Geroosterd: getSheduledPresence(startTime, endTime) - (getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime)),
        present: report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime),
        schedule: getSheduledPresence(startTime, endTime),
        id: report.ID,
        simplifiedDate: simplifiedDate,
        total:(report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime)) - getSheduledPresence(startTime, endTime),
        Position: report.Position,
        docid: report.docid
      });
    });

    const orderedData = presenceData.sort((a, b) => a.simplifiedDate - b.simplifiedDate);
    setPresenceData(orderedData);
    setLoading(false);
    setTotalPresent(addedTotalHoursOfPresence);
    setTotalSheduledPresence(addedTotalHoursOfSheduledPresence);
  };

  // Call the createPresenceData function when the presence reports are loaded
  useEffect(() => {
    if (presenceReports && presenceReports.length > 0) {
      createPresenceData();
    } else {
      setLoading(false);
    }
  }, [presenceReports, startDate, endDate]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const tooltipType = (type) => {
        if (type === 'correct') {
          return 'Correct';
        } else if (type === 'absent') {
          return 'Afwezig';
        } else if (type === 'correction') {
          return 'Gecorrigeerd';
        }
      };

      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0].payload.date ? payload[0].payload.date : 'Geen datum'}</p>
          <h2 className="label" id='presence-tooltip-type'>{tooltipType(payload[0].payload.type)}</h2>
          <p className="desc" style={{ display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Geroosterd: {payload[0].payload.schedule}</p>
          <p className="desc" style={{ display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Aanwezig: {payload[0].payload.present}</p>
          <p className="desc" style={{ display: payload[0].payload.reason === '' ? 'none' : 'block' }}>Reden: {payload[0].payload.reason}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
         <div id='presence-data-outer-container'>
            <div className='presence-data-container'>
                <p><b>Totaal geroosterd</b></p>
                <p>{totalSheduledPresence} uur</p>
                </div>
                <div className='presence-data-container'>
                <p><b>Totaal aanwezig</b></p>
                <p>{totalPresent} uur</p>
                </div>
                <div className='presence-data-container'>
                <p><b>Totaal verschil</b></p>
                <p>{totalPresent - totalSheduledPresence} uur</p>
            </div>
       </div>
       <MixedBarChart 
        data={presenceData} 
        customTooltip={<CustomTooltip />} 
        />
    </div>
  )
}

export default LiveReportPersonaPresence