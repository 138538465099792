import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db } from '../../firebase/config';
import ColorPickerHousestyle from '../common/ColorPickerHousestyle';

const UpdateOutputColors = ({activityID}) => {

    // Firestore 
    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityID ? activityID : '')

    const colorHandler = (e) => {

        const docid = e.target.dataset.docid 
        const color = e.target.value

        console.log(docid)
        console.log(e.target.value)

        db.collection('Outputs')
        .doc(docid)
        .update({
            Color: color
        })
    }


  return (
    <div id="update-output-color-container">
    {outputs &&
        outputs.map((output) => (
            <div key={output.ID}>
                <h3>{output.Title}</h3>
                <ColorPickerHousestyle item={output} onChange={colorHandler} />
            </div>
        ))}
</div>

  )
}

export default UpdateOutputColors