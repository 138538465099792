import { useFirestoreNoCompagny, useFirestoreNoCompagnyGeneralTwoOrderBy, useFirestoreNoCompagnyGeneralOrderBy } from '../../firebase/useFirestore';
import { client } from '../../hooks/Client';
import PageHeader from '../../components/layout/PageHeader';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import deleteIcon from '../../images/icons/delete-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow';
import Tooltip from '../../components/common/Tooltip';
import { useState } from 'react';
import useSettings from '../../hooks/Settings';
import DeleteModal from "../../components/common/DeleteModal";
import plusButton from '../../images/icons/plus-icon.png'
import { useHistory } from "react-router-dom";
import TOCProgress from '../../hooks/TOCProgress';
import Location from "../../hooks/Location"
import AddEffectModal from '../../components/portfolio/AddEffectModal';
import { db, timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import EffectMeta from '../../components/effects/EffectMeta';
import { useEffect } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutputsModal from '../../components/portfolio/AddOutputsModal';
import OutputMeta from '../../components/outputs/OutputMeta';

const Synchronisations = () => {
    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [syncId, setSyncId] = useState('')
    const [addEffectsmodalOpen, setAddEffectsModalOpen] = useState(false)
    const [selectedEffects, setSelectedEffects] = useState([])
    const [projectName, setProjectName] = useState('')
    const [addOutputsModalOpen, setAddOutputsModalOpen] = useState(false)
    const [selectedOutputs, setSelectedOutputs] = useState([])

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    const progress = TOCProgress()  
    const projectId = Location()[3]

    // Firestore
    const syncs = useFirestoreNoCompagnyGeneralTwoOrderBy('Synchronisations', 'Parent', client, 'Project', projectId, 'Timestamp', 'desc');
    const projects = useFirestoreNoCompagny('CompagnyMeta', 'CompagnyID', projectId)
    const notifications = useFirestoreNoCompagnyGeneralOrderBy('Notifications', 'Reciever', client, 'Timestamp', 'desc');

    // Set project name in state
    useEffect(() => {
        projects && projects.map(project => {
            setProjectName(project.CommunityName)
        })
    }, [projects])

    // Delete modal
    const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename
        const id = e.target.dataset.id

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setSyncId(id)
        setDeleteModal(!deleteModal)
    }

    // Add output
    const addOutput = () => {

        selectedOutputs.map( async (output) => {

            const syncId = uuid()
            
            await db.collection('Synchronisations')
            .add({
                Parent: client,
                Project: projectId,
                Output: output.ID,
                ID: syncId,
                Timestamp: timestamp,
                Type: 'Output',
                Status: 'requested',
                Position: syncs.length + 1,
            });

            await  db.collection('Notifications')
            .add({
                Type: 'sync-request',
                Reciever: projectId,
                Sender: client,
                Timestamp: timestamp,
                Read: false,
                ID: uuid(),
                SyncType: 'Output',
                Output: output.ID,
                Position: notifications.length + 1,
                SyncId: syncId
            });

            setAddOutputsModalOpen(false)
        });
       
    };

    // Add effect
    const addEffects = () => {

        selectedEffects.map( async (effect) => {

            console.log(effect)

            const syncId = uuid()
            
            await db.collection('Synchronisations')
            .add({
                Parent: client,
                Project: projectId,
                Effect: effect.ID,
                ID: syncId,
                Timestamp: timestamp,
                Type: 'Effect',
                Status: 'requested',
                Position: syncs.length + 1,
                Standard: (effect.Standard === 'true' && true) || false,
                MSIId: effect.MSIId || '',
            })

            await  db.collection('Notifications')
            .add({
                Type: 'sync-request',
                Reciever: projectId,
                Sender: client,
                Timestamp: timestamp,
                Read: false,
                ID: uuid(),
                SyncType: 'Effect',
                Effect: effect.ID,
                Position: notifications.length + 1,
                SyncId: syncId
            })
        })

        setSelectedEffects([])
        setAddEffectsModalOpen(false)
        
    }

    // Delete sync
    const deleteSync =  async () => {

        await  db.collection('Synchronisations').doc(deleteDocid).update({
            Status: 'deleted'
        })

    }

    // Sync status
    const status = (statusCode) => {
        switch (statusCode) {
          case "requested":
            return { text: "Aangevraagd", color: "#FFA500" };
          case "accepted":
            return { text: "Geaccepteerd", color: "#008000" };
            case "paired":
            return { text: "Gekoppeld", color: "#008000" };
          case "declined":
            return { text: "Geweigerd", color: "#FF0000" };
          case "deleted":
            return { text: "Verwijderd", color: "#000000" };
          default:
            return { text: "Onbekende status", color: "#000000" };
        }
      };

    return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={`Synchronisaties`}
                description={`Bekijk en beheer hier de synchronisaties met ${projectName}.`}
                AI={false}
            />
        <div className="table-container dashboard-container">
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>SYNCHRONISATIE</th>
                    <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                    <th style={{backgroundColor: secundairyColor}}>STATUS</th>
                    <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                    {syncs && syncs.map((sync, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={sync}
                            input={
                                    <p>{sync.Type === 'Effect' ? <EffectMeta item={sync.Effect}/> : <OutputMeta id={sync.Output}/> }</p>
                            }
                            parentIndex={index}
                            collection={'Synchronisations'}
                            itemArray={syncs}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={sync.CommunityName}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td>
                                   <p>{sync.Type}</p>
                                </td>
                                <td>
                                    <p style={{ color: status(sync.Status).color }}>
                                        {status(sync.Status).text}
                                    </p>
                                </td>
                                <td>
                                    <SearchOutlinedIcon onClick={() => history.push(`/${client}/portfoliosyncdetails/${sync.ID}`)} />
                                </td>
                                <td>
                                    <Tooltip content='Project verwijderen' top='-60px'>
                                        <img className='table-delete-icon' data-id={sync.ID} data-docid={sync.docid} data-deletename={sync.CommunityName} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Project toevoegen'} onClick={() => setShowAddContainer(true)} />
                <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                    <div className="add-options-container-item-container" onClick={() => setAddEffectsModalOpen(true)}>
                        <img src={plusButton} alt="" />
                        <p>Effect toevoegen</p>
                    </div>
                    <div className="add-options-container-item-container" onClick={() => setAddOutputsModalOpen(true)}>
                        <img src={plusButton} alt="" />
                        <p>Output toevoegen</p>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteSync} deleteName={deleteName} />
        <AddEffectModal setAddEffectsModal={setAddEffectsModalOpen} addEffectsModal={addEffectsmodalOpen} addEffects={addEffects} selectedEffects={selectedEffects} setSelectedEffects={setSelectedEffects} />
        <AddOutputsModal setAddOutputsModal={setAddOutputsModalOpen} addOutputsModal={addOutputsModalOpen} addOutput={addOutput} selectedOutputs={selectedOutputs} setSelectedOutputs={setSelectedOutputs}/>
    </div>
    )
}

export default Synchronisations