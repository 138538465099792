import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import TotalResponses from '../TotalResponses';

const MultipleMomentLineChart = ({ data, totalResponses }) => {
  // Transform data to recharts-compatible format
  const transformedData = data.map((item) => {
    const transformedResponses = item.responses.reduce((acc, response) => {
      acc[response.name] = response.count; // Use name as key and count as value
      return acc;
    }, {});
    return { name: item.moment, ...transformedResponses };
  });

  // Extract unique keys and colors for the lines
  const keysWithColors = data.length > 0
    ? data[0].responses.map((response) => ({
        key: response.name,
        color: response.color,
      }))
    : [];

  // Custom tooltip for recharts
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Meetmoment: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={`tooltip-${index}`} style={{ color: entry.color }}>
              {`${entry.dataKey}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="90%" height={300}>
        <LineChart
          data={transformedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          {/* Dynamically render lines with respective colors */}
          {keysWithColors.map(({ key, color }) => (
            <Line
              key={`line-${key}`}
              type="monotone"
              dataKey={key}
              stroke={color}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default MultipleMomentLineChart;

  