import {
    useFirestoreArrayContainsOrderBy,
    useFirestoreNoCompagnyTwo
} from "../../firebase/useFirestore";
import { useEffect, useState } from "react";
import IndicatorData from "../Indicators/IndicatorData";    
import { db } from "../../firebase/config";

const EffectData = ({effectId, startDate, endDate, graphType}) => {
    // State
    const [combinedIndicators, setCombinedIndicators] = useState([]);

    // Firestore
    const syncs = useFirestoreNoCompagnyTwo('Synchronisations', 'Effect', effectId ? effectId : '', 'Status', 'paired');
    const questionnaireFields = useFirestoreArrayContainsOrderBy("QuestionnaireFields", "EffectId", effectId ? effectId : "123", "Position", "asc");

    useEffect(() => {
        const combinedIndicators = async () => {
            const array = [];

            // Add questionnaire fields to the array
            questionnaireFields.forEach((questionnaireField) => {
                array.push(questionnaireField);
            });

            // Fetch synced indicators asynchronously
            for (const sync of syncs) {
                // Fetch the synced indicators
                const indicatorsSnapshot = await db.collection('QuestionnaireFields')
                    .where('CompagnyID', '==', sync.Project)
                    .where('EffectId', 'array-contains', sync.ProjectEffect)
                    .get();

                // Process the snapshot asynchronously
                indicatorsSnapshot.forEach((doc) => {
                    const indicator = doc.data();
                    array.push(indicator);
                });
            }
            setCombinedIndicators(array);
        };

        combinedIndicators();
    }, [effectId, syncs, questionnaireFields]);

    return (
        <div>
            {combinedIndicators && combinedIndicators.map(item => (
                <div key={item.ID} className='home-kpi-container'>
                    <IndicatorData indicator={item} startDate={startDate} endDate={endDate} graphType={item.GraphType} docid={item.docid} fieldType={item.Type}/>
                </div>
            ))}
        </div>
    );
};

export default EffectData;
