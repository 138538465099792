import ScrollToTop from "../../hooks/ScrollToTop";
import { useFirestoreID, useFirestore} from "../../firebase/useFirestore"
import { useState, useContext, useEffect } from 'react'
import { db } from "../../firebase/config";
import ButtonClicked from "../../hooks/ButtonClicked";
import saveFile from "../../hooks/SaveFile";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import useSettings from "../../hooks/Settings";
import Tooltip from "../../components/common/Tooltip";
import plusButton from '../../images/icons/plus-icon.png'
import Location from "../../hooks/Location"
import penIcon from '../../images/icons/pen-icon.png'
import personIcon from '../../images/icons/person-icon.png'
import activityIcon from '../../images/icons/activity-icon.png'
import trajectIcon from '../../images/icons/traject-icon.png'
import firebase from "firebase";
import ReportBuilderTargetgroups from "../../components/liveReport/ReportBuilderTargetgroups";
import ReportBuilderActivities from "../../components/liveReport/ReportBuilderActivities";
import ReportBuilderEffects from "../../components/liveReport/ReportBuilderEffects";
import { LiveReportBuilderSystemMessage, LiveReportBuilderToolCallContent, LiveReportBuilderSaveMessage } from "../../hooks/impactAI/Prompts"
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from "../../StateManagment/ImpactAI";
import ReportBuilderResearch from "../../components/liveReport/ReportBuilderResearch";
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import PeriodSelector from "../../components/common/PeriodSelector";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ReportBuilderPersonas from "../../components/liveReport/ReportBuilderPersonas";

const LiveReportBuilder = () => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)
  const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
    } = useContext(ImpactAI);

  // State
  const [editBanner, showEditBanner] = useState(false)
  const [showAddTargetgroup, setShowAddTargetgroup] = useState(false)
  const [showAddActivity, setShowAddActivity] = useState(false)
  const [showAddEffect, setShowAddEffect] = useState(false)
  const [banner, setBanner] = useState(null)
  const [bannerMeta, setBannerMeta] = useState('')
  const [targetgroup, setTargetgroup] = useState('')
  const [activity, setActivity] = useState('')
  const [reportDocid, setReportDocid] = useState('')
  const [effect, setEffect] = useState('')
  const [reportTitle, setReportTitle] = useState('')
  const [ID, setID] = useState('')
const [researchId, setResearchId] = useState('all')
const [showAddResearch, setShowAddResearch] = useState(false)
const [showAddPersona, setShowAddPersona] = useState(false)
const [persona, setPersona] = useState('')

// Hooks
ScrollToTop()
const secundairyColor = useSettings().SecundairyColor
const route = Location()[3]

// Firestore
const reports = useFirestoreID('LiveReports', route ? route : '123')
const compagny = useFirestore("CompagnyMeta")
const targetgroups = useFirestore('Stakeholders')
const activities = useFirestore('Activities')
const effects = useFirestore('OutputEffects')
const research = useFirestore('Research')
const personas = useFirestore('Personas')

//ImpactAI
const pageSystemMessage = LiveReportBuilderSystemMessage(reportTitle)
const pageToolCallContent = LiveReportBuilderToolCallContent()
const pageSaveMessage = LiveReportBuilderSaveMessage()
const pageStartMessage = `Hoi ${auth?.ForName}. Hier ga je het live-rapport '${reportTitle}' inhoud geven.
Wat kan ik voor je betekenen?`
const pageCollection = 'LiveReports'
const pageField = 'Description'
const pageType = 'liveReportBuilder' + ID

useEffect(() => {
    setType(pageType)
    setSystemMessage(pageSystemMessage)
    setStartMessage(pageStartMessage)
    setToolCallContent(pageToolCallContent)
    setSaveMessage(pageSaveMessage)
    setCollection(pageCollection)
    setField(pageField)
    setParentId(ID)
    setExampleMessages([])
    setDocid(reportDocid)
}, [auth, reportTitle, reportDocid, ID])

// Format the start and end date
function formatDate(date) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
} 

// Set report docid and title
useEffect(() => {
    reports && reports.map(item => (
        setReportDocid(item.docid),
        setReportTitle(item.Title),
        setID(item.ID)
    ))
}, [reports]) 

const editBannerToggle = () => {
    showEditBanner(!editBanner)
}

const bannerHandler = (e) => {

    saveFile(e, setBanner, setBannerMeta)

}

const saveBanner = (e) => {
    const docid = e.target.dataset.docid

    ButtonClicked(e, 'Opgeslagen')

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Banner: banner
    })
    .then(() => {
        setSaved(true)
    })

    showEditBanner(false)

}

const titleHandler = (e) => {
    const docid = e.target.dataset.docid

    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Title: title
    })
    .then(() => {
        setSaved(true)
    })
}

const descriptionHandler = (e) => {
    const docid = e.target.dataset.docid

    const description = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Description: description
    })
    .then(() => {
        setSaved(true)
    })
}

const addTargetgroupToggle = (e) => {

    setShowAddTargetgroup(!showAddTargetgroup)
}

const targetgroupHandler = (e) => {
    const targetgroup = e.target.options[e.target.selectedIndex].value

    setTargetgroup(targetgroup)
}

const addTargetgroup = (e) => {
    const docid = e.target.dataset.docid

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Targetgroups: firebase.firestore.FieldValue.arrayUnion(targetgroup)
    })
    .then(() => {
        setSaved(true)
    })

    setShowAddTargetgroup(false)
}

const addActivityToggle = (e) => {
    setShowAddActivity(!showAddActivity)
}

const activityHandler = (e) => {
    const activity = e.target.options[e.target.selectedIndex].value

    setActivity(activity)

}

const addActivity = (e) => {
    const docid = e.target.dataset.docid

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Activities: firebase.firestore.FieldValue.arrayUnion(activity)
    })
    .then(() => {
        setSaved(true)
    })

    setShowAddActivity(false)
}

const addEffectToggle = () => {
    setShowAddEffect(!showAddEffect)
}

const effectHandler = (e) => { 

    const effect = e.target.options[e.target.selectedIndex].value

    setEffect(effect)
    
}

const addEffect = (e) => {
    const docid = e.target.dataset.docid

    db.collection('LiveReports')
    .doc(docid)
    .update({
        KPIs: firebase.firestore.FieldValue.arrayUnion(effect)
    })
    .then(() => {
        setSaved(true)
    })

    setShowAddEffect(false)
}

const researchHandler = (e) => {
    const research = e.target.options[e.target.selectedIndex].value

    setResearchId(research)
}

const targetgroupTitleHandler = (e) => {
    const docid = e.target.dataset.docid
    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        TargetgroupTitle: title
    })
    .then(() => {
        setSaved(true)
    })
}

const activityTitleHandler = (e) => {
    const docid = e.target.dataset.docid
    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        ActivityTitle: title
    })
    .then(() => {
        setSaved(true)
    })
}

const effectTitleHandler = (e) => {
    const docid = e.target.dataset.docid
    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        KPITitle: title
    })
    .then(() => {
        setSaved(true)
    })
}

const addResearchToggle = () => {
    setShowAddResearch(!showAddResearch)
}

const addResearch = (e) => {
    const docid = e.target.dataset.docid

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Researches: firebase.firestore.FieldValue.arrayUnion(researchId)
    })
    .then(() => {
        setSaved(true)
    })

    setShowAddResearch(false)
}

const researchTitleHandler = (e) => {
    const docid = e.target.dataset.docid
    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        ResearchTitle: title
    })
    .then(() => {
        setSaved(true)
    })
}

// Persona title handler
const personaTitleHandler = (e) => {
    const docid = e.target.dataset.docid

    const title = e.target.value

    db.collection('LiveReports')
    .doc(docid)
    .update({
        PersonaTitle: title
    })
    .then(() => {
        setSaved(true)
    })

}

// Add persona toggle
const addPersonaToggle = () => {
    setShowAddPersona(!showAddPersona)
}

// Add persona
const personaHandler = (e) => {
    const persona = e.target.options[e.target.selectedIndex].value

    setPersona(persona)
}

// Add persona
const addPersona = (e) => {
    const docid = e.target.dataset.docid

    db.collection('LiveReports')
    .doc(docid)
    .update({
        Personas: firebase.firestore.FieldValue.arrayUnion(persona)
    })
    .then(() => {
        setSaved(true)
    })

    setShowAddPersona(false)
}

  // Reset hightlights
  useEffect(() => {
    setHightlighted(false)
    setHightlightText('')
},[])

  return (
    <div className="main">
      <div className="main-container" >
        <div className='page-header'>
            <AssessmentOutlinedIcon/>
            <h1>Live-rapport instellingen</h1>
        </div>
        <div id='live-report-builder-content-container'>
          {reports && reports.map(report => (
            <div key={report.ID}>
                <div className="report-builder-container">

                    {/* Banner */}
                    <div className="report-header-item-container">
                        <img className="report-builder-banner" src={report.Banner} alt="" />
                        <Tooltip content={'Banner aanpassen'} width='4%' top='-60px'>
                        <img className="report-builder-edit-banner" src={penIcon} alt="" onClick={editBannerToggle}/>
                        </Tooltip>
                        <input type="file" onChange={bannerHandler} style={{display: editBanner === true ? 'block' : 'none'}}/>
                        <button className="button-simple" data-docid={report.docid} onClick={saveBanner} style={{display: editBanner === true ? 'block' : 'none'}}>Opslaan</button>
                    </div>

                    {/* Logo, period and title*/}
                    {compagny && compagny.map(item => (
                        <div key={item.ID}>

                            <div id='live-report-logo-period-container'>
                                {/* Logo */}
                                <img className="report-logo" src={item.Logo} alt="" />
                            </div>

                            <div id='live-report-period-container'>
                                {/* Periode */}
                                <PeriodSelector page={'livereport'} reportId={report.ID} />
                            </div>

                            {/* Title */}
                            <input 
                            style={{color: secundairyColor}}
                            type="text" 
                            placeholder="Live rapportage titel" 
                            defaultValue={report.Title} 
                            onChange={titleHandler}
                            className="live-report-title-input"
                            data-docid={report.docid}
                            />
                        </div>
                    ))}
                </div>
                <div className="report-inner-container">

                    {/* Description */}
                    <div className="report-head">
                        <textarea 
                        cols="30" 
                        rows="10" 
                        defaultValue={report.Description} 
                        placeholder="Geef een omschrijving van je live rapport"
                        onChange={descriptionHandler}
                        data-docid={report.docid}
                        ></textarea>
                    </div>
                    <div>
                        {/* Targetgroups */}
                        <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                            <img src={personIcon} alt="" />
                            <div className="report-builder-sub-title-container">
                                <input style={{color: secundairyColor}} type="text" placeholder="Voor wie we het doen" data-docid={report.docid} defaultValue={report.TargetgroupTitle} onChange={targetgroupTitleHandler}/>
                            </div>
                        </div>
                        <div className="add-report-builder-item-container">
                            <div className="add-live-report-item-container">
                                <Tooltip content={'Doelgroep toevoegen'} width='4%' top='-60px'>
                                    <img src={plusButton} alt=""  onClick={addTargetgroupToggle}/>
                                </Tooltip>
                            </div>
                            <select name="" id="" style={{display: showAddTargetgroup === true ? 'block' : 'none'}} onChange={targetgroupHandler}>
                                <option value="">-- Selecteer doelgroep --</option>
                                {targetgroups && targetgroups.map(item => (
                                    <option value={item.ID}>{item.Organisation}</option>
                                ))}
                            </select>
                                <button className="button-simple" data-docid={report.docid} onClick={addTargetgroup} style={{display: showAddTargetgroup === true ? 'block' : 'none'}}>Opslaan</button>
                        </div>
                        <div className="report-activities-container">
                            {reports && reports[0].Targetgroups?.map(item => (
                                <ReportBuilderTargetgroups report={item} docid={reportDocid}/>
                            ))}
                        </div>

                        {/* Activities */}
                        <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                            <img src={activityIcon} alt="" />
                            <div className="report-builder-sub-title-container">
                                <input style={{color: secundairyColor}} type="text" placeholder="Wat we doen" data-docid={report.docid} defaultValue={report.ActivityTitle} onChange={activityTitleHandler}/>
                            </div>
                        </div>
                        <div className="add-report-builder-item-container">
                            <div className="add-live-report-item-container">
                                <Tooltip content={'Activiteit toevoegen'} width='4%' top='-60px'>
                                    <img src={plusButton} alt=""  onClick={addActivityToggle}/>
                                </Tooltip>
                            </div>
                            <select name="" id="" style={{display: showAddActivity === true ? 'block' : 'none'}} onChange={activityHandler}>
                                <option value="">-- Selecteer activiteit --</option>
                                {activities && activities.map(item => (
                                    <option value={item.ID}>{item.Activity}</option>
                                ))}
                            </select>
                                <button className="button-simple" data-docid={report.docid} onClick={addActivity} style={{display: showAddActivity === true ? 'block' : 'none'}}>Opslaan</button>
                        </div>
                        <div className="report-activities-container">
                            {reports[0].Activities?.map(item => (
                                <ReportBuilderActivities activity={item} docid={reportDocid} startDate={report.Start} endDate={report.End} />
                            ))}
                        </div>

                        {/* Effecten */}
                        <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                            <img src={trajectIcon} alt="" />
                            <div className="report-builder-sub-title-container">
                                <input style={{color: secundairyColor}} type="text" placeholder="Wat we willen bereiken" data-docid={report.docid} defaultValue={report.KPITitle} onChange={effectTitleHandler}/>
                            </div>
                        </div>
                        <div className="add-report-builder-item-container">
                            <div className="add-live-report-item-container">
                                <Tooltip content={'Effect toevoegen'} width='4%' top='-60px'>
                                    <img src={plusButton} alt=""  onClick={addEffectToggle}/>
                                </Tooltip>
                            </div>
                            <select name="" id="" style={{display: showAddEffect === true ? 'block' : 'none'}} onChange={effectHandler}>
                                <option value="">-- Selecteer effect --</option>
                                {effects && effects.map(item => (
                                    <option value={item.ID}>{item.Effect}</option>
                                ))}
                            </select>
                            <button className="button-simple" data-docid={report.docid} onClick={addEffect} style={{display: showAddEffect === true ? 'block' : 'none'}}>Opslaan</button>
                        </div>
                        <div className="report-activities-container">
                            {reports && reports[0].KPIs?.map(item => (
                                <ReportBuilderEffects effect={item} docid={reportDocid} startDate={report.Start} endDate={report.End} />
                            ))}
                        </div>

                        {/* Researches */}
                        <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                            <BiotechOutlinedIcon />
                            <div className="report-builder-sub-title-container">
                                <input style={{color: secundairyColor}} type="text" placeholder="Onderzoeksresultaten" data-docid={report.docid} defaultValue={report.ResearchTitle} onChange={researchTitleHandler}/>
                            </div>
                        </div>
                        <div className="add-report-builder-item-container">
                            <div className="add-live-report-item-container">
                                <Tooltip content={'Onderzoek toevoegen'} width='4%' top='-60px'>
                                    <img src={plusButton} alt=""  onClick={addResearchToggle}/>
                                </Tooltip>
                            </div>
                            <select name="" id="" style={{display: showAddResearch === true ? 'block' : 'none'}} onChange={researchHandler}>
                                <option value="">-- Selecteer onderzoek --</option>
                                {research && research.map(item => (
                                    <option value={item.ID}>{item.Title}</option>
                                ))}
                            </select>
                            <select name="" id="" style={{display: showAddEffect === true ? 'block' : 'none'}} onChange={researchHandler}>
                                <option value="all">-- Selecteer onderzoek (optioneel) --</option>
                                <option value="all">Alle onderzoeken</option>
                                {research && research.map(item => (
                                    <option value={item.ID}>{item.Title}</option>
                                ))}
                            </select>
                                <button className="button-simple" data-docid={report.docid} onClick={addResearch} style={{display: showAddResearch === true ? 'block' : 'none'}}>Opslaan</button>
                        </div>
                        <div className="report-activities-container">
                        {reports && reports[0].Researches?.map(item => (
                                <ReportBuilderResearch researchId={item} docid={reportDocid} startDate={report.Start} endDate={report.End}/>
                            ))}
                        </div>

                        {/* Personas */}
                        <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                            <GroupsOutlinedIcon />
                            <div className="report-builder-sub-title-container">
                                <input style={{color: secundairyColor}} type="text" placeholder="Deelnemer resultaten" data-docid={report.docid} defaultValue={report.PersonaTitle} onChange={personaTitleHandler}/>
                            </div>
                        </div>
                        <div className="add-report-builder-item-container">
                            <div className="add-live-report-item-container">
                                <Tooltip content={'Persona toevoegen'} width='4%' top='-60px'>
                                    <img src={plusButton} alt=""  onClick={addPersonaToggle}/>
                                </Tooltip>
                            </div>
                            <select name="" id="" style={{display: showAddPersona === true ? 'block' : 'none'}} onChange={personaHandler}>
                                <option value="">-- Selecteer deelnemer --</option>
                                {personas && personas.map(item => (
                                    <option value={item.ID}>{item.Name}</option>
                                ))}
                            </select>
                                <button className="button-simple" data-docid={report.docid} onClick={addPersona} style={{display: showAddPersona === true ? 'block' : 'none'}}>Opslaan</button>
                        </div>
                        <div className="report-activities-container">
                        {reports && reports[0].Personas?.map(item => (
                                <ReportBuilderPersonas personaId={item} startDate={report.Start} endDate={report.End}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LiveReportBuilder