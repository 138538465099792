import { client } from '../../hooks/Client';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import Location from "../../hooks/Location";
import TotalResponses from '../Visualisations/TotalResponses';

const OpenQuestionSlider = ({researchId, field, startDate, endDate }) => {
    // State
    const [responses, setResponses] = useState([]);
    const [visible, setVisible] = useState(0);
    const [printPage, setPrintPage] = useState(false);
    const [totalResponses, setTotalResponses] = useState(0);

    // Hooks
    const page = Location()[2];

    // Default dates
    const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
    const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

    // Fetch responses
    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const responsesSnapshot = await db.collection('QuestionnairesResponses')
                    .where('CompagnyID', '==', client)
                    .where('FieldID', '==', field)
                    .where('ResearchID', '==', researchId)
                    .where('Timestamp', '>=', startDate || defaultStartDate)
                    .where('Timestamp', '<=', endDate || defaultEndDate)
                    .get();
                
                setTotalResponses(responsesSnapshot.docs.length);

                const fetchedResponses = responsesSnapshot.docs.map((doc) => doc.data());
                setResponses(fetchedResponses);
            } catch (error) {
                console.error("Error fetching responses: ", error);
            }
        };

        fetchResponses();
    }, [field, startDate, endDate]);

    // Set page type
    useEffect(() => {
        setPrintPage(page === 'printlivereport');
    }, [page]);

    // Navigation
    const nextIndicator = () => {
        setVisible((prev) => (prev < responses.length - 1 ? prev + 1 : 0));
    };

    const previousIndicator = () => {
        setVisible((prev) => (prev > 0 ? prev - 1 : responses.length - 1));
    };

    return (
        <>
            {printPage ? (
                <div id='live-report-open-question-slider'>
                    {responses.map((response, index) => (
                        <div key={index} id='live-report-open-question-slider'>
                            <p>{index + 1}</p>
                            <p>{response.Input}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <div id='text-reponses-container'>
                    <TotalResponses totalResponses={totalResponses} />
                    {responses.length > 0 && (
                        <div
                            style={{
                                display: 'block',
                            }}
                            id='live-report-open-question-slider'
                        >
                            <p>{responses[visible]?.Input}</p>
                        </div>
                    )}
                    <div id='live-report-effect-indicator-navbar'>
                        <p
                            className="live-report-effect-indicator-navbar-button"
                            id='live-report-open-question-text-indicator-navbar-button'
                            onClick={previousIndicator}
                        >
                            Vorige
                        </p>
                        <div>
                            <p id='live-report-open-question-text-count-indicator'>
                                {responses.length > 0 ? `${visible + 1} van de ${responses.length}` : "Geen reacties"}
                            </p>
                        </div>
                        <p
                            className="live-report-effect-indicator-navbar-button"
                            id='live-report-open-question-text-indicator-navbar-button'
                            onClick={nextIndicator}
                        >
                            Volgende
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default OpenQuestionSlider;
