import Location from "../../hooks/Location"
import { useState, useEffect, useContext } from "react"
import { useFirestoreID } from "../../firebase/useFirestore"
import { SavedIcon } from "../../StateManagment/SavedIcon"
import EffectMeta from "../../components/effects/EffectMeta"
import PersonaGoalData from "../../components/personas/PersonaGoalData"

const PersonaGoalProgress = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    
    // Hooks
    const goalId = Location()[4]

    // Firestore
    const goals = useFirestoreID('PersonaGoals', goalId ? goalId : '')

    // Set title of goal in state
    useEffect(() => {
        goals && goals.forEach(item => {
            setTitle(item.Goal)
            setDescription(item.Description)
        })
    }, [goals])

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Doel voortgang</h1>
                <p>{title}</p>
            </div>  
            <div className='dashboard-container'>
                {goals && goals.map(item => ( 
                    <div key={item.docid}>
                        <PersonaGoalData
                        goal={item}
                        personaId={item.PersonaID}
                        />
                    </div>
                ))}
            </div>         
            
        </div>
    </div>
  )
}

export default PersonaGoalProgress