import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TotalResponses from '../TotalResponses';

const MultipleDevelopmentLineChart = ({data, totalResponses}) => {

    const preprocessData = (dataset) => {
        // Transform the dataset into a flat structure
        return dataset.map((entry) => {
            const transformed = { name: entry.moment }; // Use 'moment' as the key for XAxis
            entry.responses.forEach((response) => {
                transformed[response.name] = response.count; // Set response name as key and count as value
            });
            return transformed;
        });
    };

    const extractOptions = (dataset) => {
        // Extract unique response types and their colors
        const uniqueOptions = {};
        dataset.forEach((entry) => {
            entry.responses.forEach((response) => {
                uniqueOptions[response.name] = response.color;
            });
        });
        return Object.entries(uniqueOptions).map(([name, color]) => ({ name, color }));
    }

    return (
        <>
            <TotalResponses totalResponses={totalResponses} />
            <ResponsiveContainer width="90%" height={400}>
                <LineChart
                    data={data ? preprocessData(data) : []}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                    {data && extractOptions(data).map((option, index) => (
                        <Line
                            key={`line-${index}`}
                            type="monotone"
                            dataKey={option.name}
                            stroke={option.color}
                            strokeWidth={3}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}

export default MultipleDevelopmentLineChart