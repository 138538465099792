import { useFirestoreGeneral } from '../../firebase/useFirestore'
import OutputMeta from './OutputMeta'

const EventOutputs = ({eventId}) => {

  const results = useFirestoreGeneral('Results', 'EventID', eventId ? eventId : '')

  return (
    <div>
      {results && results.map(item => (
        <ul>
          <li><OutputMeta id={item.OutputID}/> - {item.Result}</li>
        </ul>
      ))}
    </div>
  )
}

export default EventOutputs