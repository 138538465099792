import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from 'recharts';
  import TotalResponses from '../TotalResponses';
  

const MultipleMomentBarChart = ({data, totalResponses}) => {

    // Transform data to fit the Recharts format
const transformedData = data && data.map((item) => {
    const transformedResponses = item.responses.reduce((acc, response) => {
      acc[response.name] = response.count; // Use response name as key, count as value
      return acc;
    }, {});
    return { name: item.moment, ...transformedResponses };
  });
  
  // Extract unique keys and colors for the bars
  const keysWithColors = data.length > 0
    ? data[0].responses.map((response) => ({
        key: response.name,
        color: response.color,
      }))
    : [];


  return (
    <>
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={data ? transformedData : []}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            {/* Dynamically render bars with their respective colors */}
            {keysWithColors.map(({ key, color }) => (
              <Bar
                key={`bar-${key}`}
                dataKey={key}
                fill={color}
                activeBar={<Rectangle fill="lightblue" stroke="darkblue" />}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </>
  )
}

export default MultipleMomentBarChart