import React from 'react';
import {
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import TotalResponses from '../TotalResponses';

const ParagraphMomentPieChart = ({ data, totalResponses }) => {

  // Convert the Categories array into the format required for the pie chart
  const pieData =
    data && data[0].Categories && data[0].Categories.length > 0
      ? data[0].Categories.map((category) => ({
          name: category.Title,
          value: category.Words,
          color: category.Color,
        }))
      : [{ name: 'No data', value: 1, color: '#ccc' }];

  // Calculate the total value for percentage calculation
  const totalValue = pieData.reduce((total, entry) => total + entry.value, 0);

  // Custom tooltip content
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
      const percentage = ((value / totalValue) * 100).toFixed(2);
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <p style={{ margin: 0, fontWeight: 'bold' }}>{name}</p>
          <p style={{ margin: 0 }}>{`Percentage: ${percentage}%`}</p>
        </div>
      );
    }
    return null;
  };

  // Custom Legend Renderer
  const renderCustomLegend = (props) => {
    const { payload } = props; // Access the legend items
    return (
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {payload.map((entry, index) => (
          <li key={`legend-item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                marginRight: 8,
              }}
            ></div>
            <span>{`${entry.payload.name} (${entry.payload.value})`}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={300}>
        <RechartsPieChart>
          <Pie
            data={pieData ? pieData : []}
            cx="40%"
            cy="50%"
            innerRadius={0}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
            labelLine={false}
          >
            {pieData && pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={renderCustomLegend} layout="vertical" align="right" verticalAlign="middle" />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ParagraphMomentPieChart;
