import { useHistory } from "react-router-dom";
import { useState } from 'react';
import useSettings from "../../hooks/Settings";
import { useFirestoreGeneralTwo, useFirestoreGeneral, useFirestoreOrderBy, useFirestore } from '../../firebase/useFirestore';
import sentimentAnalyse from '../../components/AI/SentimentAnalyse';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client";
import uuid from 'react-uuid';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import firebase from 'firebase/app';

const AIOpenQuestionAnalysis = () => {
     // State
     const [questionnaireId, setQuestionnaireId] = useState('');
     const [questionFieldId, setQuestionFieldId] = useState('');
     const [question, setQuestion] = useState('');
     const [loading, setLoading] = useState('none');
     const [researchId, setResearchId] = useState('');
     const [openQuestionSuggestion, setOpenQuestionSuggestion] = useState([]);
     const [openQuestionAnalysisLoading, setOpenQuestionAnalysisLoading] = useState('none');
 
     // Hooks
     const history = useHistory();
     const secundairyColor = useSettings().SecundairyColor;
 
     // Firestore
     const allResearch = useFirestoreOrderBy('Research', 'Position', 'asc');
     const openQuestions = useFirestoreGeneralTwo('QuestionnaireFields', 'QuestionnaireID', questionnaireId ? questionnaireId : '', 'Type', 'paragraph');
     const responses = useFirestoreGeneral('QuestionnairesResponses', 'FieldID', questionFieldId ? questionFieldId : '');
     const insights = useFirestore('Insights')

      // Function to select research
    const selectResearch = (e) => {
        setQuestionnaireId(e.target.options[e.target.selectedIndex].dataset.questionnaireid);
        setResearchId(e.target.options[e.target.selectedIndex].value);
    };

    // Function to select question
    const selectQuestion = (e) => {
        const fieldId = e.target.options[e.target.selectedIndex].value;
        const question = e.target.options[e.target.selectedIndex].innerText;

        setQuestionFieldId(fieldId);
        setQuestion(question);
    };


     // Function to create analysis
    const createAnalysis = () => {
        setOpenQuestionAnalysisLoading('block');
        setOpenQuestionSuggestion([]);
        const array = [];

        responses && responses.map(response => {
            array.push(response.Input);
        });

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van het maken van samenvattingen." },
            { "role": "user", "content": `Ik heb ${array.length} mensen deze vraag gesteld: ${question}. 
            Dit zijn hun antwoorden: ${JSON.stringify(array)}. 
            Maak een samenvatting van deze antwoorden` }
        ];

        sentimentAnalyse(prompt, setOpenQuestionSuggestion, setLoading);
        setOpenQuestionAnalysisLoading('none');
    };

     // Function to save analysis
     const saveAnalysis = (e) => {
        const type = e.target.dataset.type;


        db.collection('Insights')
            .add({
                Content: openQuestionSuggestion.content || '',
                AverageInput: '', 
                CrossResearchResults: '',
                Type: type,
                Timestamp: timestamp,
                CompagnyID: client,
                Research: firebase.firestore.FieldValue.arrayUnion(researchId),
                QuestionnaireID: questionnaireId,
                QuestionFieldID: questionFieldId,
                ID: uuid(),
                Title: 'Inzicht ' + (insights.length + 1),
                Position: insights.length + 1,
            })
            .then(() => {
                history.push(`/${client}/insights`);
            })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
    };
  return (
    <div class="table-container dashboard-container">
        <h3>Open vraag analyse</h3>
        <p>Creeer een AI analyse van een open vraag uit een specifiek onderzoek.</p>
        <p><b>Selecteer onderzoek</b></p>
        <select name="" id="" onChange={selectResearch}>
            <option value="" >-- Selecteer onderzoek --</option>
            {allResearch && allResearch.map(research => (
                <option value={research.ID} data-questionnaireid={research.QuestionnaireID}>{research.Title}</option>
            ))}
        </select>
        <p>Selecteer vraag</p>
        <select name="" id="" onChange={selectQuestion}>
            <option value="">-- Selecteer open vraag --</option>
            {openQuestions && openQuestions.map(question => (
                <option value={question.ID}>{question.Question}</option>
            ))}
        </select>
        <div className='start-insights-button-container' onClick={createAnalysis}>
            <KeyboardDoubleArrowRightOutlinedIcon style={{fill: secundairyColor ? secundairyColor : 'black'}}/>
            <p style={{color: secundairyColor ? secundairyColor : 'black'}}>Analyseer</p>
        </div>
        <h3>Analyse</h3>
        <div className='insights-analysis-content-container'>
            <p style={{ display: openQuestionAnalysisLoading }}>Analyseren...</p>
            <p>{openQuestionSuggestion.content}</p>
        </div>
        <div>
            <button data-type='sentiment-analyse-open-question' onClick={saveAnalysis}>Sla op</button>
        </div>
    </div>
  )
}

export default AIOpenQuestionAnalysis