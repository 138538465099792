import {
  useFirestore,
  useFirestoreOrderBy,
  useFirestoreArrayContainsOrderBy,
} from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import { useEffect, useState, useContext } from "react";
import useSettings from "../../hooks/Settings";
import IndicatorData from "../../components/Indicators/IndicatorData";
import { db } from "../../firebase/config";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { DashboardSystemMessage } from "../../hooks/impactAI/Prompts"
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import Tooltip from "../../components/common/Tooltip";
import DashboardOutputResultsDetail from "../../components/dashboard/DashboardOutputResultsDetail";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import UpdateOutputColors from "../../components/dashboard/UpdateOutputColors";
import OutputData from "../../components/graphData/OutputData";
import Modal from 'react-modal';
import PeriodSelector from "../../components/common/PeriodSelector";
import PresenceToday from "../../components/dashboard/Personas/PresenceToday";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import StatusData from "../../components/personas/StatusData";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import RouteData from '../../components/personas/RouteData';
import ForkRightOutlinedIcon from '@mui/icons-material/ForkRightOutlined';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import EffectData from "../../components/graphData/EffectData";

const Dashboard = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen],
      hightlighted: [hightlighted, setHightlighted],
      hightlightText: [hightlightText, setHightlightText],
  } = useContext(ImpactAI);

  // State
  const [activityID, setActivityID] = useState(null);
  const [EffectID, setEffectID] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [effectsWithIndicators, setEffectsWithIndicators] = useState([]);
  const [outputDetails, setOutputDetails] = useState(false)
  const [graphType, setGraphType] = useState('line')
  const [modalOpen, setModalOpen] = useState(false)
  const [presenceMonth, setPresenceMonth] = useState(new Date().getMonth() + 1)
  const [outflowRange, setOutflowRange] = useState(3)
  const [outflowPersonas, setOutflowPersonas] = useState([])

  // Hooks
  const today = new Date();
  ScrollToTop();
  const secundairyColor = useSettings().SecundairyColor;
  const trailDays = useSettings().trailDays;
  const subscribtion = useSettings().subscribtion;
  const personasOn = useSettings().personasOn
  const history = useHistory();
  Modal.setAppElement('#root');
  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  // Firestore
  const effects = useFirestore("OutputEffects");
  const outputs = useFirestore("Outputs");
  const activities = useFirestoreOrderBy("Activities", "Position", "asc");
  const dashboardSettings = useFirestore("DashboardSettings");
  const presenceReports = useFirestore('PersonaPresenceReports');
  const personas = useFirestore('Personas');


  //ImpactAI
  const pageSystemMessage = DashboardSystemMessage()
  const pageStartMessage = `Welkom bij het dashboard, ${auth?.ForName}. Hier kun je je impactresultaten inzien.
  Heb je vragen over het dashboard?`
  const pageExampleMessages = ['Wat zijn outputresultaten?', 'Wat zijn KPI"s?']
  const pageType = 'dashboard'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

 // Reset hightlights
 useEffect(() => {
  setHightlighted(false)
  setHightlightText('')
},[])

 // Helper function to convert date format
 const convertDateFormat = (dateString) => {
  const parts = dateString.split('-');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

// Set the start date and end date from the dashboard settings
useEffect(() => {
       dashboardSettings && dashboardSettings.forEach(item => {
            setStartDate(item.Start);
            setEndDate(item.End);
        });
  }, [dashboardSettings]);

  // Get the effects with indicators
  useEffect(() => {
    effects &&
      effects.map((item) => {
        db.collection("QuestionnaireFields")
          .where("EffectId", "array-contains", item.ID)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              const object = {
                ID: item.ID,
                Effect: item.Effect,
              };
              setEffectsWithIndicators((prevState) => [...prevState, object]);
            }
          });
      });
  }, [effects]);

  // Set the first activity as default
  useEffect(() => {
    activities.length > 0
      ? setActivityID(activities[0].ID)
      : setActivityID(null);
  }, [activities]);

  // Set the first effect as default
  useEffect(() => {
    effectsWithIndicators.length > 0 ? setEffectID(effectsWithIndicators[0].ID) : setEffectID(null);
  }, [effectsWithIndicators]);

  // Update the selected activity
  const selecteActivity = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;

    setActivityID(id);
  };

  // Update the selected effect
  const selectedEffect = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;

    setEffectID(id);
  };

  // Style of output details container
  const detailContainerStyle = {
    maxHeight: outputDetails ? '5000px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 2s ease-in-out',
  };

  // Presence month handler
  const presenceMonthHandler = (e) => {
    const month = e.target.options[e.target.selectedIndex].value;
    setPresenceMonth(month);
  };

  // Get the presence reports
  const personaPresenceData = (personaID) => {
    // Filter reports for the selected persona and matching the selected month
    const reports = presenceReports && presenceReports.filter(report => {
      const reportDate = new Date(report.Date); // Convert date to JavaScript Date object
      return (
        report.PersonaID === personaID && // Match persona ID
        reportDate.getMonth() + 1 === parseInt(presenceMonth) && // Match month (getMonth is zero-based)
        reportDate.getFullYear() === today.getFullYear() // Match year to the current year
      );
    });
  };

  // Filter personas
  useEffect(() => {
    const array = [];

    personas && personas.forEach(persona => {
      const endDate = new Date(persona.EndDate);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - endDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= outflowRange * 30) {
        array.push(persona);
      }
    });

    setOutflowPersonas(array);
  }, [personas, outflowRange]);

 // Outflow range handler
 const outflowRangeHandler = (e) => {
  const value = e.target.options[e.target.selectedIndex].value;
  setOutflowRange(parseInt(value));
};

  return (
    <div className="main">
      <div
        className="main-container"
        style={{
          display:
            trailDays === 0 && subscribtion === "trial" ? "none" : "flex",
        }}
      >
         <div className="page-header">
          <DashboardOutlinedIcon/>
          <h1>Dashboard</h1>
        </div>

        <div id='dashboard-period-selector-container'>
          <h2>Periode</h2>
          <PeriodSelector page={'dashboard'}/>
        </div>

        <div className="home-sections-one-column-container" style={{display: personasOn}}>
          <div className="home-section-container">
            <div>
              <PresenceToday/>
            </div>
          </div>
        </div>

          <div className="home-sections-column-container" style={{display: personasOn}}>
            <div className="home-section-container">
              <div className="title-edit-container home-section-title-container">
                <CircleOutlinedIcon className='icon' />
                <h2>Status deelnemers</h2>
              </div>
              <StatusData />
            </div>

            <div className="home-section-container">
              <div className="title-edit-container home-section-title-container">
                <RouteOutlinedIcon className='icon' />
                <h2>Trajecten</h2>
              </div>
              <RouteData />
            </div>
          </div>
        
          <div className="home-sections-column-container" style={{display: personasOn}}>
          <div className="home-section-container">
            <div className="title-edit-container home-section-title-container">
              <CalendarTodayOutlinedIcon className='icon' />
              <h2>Aanwezig totaal</h2>
            </div>
            <div className='table-container dashboard-container'>
              <div>
                <select name="" id="" defaultValue={new Date().getMonth() + 1} onChange={presenceMonthHandler}>
                  <option value="1">Januari</option>
                  <option value="2">Februari</option>
                  <option value="3">Maart</option>
                  <option value="4">April</option>
                  <option value="5">Mei</option>
                  <option value="6">Juni</option>
                  <option value="7">Juli</option>
                  <option value="8">Augustus</option>
                  <option value="9">September</option>
                  <option value="10">Oktober</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <table>
                  <tr>
                      <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                      <th style={{backgroundColor: secundairyColor}}>TOTALE UREN</th>
                      <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                  </tr>
                  {personas && personas.map((item, index) => (
                      <tr key={index}>
                          <td>
                              <p>{item.Name}</p>
                          </td>
                          <td>
                              <p>{personaPresenceData(item.ID)}</p>
                          </td>
                          <td>
                              <Tooltip content='Details' >
                                  <SearchOutlinedIcon />
                              </Tooltip>
                          </td>
                      </tr>
                  ))}
              </table>
          </div>
        </div>

        <div className="home-section-container">
        <div className="title-edit-container home-section-title-container dashboard-outflow-title-container">
          <ForkRightOutlinedIcon className='icon' />
          <h2>Uitstroom binnen
            <select name="" id="" onChange={outflowRangeHandler}>
              <option value="3">3</option>
              <option value="6">6</option>
              <option value="12">12</option>
            </select>
            maanden
          </h2>
        </div>
        <div>
          <table className='dashboard-outflow-table'>
            <thead>
              <tr>
                <th style={{ backgroundColor: secundairyColor }}>Naam</th>
                <th style={{ backgroundColor: secundairyColor }}>Einddatum</th>
              </tr>
            </thead>
            <tbody>
              {outflowPersonas && outflowPersonas.map(persona => (
                <tr key={persona.id} onClick={() => { history.push(`/${client}/personabuilder/${persona.ID}`) }}>
                  <td>{persona.Name}</td>
                  <td>{convertDateFormat(persona.EndDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
       
    <div className="home-sections-one-column-container">
        <div className="home-section-container">
        <div className="title-edit-container home-section-title-container">
          <BarChartOutlinedIcon className='icon'/>
          <h2>Outputs</h2>
        </div>
        <div className="select-activity-container">
          <div className="select-activity-inner-container">
            {activities &&
              activities.map((item) => (
                <div
                  key={item.ID}
                  className="activity-select-item-container"
                  onClick={selecteActivity}
                  data-id={item.ID}
                  style={{
                    backgroundColor:
                      activityID === item.ID ? secundairyColor : "white",
                  }}
                >
                  <p
                    data-id={item.ID}
                    onClick={selecteActivity}
                    style={{
                      color: activityID === item.ID ? "white" : "#616161",
                    }}
                  >
                    {item.Activity}
                  </p>
                </div>
              ))}
          </div>
        </div>

        <div className="home-outputs-container">
          <div id='dashboard-graph-options-container'>

              {/* Zoom in and out */}
          {!outputDetails &&
              <div onClick={() => setOutputDetails(true)}>
                <Tooltip content='Bekijk details' top='40px'>
                  <ZoomInOutlinedIcon />
                </Tooltip>
              </div>
            }
            {outputDetails &&
              <div onClick={() => setOutputDetails(false)}>
                <Tooltip content='Verberd details' top='40px'>
                  <ZoomOutOutlinedIcon />
                </Tooltip>
              </div>
            }

            {/* Change graphtype */}
            {graphType === 'bar' &&
              <div onClick={() => setGraphType('line')}>
                <Tooltip content='Cummulatief' top='40px'>
                  <TimelineOutlinedIcon style={{color: graphType === 'line' ? secundairyColor : 'black'}}/>
                </Tooltip>
              </div>
            }
            {graphType === 'line' &&
              <div onClick={() => setGraphType('bar')}>
                <Tooltip content='Momentopname' top='40px'>
                  <EqualizerOutlinedIcon style={{color: graphType === 'bar' ? secundairyColor : 'black'}}/>
                </Tooltip>
              </div>
            }
            
            {/* Change output colors */}
            <div onClick={() => setModalOpen(!modalOpen)}>
              <Tooltip content='Kleuren' top='40px'>
                <PaletteOutlinedIcon />
              </Tooltip>
            </div>
          </div>
          <OutputData activity={activityID} startDate={startDate} endDate={endDate} graphType={graphType}/>
          <div id='dashboard-output-detail-container' style={detailContainerStyle}>
            {outputs && outputs.map(item => (
              <>
              {item.ActivityID === activityID &&
                <DashboardOutputResultsDetail 
                outputID={item.ID}
                startDate={startDate}
                endDate={endDate}
                color={item.Color} 
                graphType={graphType}
                />
                }
              </>
            ))}
          </div>
        </div>
        </div>
      </div>

      <div className="home-sections-one-column-container">
      <div className='home-section-container'>
          <div className='title-edit-container'>
              <TrackChangesOutlinedIcon className='icon'/>
              <h2>Effecten</h2>
          </div>
          <div className="select-activity-container">
              <div className="select-activity-inner-container">
                  {effectsWithIndicators && effectsWithIndicators.map(item => (
                      <div key={item.ID} data-id={item.ID} className='activity-select-item-container' onClick={selectedEffect} style={{backgroundColor: EffectID === item.ID ? secundairyColor : 'white'}}>
                          <p data-id={item.ID} onClick={selectedEffect} style={{color: EffectID === item.ID ? 'white' : '#616161'}}>{item.Effect}</p>
                      </div>
                  ))}
              </div>
          </div>
          <div>
            <EffectData effectId={EffectID} startDate={startDate} endDate={endDate} graphType='line'/>
          </div>
        </div>
      </div>
       
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalOpen}
        style={modalStyles}
        contentLabel="Color settings outputgraph"
        >
            <div>
                <h1>Wijzig kleuren</h1>
                <UpdateOutputColors activityID={activityID}/>
            </div>
            <div id='modal-button-container'>
                <button id='modal-cancel-button' onClick={() => setModalOpen(false)}>Annuleren</button>
                <button id='modal-save-button' onClick={() => setModalOpen(false)} >Opslaan</button>
            </div>
        </Modal>
    </div>                
  );
};

export default Dashboard;
