import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import TotalResponses from '../TotalResponses';

const ParagraphDevelopmentBarChart = ({ rawData, totalResponses }) => {
  // Transform data to a usable format for Recharts
  const processData = (data) => {
    return (
      data &&
      data.map((item) => {
        const transformed = { MomentName: item.MomentName };
        item.Categories.forEach((category) => {
          transformed[category.Title] = category.Words;
        });
        return transformed;
      })
    );
  };

  // Extract unique dynamic keys and their associated colors
  const categories =
    rawData &&
    rawData.flatMap((item) =>
      item.Categories.map((category) => ({
        title: category.Title,
        color: category.Color,
      }))
    );

  const uniqueCategories = Array.from(
    new Map(categories.map((cat) => [cat.title, cat])).values()
  );

  // Transformed data
  const data = processData(rawData);

  console.log(data)

  return (
    <>
     <TotalResponses totalResponses={totalResponses} />
     <BarChart
      width={600}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="MomentName" />
      <YAxis />
      <Tooltip />
      <Legend />
      {uniqueCategories.map((category, index) => (
        <Bar
          key={category.title}
          dataKey={category.title}
          fill={category.color} // Use color from rawData
        />
      ))}
    </BarChart>
    </>

  );
};

export default ParagraphDevelopmentBarChart;
