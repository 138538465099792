import { useState } from "react"
import firebase from "firebase";
import { client } from "../../hooks/Client";

const AIAnalysis = () => {
    // State
    const [startDate, setStartDate] = useState('2000-01-01');
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false)
    const [analysis, setAnalysis] = useState([])
    const [prompt, setPrompt] = useState('')
    const [costs, setCosts] = useState(0)

    // Start ananlyse
    const startAnalysis = async () => {
        try {
            setLoading(true)
            setCosts(0)
    
            const progressAnalysis  = firebase.functions().httpsCallable('progressAnalysis ')  

            const data = {
                startDate: startDate,
                endDate: endDate,
                companyId: client,
                prompt: prompt
            }
    
            const result = await progressAnalysis ({data})
    
            console.log(result)
    
            const answer = result.data.message 
            const costs = result.data.costs
    
            setAnalysis(answer)
            setCosts(costs)

            setLoading(false)
    
        } catch (error) {
            console.log(error.message)
            setAnalysis('Er is iets fout gegaan. Probeer het opnieuw.')
        }
    }


  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>AI Rapportage</h1>
            </div>
            <div>
                <div className="ai-analysis-container">
                    <div className="ai-analysis-item">
                    <h2>Selecteer periode</h2>
                    <input 
                      type="date" 
                      onChange={(e) => setStartDate(e.target.value)} 
                      defaultValue="2000-01-01" 
                    />
                    <input 
                      type="date" 
                      onChange={(e) => setEndDate(e.target.value)} 
                      defaultValue={new Date().toISOString().split('T')[0]} 
                    />
                    
                    </div>
                </div>
                <div>
                    <h2>Onderwerp</h2>
                    <textarea 
                      placeholder="Geef hier aan waar het rapport over moet gaan." 
                      onChange={(e) => setPrompt(e.target.value)} 
                    ></textarea>
                </div>
                <div>
                    <button onClick={startAnalysis}>Creëer rapportage</button>
                </div>
                <div>
                    <p>(Kosten: {costs.toFixed(4)} euro)</p>
                </div>
                <div>
                    {loading ? <p>Loading...</p> : <p dangerouslySetInnerHTML={{__html: analysis}}></p>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default AIAnalysis