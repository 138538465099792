import useSettings from "../../hooks/Settings";

const ColorPickerHousestyle = ({item, onChange}) => {

      // Hooks
      const primaryColor = useSettings().PrimaryColor
      const secundairyColor = useSettings().SecundairyColor;
      const tertiaryColor = useSettings().TertairyColor;

  return (
    <div>
        <div className="color-picker-select-color-container">
            <input
                type="radio"
                name={`color-${item.ID}`} // Unique name for this group
                data-docid={item.docid}
                checked={item.Color === primaryColor}
                value={primaryColor}
                onChange={onChange}
            />
            <div
                className="color-picker-color-container"
                style={{ backgroundColor: primaryColor }}
            ></div>
        </div>
        <div className="color-picker-select-color-container">
            <input
                type="radio"
                name={`color-${item.ID}`} // Same name for all radios in this group
                data-docid={item.docid}
                checked={item.Color === secundairyColor}
                value={secundairyColor}
                onChange={onChange}
            />
            <div
                className="color-picker-color-container"
                style={{ backgroundColor: secundairyColor }}
            ></div>
        </div>
        <div className="color-picker-select-color-container">
            <input
                type="radio"
                name={`color-${item.ID}`} // Same name for all radios in this group
                data-docid={item.docid}
                checked={item.Color === tertiaryColor}
                value={tertiaryColor}
                onChange={onChange}
            />
            <div
                className="color-picker-color-container"
                style={{ backgroundColor: tertiaryColor }}
            ></div>
        </div>
    </div>
  )
}

export default ColorPickerHousestyle