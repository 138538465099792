import React from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext, useState } from "react";
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import { Auth } from '../../StateManagment/Auth'
import UserImageMeta from '../../components/user/UserImageMeta'
import UserNameMeta from '../../components/user/UserNameMeta'
import DraggableTableItems from '../../components/common/DraggableTableItems'
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Reports = ({personaNavigation}) => {
   // Context
   const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null)

   // Hooks
   const secundairyColor = useSettings().SecundairyColor
   const personaId = Location()[3]
   const history = useHistory()
   const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

   // Firestore
   const reports = useFirestoreGeneralOrderBy('PersonaReports', 'PersonaID', personaId ? personaId : '', 'Timestamp', 'desc')

   const addReport = () => {

    const reportId = uuid()

       db.collection('PersonaReports')
       .add({
           ID: reportId,
           PersonaID: personaId,
           Title: '',
           Description: '',
           Tags: [],
           CompagnyID: client,
           Compagny: client,
           Position: reports.length + 1,
           Timestamp: timestamp,
           User: auth.ID,
           Body: '',
           Position: reports.length + 1
       })   
         .then(() => {
              setSaved('flex')
              history.push(`/${client}/personareportdetail/${reportId}/${personaId}`)
         })

   }

   const deleteReport = (e) => {

       const docid = e.target.dataset.docid

       db.collection('PersonaReports')
       .doc(docid)
       .delete()
       .then(() => {
           setSaved('flex')
       })

   }

 return (
   <div style={{display: personaNavigation === 'report' ? 'block' : 'none'}}>
       <h2>Rapportages</h2>
       <div  className="persona-option-container">
           <div className='table-container'>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                            <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                            <th style={{backgroundColor: secundairyColor}}>AUTEUR</th>
                            <th style={{backgroundColor: secundairyColor}}>INHOUD</th>
                            <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                    </thead>
                    <tbody>
                            {reports && reports.map((report, index) => (
                            <React.Fragment key={report.docid}>  
                                {placeholderIndex === index && (
                                    <tr className="placeholder-row">
                                        <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                    </tr>
                                )}
                                <DraggableTableItems
                                    item={report}  
                                    input={
                                        <p>{report.Title}</p>
                                    }
                                    parentIndex={index}
                                    collection={'PersonaReports'}
                                    itemArray={reports}
                                    setHoverIndex={setHoverIndex}
                                    hoverIndex={hoverIndex}
                                    title={report.Timestamp.toDate().toLocaleDateString("nl-NL", options)}
                                    setPlaceholderIndex={setPlaceholderIndex}
                                >
                                    <td>
                                        {report.Timestamp.toDate().toLocaleDateString("nl-NL", options)}
                                    </td>
                                    <td>
                                        <div id='report-user-meta-container'>
                                            <UserImageMeta userID={report.User} />
                                            <UserNameMeta userID={report.User} />
                                        </div>
                                    </td>
                                    <td>
                                        <p dangerouslySetInnerHTML={{
                                            __html: report.Body && report.Body.length > 50 
                                                ? report.Body.substring(0, 50) + ' [...]' 
                                                : report.Body
                                            }}>
                                        </p>
                                    </td>
                                    <td>
                                            <Tooltip content={'Rapportage aanpassen'} top='-60px' width={'30px'}>
                                                <EditOutlinedIcon src={settingsIcon} className="table-delete-icon" onClick={() => history.push(`/${client}/personareportdetail/${personaId}/${report.ID}`)}/>
                                            </Tooltip>
                                    </td>
                                    <td>
                                            <Tooltip content={'Rapportage verwijderen'} top='-60px' width={'30px'}>
                                                <img className='table-delete-icon' data-docid={report.docid} onClick={deleteReport} src={deleteIcon} alt="" />
                                            </Tooltip>
                                    </td>
                                </DraggableTableItems>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <AddItemTableRow toolTipContent={'Rapportage toevoegen'} onClick={addReport}/>
           </div>
       </div>
   </div>
 )
}

export default Reports