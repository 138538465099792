import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import firebase from 'firebase/app';
import { client } from '../../hooks/Client';
import MultipleMomentLineChart from '../Visualisations/moment/MultipleMomentLineChart';
import MultipleMomentPieChart from '../Visualisations/moment/MultipleMomentPieChart';
import MultipleDevelopmentLineChart from '../Visualisations/development/MultipleDevelopmentLineChart';
import MultipleDevelopmentPieChart from '../Visualisations/development/MultipleDevelopmentPieChart';
import MultipleMomentBarChart from '../Visualisations/moment/MultipleMomentBarChart';
import MultipleDevelopmentBarChart from '../Visualisations/development/MultipleDevelopmentBarChart';

const MultipleData = ({ field, researchId, startDate, endDate, graphType }) => {
    // State
    const [data, setData] = useState([]);
    const [otherInputs, setOtherInputs] = useState([]);
    const [totalResponses, setTotalResponses] = useState(0);

    // Default dates
    const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
    const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

    // Query other results
    const fetchOtherInputs = async () => {
        const querySnapshot = await db.collection('QuestionnairesResponses')
            .where('CompagnyID', '==', client)
            .where('FieldID', '==', field)
            .where('OptionType', '==', 'other')
            .where('Timestamp', '>=', startDate || defaultStartDate)
            .where('Timestamp', '<=', endDate || defaultEndDate)
            .get();

        return querySnapshot.docs.map((doc) => doc.data().Input);
    };

    // Function to query and group responses by MeasureMoment with option type and color
    const groupResponsesWithOptions = async () => {
        const responsesSnapshot = await db.collection('QuestionnairesResponses')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .where('FieldID', '==', field)
            .where('Timestamp', '>=', startDate || defaultStartDate)
            .where('Timestamp', '<=', endDate || defaultEndDate)
            .get();

        const responses = responsesSnapshot.docs.map((doc) => doc.data());


        setTotalResponses(responses.length);

        const optionsSnapshot = await db.collection('MultipleQuestionOptions')
            .where('CompagnyID', '==', client)
            .where('Field', '==', field)
            .orderBy('Position', 'asc')
            .get();

        const options = optionsSnapshot.docs.map((doc) => ({
            id: doc.id,
            option: doc.data().Option,
            color: doc.data().Color,
        }));

        const momentsSnapshot = await db.collection('MeasureMoments')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .orderBy('Position', 'asc')
            .get();

        const moments = momentsSnapshot.docs.map((doc) => ({
            id: doc.data().ID,
            name: doc.data().Title,
        }));

        const groupedData = moments.map((moment) => {
            const momentResponses = responses.filter((response) => response.MomentID === moment.id);

            const responseCounts = options.map((option) => {
                const count = momentResponses.filter((response) => response.Input === option.option).length;
                return count > 0 ? { name: option.option, count, color: option.color } : null;
            }).filter(Boolean);

            return responseCounts.length > 0
                ? { moment: moment.name, responses: responseCounts }
                : null;
        }).filter(Boolean);

        return groupedData;
    };

    // Fetch data on component mount
    useEffect(() => {
        (async () => {
            const fetchedOtherInputs = await fetchOtherInputs();
            setOtherInputs(fetchedOtherInputs);

            const groupedData = await groupResponsesWithOptions();
            setData(groupedData);
        })();
    }, [field, researchId, startDate, endDate]);

    // Render the correct graph based on the data
    const graphComponents = {
        single: {
          line: <MultipleMomentLineChart data={data} totalResponses={totalResponses} />,
          bar: <MultipleMomentBarChart data={data} totalResponses={totalResponses}/>,
          pie: <MultipleMomentPieChart data={data} totalResponses={totalResponses}/>
        },
        multiple: {
          line: <MultipleDevelopmentLineChart data={data} totalResponses={totalResponses}/>,
          bar: <MultipleDevelopmentBarChart data={data} totalResponses={totalResponses}/>,
          pie: <MultipleDevelopmentPieChart data={data} totalResponses={totalResponses}/>
        }
      };
      
      const isSingleData = data.length === 1;
      const typeOfGraph = isSingleData ? 'single' : 'multiple';

    return (
        <>
             {graphComponents[typeOfGraph][graphType ? graphType : 'bar'] || null}
        </>
    );
};

export default MultipleData;
