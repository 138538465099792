import Location from "../../hooks/Location"
import { useState, useEffect, useContext } from "react"
import { useFirestore, useFirestoreGeneral, useFirestoreGeneralArrayContains, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import deleteIcon from '../../images/icons/delete-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow'
import Tooltip from '../../components/common/Tooltip'
import { db } from "../../firebase/config"
import { SavedIcon } from "../../StateManagment/SavedIcon"
import useSettings from "../../hooks/Settings";
import personaGoalProgress from "../../components/AI/PersonaGoalProgress"

const Evaluations = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [title, setTitle] = useState('')
    const [docid, setDocid] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState('')
    const [personaName, setPersonaName] = useState('')
    const [suggestion, setSuggestion] = useState('')
    
    // Hooks
    const personaId = Location()[3]
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', personaId ? personaId : '')
    const reports = useFirestoreGeneral('PersonaReports', 'PersonaID', personaId ? personaId : '')
    const personas = useFirestoreGeneral('Personas', 'ID', personaId ? personaId : '')
    const methodes = useFirestore('PersonaMethodes')
    const activities = useFirestore('Activities')
    const results = useFirestoreGeneralOrderBy(
        'QuestionnairesResponses', 
        'Persona', personaId ? personaId : '', 
        'Timestamp', 'asc' )

    // Set persona name in state
    useEffect(() => {
        personas && personas.forEach(item => {
            setPersonaName(item.Name)
        })
    }, [personas])

    // Goals data
    const goalData = async() => {

        const data = [];

        if (goals && goals.length > 0) {
            for (const goal of goals) {
            data.push({
                ID: goal.ID,
                Goal: goal.Goal,
                Description: goal.Description
            })
        }
    }

        return data;

    }

    // Research results with async/await
   const researchResults = async () => {
        const processedResults = [];

        if (results && results.length > 0) {
            for (const result of results) {
                const object = {
                    question: result.RowTitle,
                    answer: result.Input
                };
                processedResults.push(object);
            }
        }

        return processedResults;
    }

    // Methodes
    const methodesList = async () => {
        const list = [];

        if (methodes && methodes.length > 0) {
            for (const methode of methodes) {
                const object = {
                    title: methode.Title,
                    description: methode.Description
                };
                list.push(object);
            }
        }

        return list;
    }

    // Methodes
    const activitiesList = async () => {
        const list = [];

        if (activities && activities.length > 0) {
            for (const activity of activities) {
                list.push(activity.Activity);
            }
        }

        return list;
    }

    // Tools
    const tools = () => {
        return (
        `1. SWOT-Analyse
        Laat het LLM de SWOT-methode toepassen door het vragen te stellen over de Sterktes, Zwaktes, Kansen, en Bedreigingen van een cliënt. Je kunt het model bijvoorbeeld vragen om sterktes en zwaktes in hun vaardigheden of ervaringen in kaart te brengen, en om kansen en bedreigingen op de arbeidsmarkt of in specifieke functies te analyseren.
        2. GROW Model
        Het GROW Model (Goal, Reality, Options, Will) is handig voor coachingsgesprekken. Een LLM kan deze aanpak leren door:
        Doelen (Goal) vast te stellen met de cliënt.
        De huidige situatie (Reality) te analyseren en reflecteren op uitdagingen.
        Mogelijke opties (Options) en strategieën te verkennen.
        Acties en motivatie (Will) te plannen om deze doelen te bereiken.
        3. SMART-doelen
        SMART-doelen (Specifiek, Meetbaar, Acceptabel, Realistisch, Tijdgebonden) bieden structuur voor het formuleren van duidelijke en haalbare doelen. Het LLM kan leren hoe het vragen kan stellen om doelen concreet te maken en te controleren of ze SMART zijn. Bijvoorbeeld:
        “Wat is precies het doel (Specifiek)? Hoe kun je dit meten (Meetbaar)?”
        4. 5-Why’s Methode
        Deze methode helpt bij het doorgronden van problemen door vijf keer “waarom” te vragen. Het LLM kan hiermee de onderliggende oorzaken van obstakels in de arbeidstoeleiding achterhalen, bijvoorbeeld:
        Cliënt vindt het moeilijk om een baan te vinden. Waarom? Omdat ze niet zeker zijn van hun vaardigheden. Waarom? Omdat ze geen recente werkervaring hebben, enzovoort.
        5. Competentie- en GAP-Analyse
        Een Competentie-analyse laat het model vragen stellen over huidige vaardigheden en benodigde vaardigheden voor een functie. Een GAP-analyse kan helpen om ontbrekende vaardigheden of kennis op te sporen, en een plan op te stellen om deze kloof te dichten.
        6. STAR-methode voor zelfreflectie
        De STAR-methode (Situatie, Taak, Actie, Resultaat) helpt cliënten bij het analyseren van hun eigen ervaringen. Het LLM kan de STAR-structuur gebruiken om cliënten door zelfreflectie te begeleiden, bijvoorbeeld:
        “Beschrijf een situatie waar je trots op bent” (Situatie), “Wat was jouw rol hierin?” (Taak), “Welke acties ondernam je?” (Actie), “Wat was het resultaat?” (Resultaat).
        7. Mindmapping
        Mindmapping helpt cliënten om ideeën te visualiseren en verbanden te leggen. Het LLM kan leren om een simpele mindmap-structuur op te bouwen voor het brainstormen over carrière-opties, bijvoorbeeld door vragen te stellen die subcategorieën of branches verkennen.
        8. Persoonlijke Waardenanalyse
        Vraag het LLM om een waardenanalyse te begeleiden waarin cliënten nadenken over wat voor hen belangrijk is in hun werk (bijv. “Wat vind je belangrijk in een werkcultuur?”). Dit helpt hen te bepalen welk type werk of organisatie bij hen past.
        9. Eisen/Wensenlijst
        Het LLM kan een cliënt begeleiden bij het opstellen van een eisen/wensenlijst. Hierbij onderscheidt de cliënt wat noodzakelijk is en wat een pluspunt zou zijn in hun ideale baan. Bijvoorbeeld: “Wat zijn de minimale vereisten voor een baan waarin je gelukkig zou zijn? Wat zijn zaken die je graag zou hebben, maar niet strikt nodig zijn?”
        10. Toekomstige Zelf-oefening
        Deze methode stimuleert cliënten om zich voor te stellen waar ze over enkele jaren willen staan. Het LLM kan hierbij helpen door vragen te stellen als: “Hoe zie jij jezelf over vijf jaar? Welke vaardigheden of ervaringen zou je willen ontwikkelen?” Dit maakt langetermijndoelen concreet.
        11. Belemmerende en stimulerende factoren (Force Field Analysis)
        Vraag het LLM om een Force Field Analysis uit te voeren om belemmeringen en ondersteunende factoren voor het bereiken van een doel te identificeren. Laat het model vragen stellen zoals: “Wat zijn de factoren die je vooruithelpen? Welke obstakels zie je?”`
        )
    }

    // Add goal progress
    const createGoalProgress = async () => {
        setLoading('Rapportages analyseren...');
        setLoading('Onderzoeksresulaten analyseren...');

        // Wait for research results before constructing the prompt
        const researchData = await researchResults();
        const goals = await goalData();
        const methodes = await methodesList();
        const activities = await activitiesList();

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van analyseren van rapportages en onderzoeksresulaten." },
            { "role": "user", "content": `
                Wij willen graag dat je een analyse maakt van de voortgang van de doelen van ${personaName}.
                Maak een analyse van de voortgang voor deze doelen: ${JSON.stringify(goals)}. 

                Analyseer de rapportages en onderzoeksresultaten en geef aan wat de voortgang is op de doelen. Geef aan wat er goed gaat en wat er beter kan.

                Dit zijn de rapportages die je kunt analyseren:
                ${reports.map(report => report.Body).join(', ')}

                Dit zijn de onderzoeksresultaten die je kunt analyseren:
                ${JSON.stringify(researchData)}

                Maak bij je aanvebelingen gebruik van de volgende methodes die wij gebruiken bij het begeleiden van onze deelnemers:
                ${JSON.stringify(methodes)}.

                Geef waar nodig en waardevol ook aanbeveiligen op basis van deze begeleidingstools: ${tools()}.

                Onze organisatie biedt de volgende activiteiten aan:
                ${JSON.stringify(activities)}.
                Houd hier rekening mee bij het geven van je aanbevelingen.

                Je respons moet als volgt gestruktureerd zijn:

                1. Doel:
                2. Voortgang:
                3. Aanbevelingen:
            ` }
        ];

        personaGoalProgress(prompt, setSuggestion, setLoading);
    }

    // Create reports analysis
    const createReportsAnalasis = async () => {
        setLoading('Rapportages analyseren...');
        setLoading('Onderzoeksresulaten analyseren...');

        // Wait for research results before constructing the prompt
        const researchData = await researchResults();
        const goals = await goalData();
        const methodes = await methodesList();
        const activities = await activitiesList();

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van analyseren van rapportages en onderzoeksresulaten." },
            { "role": "user", "content": `
                Wij willen graag dat je een analyse maakt van de rapportages van ${personaName}.

                Analyseer de rapportages en geef aan wat de voortgang is op de doelen. Geef aan wat er goed gaat en wat er beter kan.

                Dit zijn de rapportages die je kunt analyseren:
                ${reports.map(report => report.Body).join(', ')}

                Dit zijn de doelen die we willen bereiken:
                ${JSON.stringify(goals)}

                Maak bij je aanvebelingen gebruik van de volgende methodes die wij gebruiken bij het begeleiden van onze deelnemers:
                ${JSON.stringify(methodes)}.

                Onze organisatie biedt de volgende activiteiten aan:
                ${JSON.stringify(activities)}.

                Houd hier rekening mee bij het geven van je aanbevelingen.

                Je respons moet als volgt gestruktureerd zijn:

                1. Doel:
                2. Aanbevelingen:
            ` }
        ];

        personaGoalProgress(prompt, setSuggestion, setLoading);
    }

  return (
    <div style={{ display: personaNavigation === 'evaluations' ? 'block' : 'none' }}>

        <div className="table-container dashboard-container">
            <h2>Samenvatting van dagrapportages</h2>
                <button onClick={createReportsAnalasis}>Creëer samenvatting</button>
                <div className='insights-analysis-content-container'>
                    <p>{loading}</p>
                    <p>{suggestion.content}</p>
                </div>
        </div>

        <div className="table-container dashboard-container">
            <h2>Doelen rapportage</h2>
                <button onClick={createGoalProgress}>Creëer analyse</button>
                <div className='insights-analysis-content-container'>
                    <p>{loading}</p>
                    <p>{suggestion.content}</p>
                </div>
        </div>
    </div>
  )
}

export default Evaluations