import { db } from '../../../firebase/config';

const researchData = async (researches) => {

    const options = { month: 'numeric', year: 'numeric'};

    try {

        const array = [];

        // Check if there is research to process
        if (researches && researches.length > 0) {
            // Map over each selected research ID and process it
            const researchPromises = researches.map(async (research) => {

                if (!research) {
                    console.error(`Research not found.`);
                    return null;
                }

                // Create the base structure for storing research details
                const object = {
                    ResearchTitle: research.Title,
                    MeasureMoments: [],
                };

                // Fetch all measure moments associated with the research
                const measureMomentsSnapshot = await db
                    .collection('MeasureMoments')
                    .where('ResearchID', '==', research.ID)
                    .get();

                // Process each measure moment
                const measureMomentsPromises = measureMomentsSnapshot.docs.map(async (measureMomentDoc) => {
                    const measureMomentData = measureMomentDoc.data();

                    // Create the structure for the measure moment
                    const measureMomentObject = {
                        MeasureMomentTitle: measureMomentData.Title,
                        Responses: [],
                    };

                    // Fetch all responses for the current measure moment
                    const questionnaireResponsesSnapshot = await db
                        .collection('QuestionnairesResponses')
                        .where('MomentID', '==', measureMomentData.ID)
                        .where('ResearchID', '==', research.ID)
                        .get();

                    // Process each response in the measure moment
                    const responsesPromises = questionnaireResponsesSnapshot.docs.map(async (questionnaireResponsesDoc) => {
                        const responseData = questionnaireResponsesDoc.data();

                        // Create a response object
                        const response = {
                            Field: '',
                            Response: responseData.Input,
                            Date: responseData.Timestamp.toDate().toLocaleDateString("nl-NL", options),
                        };

                        // Fetch field data for the current response
                        const questionnaireFieldsSnapshot = await db
                            .collection('QuestionnaireFields')
                            .where('ID', '==', responseData.FieldID)
                            .get();

                        // Add the field question to the response object
                        questionnaireFieldsSnapshot.docs.forEach((fieldsDoc) => {
                            const fieldsData = fieldsDoc.data();
                            response.Field = fieldsData.Question;
                        });

                        // Add the response to the measure moment
                        measureMomentObject.Responses.push(response);
                    });

                    // Wait for all responses to be processed for the measure moment
                    await Promise.all(responsesPromises);
                    object.MeasureMoments.push(measureMomentObject);
                });

                // Wait for all measure moments to be processed for the research
                await Promise.all(measureMomentsPromises);

                // Return the completed research object
                return object;
            });

            // Wait for all research processing promises
            const results = await Promise.all(researchPromises);

            // Filter out any null results and add them to the array
            array.push(...results.filter((result) => result !== null));
        }

        // Return the final array of research analysis results
        return array;
       
    } catch (error) {
        // Handle and log any errors encountered during processing
        console.error('Error creating research data:', error);
    } 
}

export default researchData;