import EffectMeta from '../effects/EffectMeta'
import PersonaEffectIndicators from './goals/PersonaEffectIndicators'

const PersonaGoalData = ({ goal, personaId }) => {

  const effects = goal.Effects

  return (
    <>
        {effects && effects.map(effect => (
            <div key={effect}>
                {<EffectMeta item={effect}/>}
                <PersonaEffectIndicators effectId={effect} personaId={personaId} />
            </div>
        ))}
    </>
  )
}

export default PersonaGoalData