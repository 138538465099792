import { useFirestoreID } from "../../../firebase/useFirestore";
import useSettings from "../../../hooks/Settings";
import TotalResponses from '../TotalResponses';

const ScaleMomentVisualisation = ({ data, field }) => {

    // Hooks
    const primaryColor = useSettings()?.PrimaryColor || "#000"; // Fallback to black color

    // Firestore
    const fields = useFirestoreID("QuestionnaireFields", field || "");

    // Function to calculate width percentage based on scores and field ranges
    const calculateWidthPercentage = (averageScore, reachStart, reachEnd) => {
        const range = reachEnd - reachStart;
        const normalizedScore = ((averageScore - reachStart) / range) * 100;
        return Math.max(0, Math.min(100, normalizedScore)); // Ensure it's between 0% and 100%
    };

    return (
        <>
            <div id="scale-visualisation-title-container">
                <h2>Gemiddelde score</h2>
                <TotalResponses totalResponses={data?.totalResponses} />
            </div>
            <div id="scale-visualisation-scale-container">
                {/* Map over fields and match with groupedData */}
                {fields && data?.groupedData ? (
                    fields.map((fieldItem, index) => {
                        // Safely access the average score
                        const averageScore = parseFloat(data.groupedData[index]?.average) || 0;

                        return (
                            <div key={fieldItem.ID} id="scale-visualisation-scale-item-container">
                                {/* Left Label */}
                                <div className="scale-visualisation-label-container">
                                    <p>{fieldItem.ReachStart}</p>
                                    <p>{fieldItem.ReachStartLabel}</p>
                                </div>

                                {/* Scale Box */}
                                <div id="scale-visualisation-scale-box-container">
                                    <div
                                        id="scale-visualisation-average-score-container"
                                        style={{
                                            width: `${calculateWidthPercentage(
                                                averageScore,
                                                fieldItem.ReachStart,
                                                fieldItem.ReachEnd
                                            )}%`,
                                        }}
                                    >
                                        <div id="scale-visualisation-avarage-score-item-container">
                                            <h1 style={{ color: primaryColor }}>
                                                {averageScore.toFixed(1)}
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                {/* Right Label */}
                                <div className="scale-visualisation-label-container">
                                    <p>{fieldItem.ReachEnd}</p>
                                    <p>{fieldItem.ReachEndLabel}</p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p>Geen data beschikbaar</p>
                )}
            </div>
        </>
    );
};

export default ScaleMomentVisualisation;
