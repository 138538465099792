import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import spinner from '../../../images/spinner-ripple.svg'

const DashboardBarchartSmall = ({data, isLoading, color}) => {
  return (
    <>
        {isLoading ? 
        <div id="graph-loading-container">
            <img src={spinner} alt="spinner" className="spinner"/>
        </div>
        :
        <ResponsiveContainer width="100%" height={200}>
            <BarChart
            width={125}
            height={100}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey='Resultaat' fill={color} activeBar={<Rectangle fill={color} stroke={color} />} />
        </BarChart>
    </ResponsiveContainer>
    }
  </>
  )
}

export default DashboardBarchartSmall