import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import useSettings from "../../hooks/Settings";
import { useFirestoreGeneral, useFirestoreOrderBy, useFirestore } from '../../firebase/useFirestore';
import sentimentAnalyse from '../../components/AI/SentimentAnalyse';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client";
import uuid from 'react-uuid';
import Select from 'react-select';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

const AIResearchAnalysis = () => {
     // State
     const [questionnaireId, setQuestionnaireId] = useState('');
     const [questionFieldId, setQuestionFieldId] = useState('');
     const [suggestion, setSuggestion] = useState([]);
     const [loading, setLoading] = useState('none');
     const [selectedResearch, setSelectedResearch] = useState([]);
     const [crossResearchResults, setCrossResearchResults] = useState([]);
     const [averageInput, setAverageInput] = useState(null);
     const [researchSuggestion, setResearchSuggestion] = useState([]);
     const [researchAnalysisLoading, setResearchAnalysisLoading] = useState('none');
 
     // Hooks
     const history = useHistory();
     const secundairyColor = useSettings().SecundairyColor;
 
     // Firestore
     const allResearch = useFirestoreOrderBy('Research', 'Position', 'asc');
     const researchQuestions = useFirestoreGeneral('QuestionnaireFields', 'QuestionnaireID', questionnaireId ? questionnaireId : '');
     const responses = useFirestoreGeneral('QuestionnairesResponses', 'FieldID', questionFieldId ? questionFieldId : '');
     const insights = useFirestore('Insights')
     const questionaireFields = useFirestoreGeneral('QuestionnaireFields', 'ID', questionFieldId ? questionFieldId : '');

     // Update the selectedResearch state to use the selected options in react-select
    const handleResearchSelectChange = (selectedOptions) => {
        setSelectedResearch(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

     // Format the data for react-select
     const researchOptions = allResearch && allResearch.map((research) => ({
        value: research.ID,
        label: research.Title,
    }));

    // Function to save analysis
    const saveAnalysis = (e) => {
        const type = e.target.dataset.type;


        db.collection('Insights')
            .add({
                Content: suggestion.content ? suggestion.content : '',
                AverageInput: '', 
                CrossResearchResults: '',
                Type: type,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionnaireID: '',
                QuestionFieldID: '',
                Research: selectedResearch,
                ID: uuid(),
                Title: 'Inzicht ' + (insights.length + 1),
                Position: insights.length + 1,
            })
            .then(() => {
                history.push(`/${client}/insights`);
            })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
    };

     // Onclick function to create research analysis
     const createResearchAnalysis = async () => {
        try {
            // Set loading state to indicate processing
            setResearchAnalysisLoading('block');
            setResearchSuggestion([]);
            const array = [];
    
            // Check if there are selected research IDs to process
            if (selectedResearch && selectedResearch.length > 0) {
                // Map over each selected research ID and process it
                const researchPromises = selectedResearch.map(async (researchId) => {
                    // Find the research object by its ID in the local data
                    const researchSnapshot = allResearch.find((research) => research.ID === researchId);
    
                    if (!researchSnapshot) {
                        console.error(`Research with ID ${researchId} not found.`);
                        return null;
                    }
    
                    // Create the base structure for storing research details
                    const object = {
                        ResearchTitle: researchSnapshot.Title,
                        MeasureMoments: [],
                    };
    
                    // Fetch all measure moments associated with the research
                    const measureMomentsSnapshot = await db
                        .collection('MeasureMoments')
                        .where('ResearchID', '==', researchSnapshot.ID)
                        .get();
    
                    // Process each measure moment
                    const measureMomentsPromises = measureMomentsSnapshot.docs.map(async (measureMomentDoc) => {
                        const measureMomentData = measureMomentDoc.data();
    
                        // Create the structure for the measure moment
                        const measureMomentObject = {
                            MeasureMomentTitle: measureMomentData.Title,
                            Responses: [],
                        };
    
                        // Fetch all responses for the current measure moment
                        const questionnaireResponsesSnapshot = await db
                            .collection('QuestionnairesResponses')
                            .where('MomentID', '==', measureMomentData.ID)
                            .where('ResearchID', '==', researchSnapshot.ID)
                            .get();
    
                        // Process each response in the measure moment
                        const responsesPromises = questionnaireResponsesSnapshot.docs.map(async (questionnaireResponsesDoc) => {
                            const responseData = questionnaireResponsesDoc.data();
    
                            // Create a response object
                            const response = {
                                Field: '',
                                Response: responseData.Input,
                                Date: responseData.Timestamp,
                            };
    
                            // Fetch field data for the current response
                            const questionnaireFieldsSnapshot = await db
                                .collection('QuestionnaireFields')
                                .where('ID', '==', responseData.FieldID)
                                .get();
    
                            // Add the field question to the response object
                            questionnaireFieldsSnapshot.docs.forEach((fieldsDoc) => {
                                const fieldsData = fieldsDoc.data();
                                response.Field = fieldsData.Question;
                            });
    
                            // Add the response to the measure moment
                            measureMomentObject.Responses.push(response);
                        });
    
                        // Wait for all responses to be processed for the measure moment
                        await Promise.all(responsesPromises);
                        object.MeasureMoments.push(measureMomentObject);
                    });
    
                    // Wait for all measure moments to be processed for the research
                    await Promise.all(measureMomentsPromises);
    
                    // Return the completed research object
                    return object;
                });
    
                // Wait for all research processing promises
                const results = await Promise.all(researchPromises);
    
                // Filter out any null results and add them to the array
                array.push(...results.filter((result) => result !== null));
            }
    
            // sentiment analysis
            const prompt = [
                { "role": "system", "content": "Je bent een expert op het gebied van onderoeksanalyses." },
                { "role": "user", "content": `Ik heb ${array.length} onderzoeken gedaan. 
                Dit zijn alle resultaten: ${JSON.stringify(array)}.
                Kun je een analyse doen van de resultaten. 
                Focus je vooral op interessante inzichten op basis van de relaties tussen de resultaten.
                Je mag GEEN uitleg geven over de opzet van het onderzoek. 
                Ik wil alleen een analyse van de resultaten.
                ` }
            ];
            sentimentAnalyse(prompt, setResearchSuggestion, setLoading);
        } catch (error) {
            // Handle and log any errors encountered during processing
            console.error('Error creating research analysis:', error);
        } finally {
            // Reset the loading state after processing
            setResearchAnalysisLoading('none');
        }
    };


  return (
    <div class="table-container dashboard-container">
        <h3>Onderzoeks analyse</h3>
        <p>Creeer een AI analyses van één of meer volledige onderzoeken.</p>
        <p><b>Selecteer onderzoek(en)</b></p>
        {/* Use react-select for multiple selection */}
        <Select
            isMulti
            options={researchOptions}
            onChange={handleResearchSelectChange}
            value={researchOptions && researchOptions.filter(option => selectedResearch.includes(option.value))}
        />
        <div className='start-insights-button-container' onClick={createResearchAnalysis}>
            <KeyboardDoubleArrowRightOutlinedIcon style={{fill: secundairyColor ? secundairyColor : 'black'}}/>
            <p style={{color: secundairyColor ? secundairyColor : 'black'}}>Analyseer</p>
        </div>
        <h3>Analyse</h3>
        <div className='insights-analysis-content-container'>
            <p style={{ display: researchAnalysisLoading }}>Analyseren...</p>
            <p>{researchSuggestion.content}</p>
        </div>
        <div>
            <button data-type='sentiment-analyse-researches' onClick={saveAnalysis}>Sla op</button>
        </div>
    </div>
  )
}

export default AIResearchAnalysis