import firebase from "firebase";
import React, { useState, useRef, useEffect } from "react";
import Location from "../../hooks/Location";
import { useFirestoreID } from "../../firebase/useFirestore";
import { db } from '../../firebase/config';

const AIInterviewer = () => {
  // State
  const [events, setEvents] = useState([]);
  const [starting, setStarting] = useState('Klik op starten om te beginnen');
  // New state to store the stop button’s background color.
  const [stopButtonColor, setStopButtonColor] = useState('#ccc'); // default color

  const pcRef = useRef(null);           // Voor de RTCPeerConnection
  const localStreamRef = useRef(null);  // Voor de lokale media stream
  const audioElRef = useRef(null);      // Voor het audio element
  const dcRef = useRef(null);           // Voor de data channel

  // Get url params
  const questionnaireId = Location()[3];
  const languageCode = Location()[8];

  // Firestore
  const questionaires = useFirestoreID('Questionnaires', questionnaireId ? questionnaireId : '0');

  // Create a string of the questions
  const questions = async () => {
    if (!questionaires) return '';

    const array = [];

    for (const q of questionaires) {
      const object = {
        title: q.Title,
        description: q.Description,
        listOfQuestions: []
      };

      const questionsObject = await db.collection('QuestionnaireFields')
        .where('QuestionnaireID', '==', q.ID)
        .get();

      // Loop over the docs (note: converting to an array with forEach or Promise.all is an option too)
      for (const qDoc of questionsObject.docs) {
        object.listOfQuestions.push(qDoc.data().Question);
      }

      array.push(object);
    }

    const string = JSON.stringify(array);
    return string;
  };

  useEffect(() => {
    // For debugging, log the questions.
    questions().then((qs) => console.log(qs));
  }, [questionaires]);

  // Start realtime session
  const startSession = async () => {
    setStarting('Starten');

    // Haal een ephemeral token op
    const realtimeToken = firebase.functions().httpsCallable("realtimeToken");
    const data = await realtimeToken();
    const EPHEMERAL_KEY = data.data.client_secret.value;

    if (EPHEMERAL_KEY) {
      setStarting('Je kunt praten!');
    }

    // Maak de RTCPeerConnection en sla deze op in de ref
    const pc = new RTCPeerConnection();
    pcRef.current = pc;

    // Maak het audio element aan en sla het op
    const audioEl = document.createElement("audio");
    audioEl.autoplay = true;
    audioElRef.current = audioEl;
    // Voeg het audio element toe aan de pagina (optioneel)
    document.querySelector(".audio-container").appendChild(audioEl);

    // Stel ontrack in om de remote stream te ontvangen
    pc.ontrack = (e) => {
      audioEl.srcObject = e.streams[0];
    };

    // Verkrijg de lokale audio stream
    const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    localStreamRef.current = localStream;
    // Voeg de lokale audio track toe
    localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));

    // Maak en configureer de data channel en sla deze op
    const dc = pc.createDataChannel("oai-events");
    dcRef.current = dc;

    // Verstuur een custom event via de data channel zodra deze open is
    dc.addEventListener("open", async () => {
      const questionsList = await questions();
      // Define two tools: one for weather (as in your original) and one for changing the stop button color.
      const event = {
        type: "session.update",
        session: {
          instructions: `
            Je spreekt in de taal met deze landencode: ${languageCode}.
            Je bent een AI interviewer van het bedrijf Deccos. 
            Je taak is om de kandidaat te interviewen.
            Je stelt de lijst met vragen uit deze vragenlijst: ${questionsList}.
            Voordat je begint stel je jezelf voor en leg je uit wat de kandidaat kan verwachten.
            Begin met de eerste vraag.
          `,
          voice: "sage",
          tools: [
            {
              type: "function",
              name: "change_stop_button_color",
              description: "Change the background color of the stop button",
              parameters: {
                type: "object",
                properties: {
                  color: {
                    type: "string",
                    description: "The new background color (e.g. 'red', '#ff0000')"
                  }
                },
                required: ["color"]
              }
            }
          ],
          tool_choice: "auto",
        },
      };
      dc.send(JSON.stringify(event));
    });

    // Luister naar berichten van de data channel
    dc.addEventListener("message", (e) => {
      const realtimeEvent = JSON.parse(e.data);
      // console.log("Received event:", realtimeEvent);

      // Check if the event is a function call for changing the stop button color.
      console.log(realtimeEvent.type, realtimeEvent.name);
      if (
        realtimeEvent.type === 'response.function_call_arguments.done' &&
        realtimeEvent.name === 'change_stop_button_color'
      ) {
        try {
          const args = JSON.parse(realtimeEvent.arguments);
          console.log("Parsed arguments for color change:", args);
          if (args.color) {
            setStopButtonColor(args.color);
          }
        } catch (err) {
          console.error("Error parsing arguments for color change:", err);
        }
      }

      setEvents((prevEvents) => [...prevEvents, realtimeEvent]);
    });

    // Maak en stel de offer in
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    // Verstuur de offer naar de Realtime API
    const model = "gpt-4o-realtime-preview-2024-12-17";
    const sdpResponse = await fetch(
      `https://api.openai.com/v1/realtime?model=${model}`,
      {
        method: "POST",
        body: offer.sdp,
        headers: {
          Authorization: `Bearer ${EPHEMERAL_KEY}`,
          "Content-Type": "application/sdp",
        },
      }
    );

    const answer = {
      type: "answer",
      sdp: await sdpResponse.text(),
    };

    await pc.setRemoteDescription(answer);
  };

  // Cleanup functie om alle resources vrij te geven
  const endSession = () => {
    // Sluit de data channel indien deze bestaat
    if (dcRef.current) {
      try {
        dcRef.current.close();
      } catch (e) {
        console.error("Fout bij sluiten van data channel:", e);
      }
      dcRef.current = null;
    }

    // Sluit de RTCPeerConnection indien deze bestaat
    if (pcRef.current) {
      try {
        pcRef.current.close();
      } catch (e) {
        console.error("Fout bij sluiten van RTCPeerConnection:", e);
      }
      pcRef.current = null;
    }

    // Stop alle tracks van de lokale media stream
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
      localStreamRef.current = null;
    }

    // Verwijder het audio element van de DOM indien nodig
    if (audioElRef.current) {
      if (audioElRef.current.parentNode) {
        audioElRef.current.parentNode.removeChild(audioElRef.current);
      }
      audioElRef.current = null;
    }

    // Reset de events
    setEvents([]);
    setStarting('Klik op starten om te beginnen');
  };

  // Als je wilt dat de sessie stopt wanneer de component unmount, kun je gebruik maken van useEffect
  useEffect(() => {
    return () => {
      endSession();
    };
  }, []);

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <h1>AI interviewer</h1>
        </div>
        <div>
          <button onClick={startSession}>Start</button>
          {/* Apply the dynamic background color via inline style */}
          <button onClick={endSession} style={{ backgroundColor: stopButtonColor }}>
            Stop Sessie
          </button>
        </div>
        <div>
          <p>{starting}</p>
        </div>
        <div className="audio-container">
          {/* Het audio element wordt dynamisch aangemaakt */}
        </div>
        <div>
          <h2>Reacties</h2>
          {events.map((event, i) => (
            <ul key={i}>
              {event?.item?.content?.[0]?.transcript && (
                <div>
                  <li>{event.item.content[0].transcript}</li>
                </div>
              )}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AIInterviewer;

