import { useState, useContext, useRef } from 'react';
import { db, timestamp } from "../../../firebase/config";
import { SavedIcon } from "../../../StateManagment/SavedIcon";
import deleteIcon from '../../../images/icons/delete-icon.png'
import Tooltip from '../../common/Tooltip';
import ButtonClicked from '../../../hooks/ButtonClicked';
import { client } from "../../../hooks/Client";
import uuid from 'react-uuid';
import { useFirestoreGeneralOrderBy } from '../../../firebase/useFirestore';
import useSettings from '../../../hooks/Settings';
import plusButton from '../../../images/icons/plus-icon.png'

const MatrixOne = ({field, index}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    const primaryColor = useSettings().PrimaryColor
    const secundairyColor = useSettings().SecundairyColor;
    const tertiaryColor = useSettings().TertairyColor;
    const colors = [primaryColor, secundairyColor, tertiaryColor];

    // Firestore
    const matrixRows = useFirestoreGeneralOrderBy('MatrixQuestionRows', 'Field', field.ID, 'Position', 'asc')
    const matrixColumns = useFirestoreGeneralOrderBy('MatrixQuestionColumns', 'Field', field.ID, 'Position', 'asc')

    // Helper function to get a random color
    const getRandomColor = () => {
      const randomIndex = Math.floor(Math.random() * colors.length); // Get a random index
      return colors[randomIndex]; // Return the color at that index
    }

    // Add Matrix Row
    const addMatrixRow = (e) => {

        ButtonClicked(e, 'Toegevoegd')
 
        db.collection('MatrixQuestionRows')
         .doc()
         .set({
             Title: '',
             Question: field.Question,
             Field: field.ID,
             Compagny: client,
             CompagnyID: client,
             Timestamp: timestamp,
             Position: matrixRows.length + 1,
             ID: uuid(),
             Color: getRandomColor(),
         })
         .then(() => {
             setSaved('flex')
          })
     }
 
     // Add Matrix Column
     const addMatrixColumn = (e) => {
         
         ButtonClicked(e, 'Toegevoegd')
 
         db.collection('MatrixQuestionColumns')
         .doc()
         .set({
             Title: '',
             Question: field.Question,
             Field: field.ID,
             Compagny: client,
             CompagnyID: client,
             Timestamp: timestamp,
             Position: matrixColumns.length + 1,
             ID: uuid(),
             Color: getRandomColor(),
         })
         .then(() => {
             setSaved('flex')
          })
     }
 
     // Delete Matrix Row
     const deleteMatrixRow = (e) => {
         const docid = e.target.dataset.docid
 
         db.collection('MatrixQuestionRows')
         .doc(docid)
         .delete()
         .then(() => {
             setSaved('flex')
         })
     }
 
     // Delete Matrix Column
     const deleteMatrixColumn = (e) => {
         const docid = e.target.dataset.docid
 
         db.collection('MatrixQuestionColumns')
         .doc(docid)
         .delete()
         .then(() => {
             setSaved('flex')
         })
     }
 
        // Edit Matrix Row
     const rowTitleHandler = (e) => {
         const value = e.target.value
         const docid = e.target.dataset.docid
 
         db.collection('MatrixQuestionRows')
         .doc(docid)
         .update({
             Title: value
         })
         .then(() => {
             setSaved('flex')
         })
     }
 
     // Edit Matrix Column
     const columnTitleHandler = (e) => {
         const value = e.target.value
         const docid = e.target.dataset.docid
 
         db.collection('MatrixQuestionColumns')
         .doc(docid)
         .update({
             Title: value
         })
         .then(() => {
             setSaved('flex')
         })
     }

  return (
    <div id='edit-matrix-container' style={{display: field.Type === 'matrix-one' ? 'block' : 'none'}}>
      <div className='add-matrix-container'>
        <div className='add-matrix-item-container'>
          <Tooltip content='Rij toevoegen' top='-60px' width={'25px'}>
            <img src={plusButton} className='add-matrix-item-icon' onClick={addMatrixRow} />
          </Tooltip>
          <p>Voeg een rij toe</p>
        </div>
        <div className='add-matrix-item-container'>
          <Tooltip content='Kolom toevoegen' top='-60px' width={'25px'}>
            <img src={plusButton} className='add-matrix-item-icon' onClick={addMatrixColumn} />
          </Tooltip>
          <p>Voeg een kolom toe</p>
        </div>
      </div>

      <div id='matrix-grid-container' 
        style={{
          display: 'grid',
          gridTemplateColumns: `150px repeat(${matrixColumns.length}, 1fr)`, 
          gap: '10px'
        }}>
      {/* Matrix column titles */}
      <div className='matrix-grid'>
        {/* Placeholder for empty top-left cell */}
        <div className='matrix-grid-header'></div>
        {matrixColumns && matrixColumns.map((column) => (
          <div key={column.ID} className='matrix-grid-header'>
            <input type="text" defaultValue={column.Title} placeholder='Naam van kolom' data-docid={column.docid} onChange={columnTitleHandler} />
            <Tooltip content={`Verwijder ${column.Title}`} top='-60px'>
              <img src={deleteIcon} className='matrix-delete-icon' data-docid={column.docid} onClick={deleteMatrixColumn} alt="" />
            </Tooltip>
          </div>
        ))}
      </div>

      {/* Matrix rows and corresponding checkboxes */}
      {matrixRows && matrixRows.map((row) => (
        <div key={row.ID} className='matrix-grid-row'>
          <div className='matrix-grid-header'>
            <input type="text" defaultValue={row.Title} placeholder='Naam van rij' data-docid={row.docid} onChange={rowTitleHandler} />
            <Tooltip content={`Verwijder ${row.Title}`} top='-60px'>
              <img src={deleteIcon} alt="" className='matrix-delete-icon' data-docid={row.docid} onClick={deleteMatrixRow} />
            </Tooltip>
          </div>
          {matrixColumns && matrixColumns.map((column) => (
            <div key={column.ID} className='matrix-grid-item'>
              <input id='matrix-column-input' type="checkbox" value={column.Title} />
            </div>
          ))}
        </div>
      ))}
    </div>

  </div>
  )
}

export default MatrixOne