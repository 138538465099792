import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import TotalResponses from '../TotalResponses';

const MatrixMomentLineChart = ({ data, lowerScale, upperScale, totalResponses }) => {
  const [hoveredLine, setHoveredLine] = useState(null);

  // Ensure data is not empty before processing
  if (!data || data.length === 0) {
    return <p>Geen data aanwezig</p>;
  }

  // Extract unique keys for dynamic data
  const uniqueRowTitles = Object.keys(data[0]).filter((key) => key !== "name");

  // Custom legend component
  const CustomizedLegend = ({ payload }) => (
    <ul>
      {payload.map((entry, index) => {
        const rowTitle = entry.dataKey;

        return (
          <li
            key={`item-${index}`}
            onMouseEnter={() => setHoveredLine(rowTitle)}
            onMouseLeave={() => setHoveredLine(null)}
            style={{
              cursor: "pointer",
              fontWeight: hoveredLine === rowTitle ? "bold" : "normal",
              color: entry.color,
            }}
          >
            {rowTitle}
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data.map((item) =>
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => {
                if (key === "name") {
                  return [key, value];
                } else {
                  return [key, value.value];
                }
              })
            )
          )}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, upperScale]} />
          <Tooltip />
          <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
          {uniqueRowTitles.map((rowTitle, index) => (
            <Line
              key={`line-${index}`}
              type="monotone"
              dataKey={rowTitle}
              stroke={data[0][rowTitle]?.color || "#206c7f"}
              strokeWidth={2}
              opacity={hoveredLine && hoveredLine !== rowTitle ? 0.3 : 1}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default MatrixMomentLineChart;
