import { useState, useContext, useRef } from 'react';
import { db, timestamp } from "../../firebase/config";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import deleteIcon from '../../images/icons/delete-icon.png'
import otherIcon from '../../images/icons/other-icon.png'
import Tooltip from '../common/Tooltip';
import ButtonClicked from '../../hooks/ButtonClicked';
import { client } from "../../hooks/Client";
import uuid from 'react-uuid';
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore';
import useSettings from '../../hooks/Settings';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import createSuggestion from '../AI/CreateSuggestion';
import plusIcon from '../../images/icons/plus-icon.png'
import DraggableItems from "../../components/common/DraggableItems";
import robotImage from "../../images/icons/robot-icon.png"
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import plusButton from '../../images/icons/plus-icon.png'
import MatrixOne from './questionTypes/MatrixOne';

const QuestionnaireField = ({field, index}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // States
    const [edit, setEdit] = useState('none')
    const [multiple, setMultiple] = useState('')
    const [suggestion, setSuggestion] = useState('')
    const [loading, setLoading] = useState('none')
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Refs 
     const sectionIdRef = useRef('field');
     const sectionRef = useRef('field')

    // Hooks
    const primaryColor = useSettings().PrimaryColor
    const secundairyColor = useSettings().SecundairyColor;
    const tertiaryColor = useSettings().TertairyColor;
    const colors = [primaryColor, secundairyColor, tertiaryColor];

    // Firestore
    const multipleOptions = useFirestoreGeneralOrderBy('MultipleQuestionOptions', 'Field', field.ID, 'Position', 'asc')

    // Helper function to get a random color
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length); // Get a random index
        return colors[randomIndex]; // Return the color at that index
      }

      // Explainer Handler
    const explainerHandler = (e) => {
        const value = e.target.value 
        const docid = e.target.dataset.docid 

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Explainer: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Type Handler
    const typeHandler = (e) => {

        const type = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Type: type
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Reach Start Handler
    const reachStartHandler = (e) => {

        const reachStart = e.target.options[e.target.selectedIndex].value 
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            ReachStart: Number(reachStart)
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Reach Start Label Handler
    const reachStartLabelHandler = (e) => {

        const reachStartLabel = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            ReachStartLable: reachStartLabel
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Reach End Handler
    const reachEndHandler = (e) => {

        const reachEnd = e.target.options[e.target.selectedIndex].value 
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            ReachEnd: Number(reachEnd)
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Reach End Label Handler
    const reachEndLabelHandler = (e) => {

        const reachEndLabel = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            ReachEndLabel: reachEndLabel
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Multiple Handler
    const saveMultiple = (e) => {

        const id = e.target.dataset.id

        db.collection('MultipleQuestionOptions')
        .doc()
        .set({
            Option: multiple,
            Question: '',
            Field: id,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: multipleOptions.length + 1,
            ID: uuid(),
            Color: getRandomColor()
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Add Other Handler
    const addOtherHandler = (e) => {

        const id = e.target.dataset.id

        console.log(multipleOptions.length + 1)

        db.collection('MultipleQuestionOptions')
        .doc()
        .set({
            Option: 'other',
            Question: field.Question,
            Field: id,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: multipleOptions.length + 1,
            ID: uuid(),
            Color: getRandomColor()
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Edit Multiple Handler
    const editMultipleHandler = (e) => {

        const value = e.target.value

        const docid = e.target.dataset.docid

        db.collection('MultipleQuestionOptions')
        .doc(docid)
        .update({
            Option: value
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Delete Multiple Handler
    const deleteMulitple = (e) => {

        const docid = e.target.dataset.docid

        db.collection('MultipleQuestionOptions')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    // Create Suggestion
    const createNewSuggestion = (e) => {

        const prompt = [{"role": "system", "content": "You are a social impact specialist."},
        {"role": "user", "content": `Geef een lijst met antwoordopties voor deze vraag: ${field.Question}.
        Formateer de antwoordopties als een array:
        Bijvoorbeeld:
        [{item: option1}, {item: option2}, {item: option3}]. 
        Geef alleen de array als antwoord. Formateer de array in JSON format.`}]

        setSuggestion([])

        createSuggestion(prompt, setSuggestion, setLoading)

    }

    // Add Suggestion
    const addSuggestion = (e) => {

        const value = e.target.dataset.item

        db.collection('MultipleQuestionOptions')
        .doc()
        .set({
            Option: value,
            Question: field.Question,
            Field: field.ID,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: multipleOptions.length + 1,
            ID: uuid(),
            Color: getRandomColor()
        })
        .then(() => {
            setSaved('flex')
         })
        }

    return(
        <div className='question-type-display-container'>
            <div className='questionnaire-field-top-level-container'>
                <select name="" id="" onChange={typeHandler} defaultValue={field.Type} data-docid={field.docid} data-index={index} >
                    <option value="paragraph">Textvraag</option>
                    <option value="scale">Schaalvraag</option>
                    <option value="multiple-multiple">Meerkeuzevraag (meerdere antwoorden)</option>
                    <option value="multiple-one">Meerkeuzevraag (één antwoord)</option>
                    <option value="matrix-one">Matrix (één antwoord)</option>
                </select>
            </div>
            <div data-index={index}>
                <textarea className='question-subtitle-textarea' data-index={index} placeholder='Geef een toelichting (optioneel)'  cols="10" rows="5" data-docid={field.docid} defaultValue={field.Explainer} onChange={explainerHandler}></textarea>
            </div>

            {/* Paragraph */}
            <div className='questionnaire-field-type-paragraph-container' style={{display: field.Type === 'paragraph' ? 'block' : 'none'}}>
                <p className='questionnaire-field-type-text' data-index={index}>Antwoord</p>
            </div>

            {/* Scale */}
            <div className='questionnaire-field-scale-container' style={{display: field.Type === 'scale' ? 'flex' : 'none'}}>
                <div className='edit-scale-container'>
                    <div className='select-scale-container' data-index={index}>
                        <select name="" id="" onChange={reachStartHandler} defaultValue={field.ReachStart} data-docid={field.docid}  data-index={index}>
                            <option value="">-- Selecteer onderwaarde ---</option>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                        </select>
                        <input type="text" placeholder='Voeg label toe (optioneel)' defaultValue={field.ReachStartLable} data-docid={field.docid} onChange={reachStartLabelHandler} data-index={index}/>
                    </div>
                    <p id='scale-reach-symbol' data-index={index}>t/m</p>
                    <div className='select-scale-container' data-index={index}>
                        <select name="" id="" onChange={reachEndHandler} data-docid={field.docid} defaultValue={field.ReachEnd} data-index={index}>
                            <option value="">-- Selecteer bovenwaarde --</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                        <input type="text" placeholder='Voeg label toe (optioneel)' data-docid={field.docid} defaultValue={field.ReachEndLabel} onChange={reachEndLabelHandler} data-index={index}/>
                    </div>
                </div>
            </div>

            {/* Multiple-multiple */}
            <div style={{display: field.Type === 'multiple-multiple' ? 'block' : 'none'}}>
                <div>
                    <div className='questionnaire-suggestion-container'>
                        <Tooltip content='Antwoord toevoegen' top='-60px' width={'30px'}>
                            <img src={plusIcon} alt="" className='table-delete-icon' data-id={field.ID} onClick={saveMultiple}/> 
                        </Tooltip> 
                        <Tooltip content="Overig antwoord.. toevoegen" top='-80px' width={'30px'}>
                            <img src={otherIcon} alt="" data-id={field.ID} onClick={addOtherHandler}/>
                        </Tooltip>     
                        <Tooltip content="ImpactAI: Kan ik een suggestie doen?" top='-60px' width={'160px'}>
                            <img src={robotImage} alt="" onClick={createNewSuggestion} />
                        </Tooltip>
                        <div style={{display: loading}}>
                            <p>&#129504; nadenken...</p>
                        </div>
                    </div>
                    <div className='table-container' style={{display: suggestion !== "" ? 'block' : 'none'}}>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>OPTIE</th>

                            <th style={{backgroundColor: secundairyColor}}>TOEVOEGEN</th>
                        </tr>
                        {suggestion && suggestion.map(item => (
                            <tr key={item.item}>
                                <td>
                                    <p>{item.item}</p>
                                </td>
                                <td>
                                    <Tooltip content='Voeg toe aan vragen'top='-80px' width={'180px'}>
                                        <img src={plusIcon} alt="" className='table-delete-icon' onClick={addSuggestion} data-item={item.item}/> 
                                    </Tooltip>                                   
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                </div> 
                <div id='options-container'>
                    {multipleOptions && multipleOptions.map( (item, index) => (
                        <>
                        {placeholderIndex === index && (
                            <div className="placeholder-row-multiple" style={{backgroundColor: secundairyColor}}>
                            </div>
                        )}
                        <DraggableItems 
                        item={item}
                        input={
                            <>
                                {item.Option === 'other' ? 
                                    <>
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                    <p id='multiple-question-other'>Overig...</p>
                                    <img className='muitple-delete-icon' data-docid={item.docid} data-item={item.Option} onClick={deleteMulitple} src={deleteIcon} alt="" data-index={index}/>
                                    </>
                                :
                                    <>
                                        <CheckBoxOutlineBlankOutlinedIcon />
                                        <input className='question-checkbox-input' placeholder='Schrijf hier de antwoordoptie' data-docid={item.docid} defaultValue={item.Option} onChange={editMultipleHandler}/>
                                        <img className='muitple-delete-icon' data-docid={item.docid} data-item={item.Option} onClick={deleteMulitple} src={deleteIcon} alt="" data-index={index}/>
                                    </>
                                }
                            </>
                        }
                        parentIndex={index}
                        collection={'MultipleQuestionOptions'}
                        itemArray={multipleOptions}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={item.Option}
                        setPlaceholderIndex={setPlaceholderIndex}
                        sectionRef={sectionRef}
                        sectionIdRef={sectionIdRef}
                        >
                         </DraggableItems>
                        </>
                    ))}
                </div>
            </div>

            {/* Multiple-one */}
            <div style={{display: field.Type === 'multiple-one' ? 'block' : 'none'}}>
                <div>
                    <div className='questionnaire-suggestion-container'>
                        <Tooltip content='Antwoord toevoegen' top='-60px' width={'30px'}>
                            <img src={plusIcon} alt="" className='table-delete-icon' data-id={field.ID} onClick={saveMultiple}/> 
                        </Tooltip>  
                        <Tooltip content="Overig antwoord.. toevoegen" top='-80px' width={'30px'}>
                            <img src={otherIcon} alt="" data-id={field.ID} onClick={addOtherHandler}/>
                        </Tooltip>     
                        <Tooltip content="ImpactAI: Kan ik een suggestie doen?" top='-60px' width={'160px'}>
                            <img src={robotImage} alt="" onClick={createNewSuggestion} />
                        </Tooltip>
                        <div style={{display: loading}}>
                            <p>&#129504; nadenken...</p>
                        </div>
                    </div>
                    <div className='table-container' style={{display: suggestion !== "" ? 'block' : 'none'}}>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>OPTIE</th>
                            <th style={{backgroundColor: secundairyColor}}>TOEVOEGEN</th>
                        </tr>
                        {suggestion && suggestion.map(item => (
                            <tr key={item.item}>
                                <td>
                                    <p>{item.item}</p>
                                </td>
                                <td>
                                    <Tooltip content='Voeg toe aan vragen'top='-80px' width={'180px'}>
                                        <img src={plusIcon} alt="" className='table-delete-icon' onClick={addSuggestion} data-type={item.type} data-item={item.item}/> 
                                    </Tooltip>                                   
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                   
                </div> 
                <div id='options-container'>
                    {multipleOptions && multipleOptions.map( (item, index) => (
                        <>
                        {placeholderIndex === index && (
                            <div className="placeholder-row-multiple" style={{backgroundColor: secundairyColor}}>
                            </div>
                        )}
                        <DraggableItems 
                        item={item}
                        input={
                            <>
                                {item.Option === 'other' ? 
                                    <>
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                    <p id='multiple-question-other'>Overig...</p>
                                    <img className='muitple-delete-icon' data-docid={item.docid} data-item={item.Option} onClick={deleteMulitple} src={deleteIcon} alt="" data-index={index}/>
                                    </>
                                :
                                    <>
                                        <CheckBoxOutlineBlankOutlinedIcon />
                                        <input className='question-checkbox-input' placeholder='Schrijf hier de antwoordoptie' data-docid={item.docid} defaultValue={item.Option} onChange={editMultipleHandler}/>
                                        <img className='muitple-delete-icon' data-docid={item.docid} data-item={item.Option} onClick={deleteMulitple} src={deleteIcon} alt="" data-index={index}/>
                                    </>
                                }
                            </>
                        }
                        parentIndex={index}
                        collection={'MultipleQuestionOptions'}
                        itemArray={multipleOptions}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={item.Option}
                        setPlaceholderIndex={setPlaceholderIndex}
                        sectionRef={sectionRef}
                        sectionIdRef={sectionIdRef}
                        >
                         </DraggableItems>
                        </>
                    ))}
                </div>
            </div>

            {/* Matrix-one */}
            <MatrixOne field={field} index={index}/>
        </div>
    )
}

export default QuestionnaireField