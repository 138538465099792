import {
    LineChart as RechartsLineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
  } from 'recharts';
  import TotalResponses from '../TotalResponses';

const ScaleDevelopmentLineChart = ({ groupedData, color, totalResponses }) => {
  // Transform groupedData into the line chart format
  const lineData =
    groupedData && groupedData.length > 0
      ? groupedData.map((item) => ({
          name: item.moment, // Use 'moment' as the x-axis label
          value: parseFloat(item.average), // Convert 'average' to a number for the y-axis
        }))
      : [{ name: 'No data', value: 0 }];

 // Custom Tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload; // Get current data point
      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
          }}
        >
          <p style={{ margin: 0, fontWeight: 'bold' }}>{name}</p>
          <p style={{ margin: 0 }}>{`Gemiddelde score: ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={300}>
        <RechartsLineChart data={lineData} margin={{ top: 20, right: 20, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis domain={[0, 'auto']} tick={{ fontSize: 12 }} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" dataKey="value" stroke={color} strokeWidth={2} dot={{ r: 5 }} />
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ScaleDevelopmentLineChart