import { useFirestoreNoCompagny } from '../../firebase/useFirestore'

const CompagnyMeta = ({id}) => {

    const companyName = useFirestoreNoCompagny('CompagnyMeta', 'CompagnyID', id ? id : '')

  return (
    <>
        {companyName && companyName.map(item => (
            <>{item.CommunityName}</>
        ))}
    </>
  )
}

export default CompagnyMeta