import OpenQuestionSlider from "../liveReport/OpenQuestionSlider"
import MultipleData from "../graphData/MultipleData"
import ScaleData from "../graphData/ScaleData"
import ParagraphData from "../graphData/ParagraphData"
import MatrixData from "../graphData/MatrixData"

const DataType = ({ 
    type, 
    fieldId, 
    indicator, 
    startDate, 
    endDate, 
    researchId, 
    graphType, 
    personaId,
    paragrapgMomentVisibilityStyle,
    projectId
   }) => {

    // Determine the correct field value
    const field = indicator?.ID || fieldId;

     // Function to render chart type
     const renderData = () => {
        switch (type) {
          case 'multiple-one':
          case 'multiple-multiple':
            return <MultipleData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} projectId={projectId} />;
            
          case 'scale':
            return <ScaleData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} projectId={projectId}/>;
            
          case 'paragraph':
            { if (paragrapgMomentVisibilityStyle === 'both'){
              return <> <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} projectId={projectId} />
                      <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} projectId={projectId}/>
                    </>
              } else if(paragrapgMomentVisibilityStyle === 'graph'){
                return <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} projectId={projectId}/>
              } else if(paragrapgMomentVisibilityStyle === 'text'){
                return <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} projectId={projectId}/>
              } else {
                return <> <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} projectId={projectId}/>
                        <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} projectId={projectId}/>
                      </>
              }
            }
            
        case 'matrix-one':
        case 'matrix-multiple':
            return <MatrixData researchId={researchId} field={field} startDate={startDate} endDate={endDate} personaId={personaId} graphType={graphType} projectId={projectId}/>; 
            
          default:
            return <p>Unknown type</p>;
        }
      };


    return <>
    {renderData()}
    </>;

}

export default DataType