import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Location from "../../hooks/Location"
import { useFirestoreID, useFirestore } from "../../firebase/useFirestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase/config";

const GoalDetail = () => {
  // State
  const [title, setTitle] = useState('')
  const [docid, setDocid] = useState('')
  const [description, setDescription] = useState('')
  const [selectedEffects, setSelectedEffects] = useState([])

  // Hooks
  const goalId = Location()[4]

  // Firestore
  const goals = useFirestoreID('PersonaGoals', goalId ? goalId : '')
  const effects = useFirestore('OutputEffects')

  // Set title of goal in state
  useEffect(() => {
    goals && goals.forEach(item => {
        setTitle(item.Goal)
        setDocid(item.docid)
        setDescription(item.Description)
        setSelectedEffects(item.Effects)
    })
  }, [goals])

  const titleHandler = (e) => {

    const title = e.target.value

    db.collection('PersonaGoals')
    .doc(docid)
    .update({
        Goal: title
    })

    setTitle(title)

  }

  const descriptionHandler = (e) => {

    const description = e.target.value

    db.collection('PersonaGoals')
    .doc(docid)
    .update({
        Description: description
    })

    setDescription(description)

  }

  const effectHandler = (e) => {

    const value = e.target.value
    const checked = e.target.checked

    // If the checkbox was checked, add its value to the selectedTargetgroups array; otherwise, remove it
    setSelectedEffects(prev => {

        if (!Array.isArray(prev)) {
            console.error('Expected an array for prev, but received:', prev);
            return []; // Return a default array to recover gracefully
          }

          console.log(prev);

        if (checked) {
            return [...prev, value]; // Adds the value if checked
          } else {
            return prev.filter(effect => effect !== value); // Removes the value if not checked
          }
    });

  }

  console.log(selectedEffects)

  const saveEffects = (e) => {

    console.log(selectedEffects)

    e.target.disabled = true
    e.target.innerHTML = 'Opslaan...'
    e.target.style.color = 'lightgrey'

    db.collection('PersonaGoals')
    .doc(docid)
    .update({
        Effects: selectedEffects
    })
    .then(() => {
        e.target.disabled = false
        e.target.innerHTML = 'Opslaan'
        e.target.style.color = 'green'
    })

  }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <FlagOutlinedIcon/>
                <h1>Doel instellingen</h1>
                <p>{title}</p>
            </div>
            <div className="table-container dashboard-container">
              <h2>Titel</h2>
              <input type="text" defaultValue={title} onChange={titleHandler} />
            </div>
            <div className="table-container dashboard-container">
              <h2>Omschrijving</h2>
              <textarea name="" id="" cols="30" rows="10" defaultValue={description} onChange={descriptionHandler}></textarea>
            </div>
            <div className="table-container dashboard-container">
              <h2>Gekoppelde effecten</h2>
              <div>
                {effects && effects.map(effect => (
                  <div key={effect.ID} className='fund-selector-item-container'>
                  <input 
                      type="checkbox" 
                      id={effect.ID} 
                      name="region" 
                      value={effect.ID} 
                      checked={selectedEffects?.includes(effect.ID)}
                      onChange={effectHandler} 
                  />
                  <label htmlFor={effect.ID}>{effect.Effect}</label>
                  </div>
                ))}
                <div>
                  <button className='button-simple' onClick={saveEffects}>Opslaan</button>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default GoalDetail