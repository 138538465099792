import { useFirestoreGeneral, useFirestoreID } from '../../firebase/useFirestore';
import MeetstandaardLogo from '../../images/logo-meetstandaard-alt.png'
import useSettings from "../../hooks/Settings";
import { useState } from 'react';
import { client } from '../../hooks/Client';
import { db } from "../../firebase/config";
import { useHistory } from "react-router-dom";
import Location from "../../hooks/Location"
import StakeholderMeta from '../../components/effects/StakeholderMeta';

const AddStandardEffectsToQuestionaire = () => {
    // State
    const [checkedEffects, setCheckedEffects] = useState([])

     // Hooks
     const questionaireId = Location()[3]
     const researchId = Location()[4]
     const secundairyColor = useSettings().SecundairyColor
     const history = useHistory()

    // Firestore
    const standardEffects = useFirestoreGeneral('OutputEffects', 'Standard', 'true')
    const questionaireField = useFirestoreID('QuestionnaireFields', questionaireId ? questionaireId : '')

    // Update the selected question
    const handleCheckboxChange = (id, docid) => {
        setCheckedEffects(prevState => {
            const index = prevState.findIndex(item => item.id === id);
            if (index > -1) {
                // The effect is currently checked, remove it from the array
                return prevState.filter((_, i) => i !== index);
            } else {
                // The effect is not checked, add it to the array
                return [...prevState, {id, docid}];
            }
        });
    };

    // Add questionaire field
    const updateQuestionaireField = async (item, index) => {

        console.log('item', item.docid)

        await db.collection('QuestionnaireFields')
        .doc(item.docid)
        .update({
            QuestionnaireID: questionaireId,
            Position: questionaireField.length + 1 + index,
        })
    }

    // Save effects to questionaire
    const updateEffects = (e) => {

        checkedEffects.map(async (item, index) => {

            // Get indicators for each effect
            const indicators = await getIndicators(item.id)

            // For each indicator, update the questionaire field
            indicators && indicators.map(async (indicator, index) => {
                await updateQuestionaireField(indicator, index)
            })
                
        })

        history.push(`/${client}/questionairebuilder/${questionaireId}/${researchId}`)


    }

    // Get indicators for effect
    const getIndicators = async (effectId) => {
        const indicators = await db.collection('QuestionnaireFields')
        .where('EffectId', 'array-contains', effectId)
        .get()

        return indicators.docs.map(doc => ({ ...doc.data(), docid: doc.id }));
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <img src={MeetstandaardLogo} alt=''/>
                <h1>Gestandaardiseerde effecten toevoegen</h1>
            </div>
            <div className="table-container dashboard-container">
                <h2>Gestandaardiseerde effecten</h2>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>SELECTEER</th>
                        <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                        <th style={{backgroundColor: secundairyColor}}>DOELGROEP</th>
                    </tr>
                        {standardEffects && standardEffects.map((effect, index) => (
                             <tr key={index}>
                                <td>
                                    <input 
                                    type="checkbox" 
                                    checked={checkedEffects.some(q => q.id === effect.ID)}
                                    onChange={() => handleCheckboxChange(effect.ID, effect.docid)}
                                    />
                                </td>
                                <td>
                                    <p className='table-p'>{effect.Effect}</p>
                                </td>
                                <td>
                                    {effect.Targetgroup?.map((item) => (
                                        <StakeholderMeta stakeholder={item}/>
                                    ))}
                                </td>
                            </tr>
                        ))}
                </table>
                <div className='button-container'>
                    <button className='button-simple' onClick={updateEffects}>Opslaan</button>
                </div>
            </div>

            
        </div>
    </div>
  )
}

export default AddStandardEffectsToQuestionaire