import { useFirestoreNoCompagnyTwo } from '../../firebase/useFirestore';
import { client } from '../../hooks/Client';

const SyncCounter = ({id}) => {

    // Firestore
  const syncs = useFirestoreNoCompagnyTwo('Synchronisations', 'Project', id, 'Parent', client);


  return (
    syncs && syncs.length
  )
}

export default SyncCounter