import { useFirestoreID } from '../../firebase/useFirestore'
import EffectMeta from '../effects/EffectMeta'
import PersonaIndicators from './reports/PersonaIndicators'

const PersonaReportScoreEffects = ({goalId, reportId}) => {

    const goals = useFirestoreID('PersonaGoals', goalId ? goalId : '')

  return (
    <div>
        {goals && goals.map(item => (
            <div key={item.docid}>
              {item.Effects && item.Effects.length > 0 && 
                item.Effects.map(item => (
                  <>
                    <EffectMeta 
                    item={item}
                    />
                    <PersonaIndicators 
                    effectId={item} 
                    goalId={goalId} 
                    reportId={reportId}
                    />
                  </>
                ))
              }
            </div>
        ))}
    </div>
  )
}

export default PersonaReportScoreEffects