import TotalResponses from '../TotalResponses';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from "recharts";

const ParagraphDevelopmentPieChart = ({ rawData, totalResponses }) => {

  // Process data to fit PieChart requirements
  const processPieData = (data) =>
    data.map((moment) => ({
      MomentName: moment.MomentName,
      data: moment.Categories.map((category) => ({
        name: category.Title,
        value: category.Words,
        color: category.Color, // Use the color from the data object
      })),
    }));

  const pieData = rawData ? processPieData(rawData) : [];

  // Custom Tooltip Renderer
  const renderCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, color } = payload[0].payload; // Extract color from payload
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p style={{ marginBottom: "5px", color }}>{name} ({value})</p>
          {/* <p style={{ margin: 0 }}>{`Value: `}</p> */}
        </div>
      );
    }
    return null;
  };

  // Custom Legend Renderer
  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              marginBottom: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 25,
              }}
            ></div>
            <span>{`${entry.name} (${entry.value})`}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={400}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {pieData.map((moment, index) => (
            <div
              key={`pie-container-${index}`}
              style={{ textAlign: "center", width: "45%" }}
            >
              {/* Moment name */}
              <h3 style={{ marginBottom: "10px" }}>{moment.MomentName}</h3>
              {/* Pie chart */}
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={moment.data}
                    dataKey="value"
                    nameKey="name"
                    outerRadius={100}
                  >
                    {moment.data.map((item, idx) => (
                      <Cell key={`cell-${idx}`} fill={item.color} /> // Use color from data object
                    ))}
                  </Pie>
                  <Tooltip content={renderCustomTooltip} /> {/* Use custom tooltip */}
                  <Legend
                    content={renderCustomLegend}
                    payload={moment.data.map((d) => ({
                      value: d.value,
                      name: d.name,
                      color: d.color, // Use the color directly from the processed data
                      payload: d,
                    }))}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          ))}
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default ParagraphDevelopmentPieChart;



