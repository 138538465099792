import { useFirestoreGeneralArrayContains, useFirestoreOrderBy } from '../../firebase/useFirestore'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from '../../firebase/config'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { timestamp } from '../../firebase/config'
import { client } from '../../hooks/Client'
import plusButton from '../../images/icons/plus-icon.png'
import AddItemTableRow from '../common/AddItemTableRow'
import useSettings from "../../hooks/Settings";
import Modal from 'react-modal';
import firebase from 'firebase'

const LiveReportMedia = ({parent}) => {
    // State
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [addType, setAddType] = useState('media')
    const [storytellingItems, setStorytellingItems] = useState([])
    const [selectedMedia, setSelectedMedia] = useState([])

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    Modal.setAppElement('#root');
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    // Firestore
    const allMedia = useFirestoreOrderBy('LiveReportMedia', 'Position', 'asc')
    const allQuotes = useFirestoreOrderBy('Quotes', 'Position', 'asc')
    const allAnecdotes = useFirestoreOrderBy('Anecdotes', 'Position', 'asc')
    const media = useFirestoreGeneralArrayContains('LiveReportMedia', 'Parent', parent.ID)
    const anecdotes = useFirestoreGeneralArrayContains('Anecdotes', 'Parent', parent.ID)
    const quotes = useFirestoreGeneralArrayContains('Quotes', 'Parent', parent.ID)

    // Display item
    const displayItem = (item) => {
        switch (item.Type) {
            case 'image/jpeg':
                return <img src={item.Media} alt="" style={{width: '100px'}}/>
            case 'video':
                return <video src={item.Media} alt="" style={{width: '100px'}}/>
            case 'quote':
                return <p>{item.Quote}</p>
            case 'anecdote':
                return <p>{item.Anecdote}</p>
            default:
                return <p>Onbekend</p>
        }
    }

    // Media type
    const mediaType = (type) => {
        switch (type) {
            case 'image/jpeg':
                return 'Afbeelding'
            case 'video':
                return 'Video'
            case 'quote':
                return 'Quote'
            case 'anecdote':
                return 'Anekdote'
            default:
                return 'Onbekend'
        }
    }

    // Create an array of all the storyytelling items
    const arrayOfStorytellingItems = () => {
        const array = []

        media && media.forEach(item => {
            array.push({
                Media: item.Media,
                Type: item.Type,
                docid: item.docid
            })
        })

        anecdotes && anecdotes.forEach(item => {
            array.push({
                Anecdote: item.Anecdote,
                Type: item.Type,
                docid: item.docid
            })
        })

        quotes && quotes.forEach(item => {
            array.push({
                Quote: item.Quote,
                Type: item.Type,
                docid: item.docid
            })
        })

        setStorytellingItems(array)
    }

    useEffect(() => {
        arrayOfStorytellingItems()
    }, [media, anecdotes, quotes])

    // Delete storytelling item
    const deleteStorytelling = (e) => {
        const docid = e.target.getAttribute('data-docid')

        db.collection('LiveReportMedia').doc(docid).delete()
        db.collection('Anecdotes').doc(docid).delete()
        db.collection('Quotes').doc(docid).delete()
    }

    // Add storytelling item
    const addStorytelling = () => {

        if (addType === 'media') {
            selectedMedia.forEach(item => {
                db.collection('LiveReportMedia')
                .doc(item.id)
                .update({
                    Parent: firebase.firestore.FieldValue.arrayUnion(parent.ID),
                })
            })
        }

        if (addType === 'quote') {
            console.log(selectedMedia)

            selectedMedia.forEach(item => {
                console.log(item)
                db.collection('Quotes')
                .doc(item.id)
                .update({
                    Parent: firebase.firestore.FieldValue.arrayUnion(parent.ID),
                })
            })
        }

        if (addType === 'anecdote') {
            selectedMedia.forEach(item => {
                db.collection('Anecdotes')
                .doc(item.id)
                .update({
                    Parent: firebase.firestore.FieldValue.arrayUnion(parent.ID),
                })
            })
        }

        setOpenModal(false)
    }

    // Adding type
    const addingType = () => {
        switch (addType) {
            case 'media':
                return 'media'
            case 'quote':
                return 'quote'
            case 'anecdote':
                return 'anekdote'
            default:
                return 'Onbekend'
        }
    }

   // Select media items
    const selectMediaItems = (e) => {
        const id = e.target.dataset.id;
        const url = e.target.dataset.url || '';
        const quote = e.target.dataset.quote || '';
        const anecdote = e.target.dataset.anecdote || '';

        // Check if the item is already in selected media
        if (selectedMedia.some(item => item.id === id)) {
            // Remove item if already selected
            const newMedia = selectedMedia.filter(item => item.id !== id);
            setSelectedMedia(newMedia);
        } else {
            // Add new item with both id and url
            setSelectedMedia([...selectedMedia, { id, url, quote, anecdote }]);
        }
    };

  return (
    <div id='live-report-media-container'>
        <p><b>Voeg storytelling toe</b></p>
        <table>
            <tr>
                <th style={{backgroundColor: secundairyColor}}>STORYTELLING</th>
                <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
            </tr>
            {storytellingItems && storytellingItems.map(item => (
            <tr key={item.ID}>
                <td>
                    {displayItem(item)}
                </td>
                <td>
                    <p>{mediaType(item.Type)}</p>
                </td>
                <td>
                    <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteStorytelling}/>
                </td>
            </tr>
            ))}
        </table>
        <AddItemTableRow toolTipContent={'Storytelling toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
        <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
            <div 
            className="add-options-container-item-container"
            onClick={() => {
            setOpenModal(prevState => !prevState);
            setAddType('media');
            }}
            >
                <img src={plusButton} alt="" />
                <p>Media (foto of video) toevoegen</p>
            </div>
            <div 
            className="add-options-container-item-container"
            onClick={() => {
            setOpenModal(prevState => !prevState);
            setAddType('quote');
            }}
            >
                <img src={plusButton} alt="" />
                <p>Quote toevoegen</p>
            </div>
            <div 
            className="add-options-container-item-container"
            onClick={() => {
            setOpenModal(prevState => !prevState);
            setAddType('anecdote');
            }}
            >
                <img src={plusButton} alt="" />
                <p>Anekdote toevoegen</p>
            </div>
        </div>
        <Modal
        isOpen={openModal}
        onRequestClose={openModal}
        style={modalStyles}
        contentLabel="Storytelling toevoegen"
        >
            <div>
                <h1>Selecteer {addingType()}</h1>
                <div>

                    {/* Media */}
                    {addType === 'media' && (
                        <div>
                            {allMedia && allMedia.map(item => (
                                <div className='select-storytelling-item-container'>
                                    <input 
                                    type="checkbox" 
                                    data-media={item.Media}
                                    data-id={item.docid}
                                    data-url={item.Media}
                                    defaultChecked={item.ID === media && media.filter(mediaItem => mediaItem.Media.includes(item.ID))} 
                                    onChange={selectMediaItems}
                                    />
                                    <img src={item.Media} alt="" style={{width: '100px'}}/>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Quote */}
                    {addType === 'quote' && (
                        <div>
                            {allQuotes && allQuotes.map(item => (
                                <div className='select-storytelling-item-container'>
                                    <input 
                                    type="checkbox" 
                                    data-quote={item.Quote}
                                    data-id={item.docid}
                                    defaultChecked={item.ID === quotes && quotes.filter(quoteItem => quoteItem.ID.includes(item.ID))} 
                                    onChange={selectMediaItems}
                                    />
                                    <p>{item.Quote}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Anecdote */}
                    {addType === 'anecdote' && (
                        <div>
                            {allAnecdotes && allAnecdotes.map(item => (
                                <div className='select-storytelling-item-container'>
                                    <input 
                                    type="checkbox" 
                                    data-anecdote={item.Anecdote}
                                    data-id={item.docid}
                                    defaultChecked={item.ID === anecdotes && anecdotes.filter(anecdoteItem => anecdoteItem.ID.includes(item.ID))} 
                                    onChange={selectMediaItems}
                                    />
                                    <p>{item.Anecdote}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    
                </div>
                <div id='modal-button-container'>
                    <button id='modal-cancel-button'onClick={() => setOpenModal(false)}>Annuleren</button>
                    <button id='modal-save-button' onClick={addStorytelling}>Opslaan</button>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default LiveReportMedia