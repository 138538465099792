import {useState, useEffect, useContext, useMemo} from 'react'
import { Responses } from "../../StateManagment/Responses";
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";
import EffectDatabase from "../meetstandaard/EffectDatabase";
import useTranslations from "../../hooks/useTranslations";

const FieldType = ({field}) => {
    // Context
    const [responses, setResponses] = useContext(Responses)

    // State
    const [range, setRange] = useState(0)

    // Hooks
    const effectDatabase = EffectDatabase()

    // Firestore 
    const multipleOptions = useFirestoreGeneralOrderBy('MultipleQuestionOptions', 'Field', field.ID, 'Position', 'asc')
    const matrixRows = useFirestoreGeneralOrderBy('MatrixQuestionRows', 'Field', field.ID, 'Position', 'asc')
    const matrixColumns = useFirestoreGeneralOrderBy('MatrixQuestionColumns', 'Field', field.ID, 'Position', 'asc')

    // Start and end range for the scale question
    const start = Number(field.ReachStart)
    const end = Number(field.ReachEnd)


    // Use the meta data for the matrix row title
    useEffect(() => {

        if(field.Type === 'scale'){
            const range = (start, end) => {
                return Array(end - start + 1).fill().map((_, idx) => start + idx)
            }

            const result = range(start, end)
            
            setRange(result)
        }

    }, [field])
    
    // Update the responses
    const updateResponses = (fieldID, input, optionType, itemid, fieldType) => {

        // Find the previous state of the respons 
        const previousResponses = responses.filter(response => response.FieldID === fieldID)

        // If the response already excists, remove it
        if(previousResponses.length > 0){
            // Remove previous response
            const newResponses = responses.filter(response => response.FieldID !== fieldID)
            // Update the state without the previous response
            setResponses(newResponses)
            // Add new response
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid, FieldType: fieldType }])
        } else {
            // If the response doesn't excist, add it
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid, FieldType: fieldType }]);
        }
    }

    // Handle deselect for the matrix one
    const handleDeselectMatrixOne = (isSelected, fieldID, input, optionType, inputType, itemid, rowTitle, fieldType, columnID) => {
        // If the input is selected, add it to the responses
        if (isSelected) {
            // First, filter out any previous selection for the same rowTitle
            const filteredResponses = responses.filter(
                (item) => !(item.RowTitle === rowTitle && item.FieldID === fieldID)
            );
    
            // Now, set the updated state with the new selection
            setResponses([
                ...filteredResponses,
                { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid, RowTitle: rowTitle, FieldType: fieldType, ColumnID: columnID }
            ]);
    
        // If the input is deselected, remove it from the responses
        } else {
            const filteredResponses = responses.filter(
                (item) => !(item.Itemid === itemid && item.Input === input)
            );
    
            setResponses(filteredResponses);
        }
    };

    // Handle deselect for the multiple choice with multiple answers
    const handleDeselectMultiMulti = (isSelected, fieldID, input, optionType, inputType, itemid, fieldType) => {

        // If the input is selected, add it to the responses
        if(isSelected){
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid, FieldType: fieldType}])

        // If the input is deselected, remove it from the responses
        } else {

            responses.forEach((item, index) => {

                if(item.Itemid === itemid){
                    const filteredRespones = responses.splice(index, 1);
                }
            })

            const filteredResponses = responses.filter(item => item.Itemid !== itemid );

            setResponses(filteredResponses)
            
        }
    }

    // Update the responses from the other input at the multiple-multiple field
    const updateMultiMultiOtherInput = (fieldID, input, itemid) => {

        // Find the previous state of the respons
        const previousResponses = responses.filter(response => response.FieldID === fieldID && response.OptionType === 'other')

        // If the response already excists, remove it   
        if(previousResponses.length > 0){
            // Remove previous response
            const newResponses = responses.filter(response => response.item.Itemid !== itemid)
            // Update the state without the previous response
            setResponses(newResponses)
            // Add new response
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: 'other', itemid: itemid }])
        } else {
            // If the response doesn't excist, add it
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: 'other' }]);
        }
    }


    // Handle the input
    const inputHandler = (e) => {
        const input = e.target.value
        const fieldID = e.target.dataset.id
        const fieldType = e.target.dataset.fieldtype

        if(field.Type === 'paragraph'){

            updateResponses(fieldID, input, fieldType)

        } else if(field.Type === 'scale'){

            // Convert the input to a number
            const numberInput = parseInt(input)

            updateResponses(fieldID, numberInput, fieldType)

        } else if(field.Type === 'multiple-multiple'){ 
            const optionType = e.target.dataset.optiontype 
            const inputType = e.target.dataset.inputtype
            const itemid = e.target.dataset.itemid

            // If the input is an other input, update the responses
            if(inputType === 'otherInput'){
                updateResponses(fieldID, input, optionType, itemid, fieldType)
            } else {

            // If the input is a checkbox input, handle select or deselect
            const isSelected = e.target.checked
            handleDeselectMultiMulti(isSelected, fieldID, input, optionType, inputType, itemid, fieldType)
            }
        } else if(field.Type === 'multiple-one'){
            const optionType = e.target.dataset.optiontype
            const itemid = e.target.dataset.itemid

            updateResponses(fieldID, input, optionType, itemid, fieldType)
        } else if(field.Type === 'matrix-one'){
            const isSelected = e.target.checked
            const itemid = e.target.dataset.itemid
            const optionType = e.target.dataset.optiontype
            const inputType = e.target.dataset.inputtype
            const rowTitle = e.target.dataset.rowtitle
            const columnID = e.target.dataset.columnid

            handleDeselectMatrixOne(isSelected, fieldID, input, optionType, inputType, itemid, rowTitle, fieldType, columnID)
        }

    }

    // Return the correct input field
    if(field.Type === 'paragraph'){
        return (
            <textarea 
            cols="30" 
            rows="10" 
            placeholder={"Geef hier uw antwoord"}
            data-id={field.ID} 
            onChange={inputHandler}>
            </textarea>
        )
        } else if(field.Type === 'scale'){
            return (
                <div id='scale-container'>
                    {field.ReachStartLable}
                    {range && range.map(btn => (
                        <div key={field.ID} id='question-type-label-container'>
                            <input 
                                type="radio"
                                name={field.ID} 
                                value={btn} 
                                data-id={field.ID}
                                data-fieldtype={field.Type}
                                onChange={inputHandler}
                            />
                            <label htmlFor={btn}>{btn}</label>
                        </div>
                    ))}
                    {field.ReachEndLabel}
                </div>
            )
        } else if(field.Type === 'multiple-multiple'){
            return(
                <div id='options-container'>
                    {multipleOptions && multipleOptions.map(item => (
                        <span key={item.ID}>
                           <input
                            className='question-checkbox-input'
                            type="checkbox"
                            id={item.ID}
                            name='options'
                            data-itemid={item.ID}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            data-inputtype={'checkboxInput'}
                            data-id={field.ID}
                            data-fieldtype={field.Type}
                            value={item.Option}
                            onChange={inputHandler}
                            />
                            <label htmlFor={item.Option}>{item.Option === 'other' ? "Anders..." : item.Option}</label>
                            <input 
                            type="text" 
                            data-id={field.ID} 
                            data-itemid={item.ID}
                            data-fieldtype={field.Type}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            data-inputtype={'otherInput'}
                            style={{display: item.Option === 'other' ? 'block' : 'none'}} 
                            placeholder={"Geef hier uw antwoord"}
                            onChange={inputHandler}/>
                        </span> 
                    ))}
                </div>
            )
        } else if (field.Type === 'multiple-one') {
            return (
                <div id="options-container">
                    {multipleOptions &&
                        multipleOptions.map((item) => {
                            // Check if this item is selected in the responses state
                            const isSelected = responses.some(
                                (response) =>
                                    response.FieldID === field.ID &&
                                    response.Itemid === item.ID &&
                                    response.Input === item.Option
                            );
        
                            return (
                                <span key={item.ID}>
                                    <input
                                        className="question-checkbox-input"
                                        type="radio"
                                        id={item.ID}
                                        name={`option-${field.ID}`} // Ensure name is unique per field to group them correctly
                                        data-itemid={item.ID}
                                        data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                                        data-id={field.ID}
                                        value={item.Option}
                                        data-type={item.Option}
                                        data-fieldtype={field.Type}
                                        checked={isSelected} // Reflect the selected state
                                        onChange={inputHandler}
                                    />
                                    <label htmlFor={item.Option}>
                                        {item.Option === 'other' ? "Anders..." : item.Option}
                                    </label>
                                    {item.Option === 'other' && (
                                        <input
                                            type="text"
                                            data-id={field.ID}
                                            data-itemid={item.ID}
                                            data-fieldtype={field.Type}
                                            data-optiontype="other"
                                            style={{ display: 'block' }}
                                            placeholder={"Geef hier uw antwoord"}
                                            onChange={inputHandler}
                                        />
                                    )}
                                </span>
                            );
                        })}
                </div>
            );
        } else if(field.Type === 'matrix-one'){
        return(
            <div id='matrix-grid-container'
            style={{ gridTemplateColumns: `150px repeat(${matrixColumns.length}, 1fr)` }}
            >
            
                {/* Matrix column titles */}
                <div className='matrix-grid'>
                    {/* Placeholder for empty top-left cell */}
                    <div className='matrix-grid-header'></div>
                    {matrixColumns && matrixColumns.map((column) => (
                    <div key={column.ID} className='matrix-grid-header'>
                        <p>{column.Title}</p>
                    </div>
                    ))}
                </div>

                {/* Matrix rows with radio buttons */}
                {matrixRows && matrixRows.map(row => (
                    <div key={row.ID} className='matrix-grid-row' style={{ display: 'contents' }}>
                    {/* Row title with flex-start alignment */}
                    <div className='matrix-grid-header first-column'> {/* Add the "first-column" class */}
                        <p>{row.Title}</p>
                    </div>
                    
                    {/* Row radio buttons */}
                    {matrixColumns && matrixColumns.map(column => (
                        <div key={column.ID} className='matrix-grid-item'>
                        <input 
                            className='question-radio-input' 
                            type="radio" 
                            name={`row-${row.ID}`}  // Each row has the same name for radio buttons
                            id={column.ID} 
                            data-id={field.ID} 
                            data-itemid={column.ID}
                            data-rowtitle={row.Title}
                            value={column.Title}
                            data-columnid={column.ID}
                            data-fieldtype={field.Type}
                            onChange={inputHandler}
                        />
                        </div>
                    ))}
                    </div>
                ))}
            </div>

        )
    } else {
        return null
    }
}

export default FieldType