// This is a hook to set metadata to all the pages.
// Link and name are used for the TopBarBreadCrump component. 
// type is used for the 'Ask ImpactAI' button in the PageHeader component.

export const usePageConfig = () => {

    const pageConfigs = {
        // General Pages
        dashboard: { link: "dashboard", name: "Dashboard", level: 1 },
        notifications: { link: "notifications", name: "Notificaties", level: 1 },
        helpdesk: { link: "helpdesk", name: "Helpdesk", level: 1 },

        // Impact Strategy Pages
        impactstrategy: { link: "impactstrategy", name: "Impactstrategie", level: 1 },
        organisationcontext: { link: "organisationcontext", name: "Organisatie omschrijving", parent: "impactstrategy", level: 2 },
        problemanalysis: { link: "problemanalysis", name: "Maatschappelijk probleem", parent: "impactstrategy", level: 2, type: 'problem' },
        stakeholders: { link: "stakeholders", name: "Doelgroepen", parent: "impactstrategy", level: 2 },
        Goals: { link: "Goals", name: "Impactdoel", parent: "impactstrategy", level: 2 },
        Activities: { link: "Activities", name: "Activiteiten", parent: "impactstrategy", level: 2 },
        Outputs: { link: "Outputs", name: "Outputs", parent: "impactstrategy", level: 2 },
        effects: { link: "effects", name: "Effecten", parent: "impactstrategy", level: 2 },
        addindicators: { link: "addindicators", name: "Indicatoren toevoegen", parent: "effects", level: 3 },
        SelectKPIs: { link: "SelectKPIs", name: "Bestaande vragen selecteren", parent: "addindicators", level: 4 },
        theoryofchange: { link: "theoryofchange", name: "Theory of Change", parent: "impactstrategy", level: 2 },

        // Measurement Plan Pages
        measurementplan: { link: "measurementplan", name: "Meten", level: 1 },
        research: { link: "research", name: "Onderzoeken", parent: "measurementplan", level: 2 },
        sharequestionnaire: { link: "sharequestionnaire", name: "Vragenlijst delen", parent: "research", level: 3 },
        ResearchResults: { link: "ResearchResults", name: "Resultaten", parent: "research", level: 3 },
        researchbuilder: { link: "researchbuilder", name: "Onderzoek instellingen", parent: "research", level: 3 },
        questionairebuilder: { link: "questionairebuilder", name: "Vragenlijst instellingen", parent: "researchbuilder", level: 4 },
        addstandardeffectstoquestionaire: { link: "addstandardeffectstoquestionaire", name: "Gestandaardiseerde effecten toevoegen", parent: "questionairebuilder", level: 5 },
        outputresults: { link: "outputresults", name: "Outputresultaten", parent: "measurementplan", level: 2 },
        storytelling: { link: "storytelling", name: "Storytelling", parent: "measurementplan", level: 2 },

        // Personas Pages
        personasexplainer: { link: "personasExplainer", name: "Deelnemers", level: 1 },
        personadashboard: { link: "personadashboard", name: "Overzicht", parent: "personasexplainer", level: 2 },
        personas: { link: "personas", name: "Alle deelnemers", parent: "personasexplainer", level: 2 },
        personabuilder: { link: "personabuilder", name: "Deelnemer instellingen", parent: "personas", level: 3 },
        personapresencereportdetail: { link: "personapresencereportdetail", name: "Aanwezigheidsrapport", parent: "personabuilder", level: 4 },
        personagoaldetail: { link: "personagoaldetail", name: "Doel instellingen", parent: "personabuilder", level: 4 },
        personareportdetail: { link: "personareportdetail", name: "Rapportage instellingen", parent: "personabuilder", level: 4 },
        researchresultspersonas: { link: "researchresultspersonas", name: "Onderzoeksresultaten", parent: "personabuilder", level: 4 },
        agenda: { link: "agenda", name: "Agenda", parent: "personasexplainer", level: 2 },
        personasettings: { link: "personasettings", name: "Instellingen", parent: "personasexplainer", level: 2 },
        personamethodedetails: { link: "personamethodedetails", name: "Methode instellingen", parent: "personasettings", level: 3 },
        personareportstandarddetails: { link: "personareportstandarddetails", name: "Rapportagestandaard instellingen", parent: "personasettings", level: 3 },
        personagoalprogress: { link: "personagoalprogress", name: "Doel voortgang", parent: "personasexplainer", parent: "personabuilder", level: 3 },
        researchresultspersonasforms: { link: "researchresultspersonasforms", name: "Onderzoeksresultaten formulier", parent: "researchresultspersonas", level: 4 },
        researchresponsespersonas: { link: "researchresponsespersonas", name: "Onderzoeksresultaten", parent: "researchresultspersonas", level: 4 },

        // Communication Pages
        communication: { link: "communication", name: "Communiceren", level: 1 },
        livereports: { link: "livereports", name: "Live rapportages", parent: "communication", level: 2 },
        livereportbuilder: { link: "livereportbuilder", name: "Live-rapport instellingen", parent: "livereports", level: 3 },
        synchronisations: { link: "synchronisations", name: "Synchronisaties", parent: "communication", level: 2 },

        // Financing Pages
        financing: { link: "financing", name: "Financiering", level: 1 },
        funds: { link: "funds", name: "Fondsen", parent: "financing", level: 2 },

        // Profile and Admin Pages
        profile: { link: "profile", name: "Mijn profiel", level: 1 },
        Data: { link: "data", name: "Data", parent: "Settings", level: 2 },
        UserRoles: { link: "UserRoles", name: "Gebruikersrollen", parent: "Settings", level: 2 },
        Members: { link: "Members", name: "Team", parent: "Settings", level: 2 },
        Settings: { link: "Settings", name: "Instellingen", level: 1 },

        // Default Page
        default: { link: "home", name: "Home", level: 1 }
    };

    // Function to retrieve page details
    const getPageConfig = (pageName) => pageConfigs[pageName] || pageConfigs.default;

    // Helper to recursively get breadcrumb data
    const getBreadcrumbTrail = (pageName) => {
        const trail = [];
        let currentPage = getPageConfig(pageName);

        while (currentPage) {
            trail.unshift(currentPage); // Add to the beginning to maintain order
            currentPage = currentPage.parent ? getPageConfig(currentPage.parent) : null;
        }
        
        return trail;
    };

    return { getPageConfig, getBreadcrumbTrail };
};
