import { useFirestoreNoCompagnyGeneralTwoOrderBy } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { client } from '../../hooks/Client';
import PageHeader from '../../components/layout/PageHeader';
import OutputMeta from "../../components/outputs/OutputMeta";
import EffectMeta from "../../components/effects/EffectMeta";
import CompagnyMeta from "../../components/portfolio/CompagnyMeta";

const SyncDetails = () => {

  // Hooks
  const syncId = Location()[3]

  // Firebase
  const syncs = useFirestoreNoCompagnyGeneralTwoOrderBy('Synchronisations', 'Parent', client, 'ID', syncId, 'Timestamp', 'desc');

  // Sync status
  const status = (statusCode) => {
    switch (statusCode) {
      case "requested":
        return { text: "Aangevraagd", color: "#FFA500" };
      case "accepted":
        return { text: "Geaccepteerd", color: "#008000" };
        case "paired":
        return { text: "Gekoppeld", color: "#008000" };
      case "declined":
        return { text: "Geweigerd", color: "#FF0000" };
      case "deleted":
        return { text: "Verwijderd", color: "#000000" };
      default:
        return { text: "Onbekende status", color: "#000000" };
    }
  };

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={`Synchronisatie details`}
                description={`Bekijk en beheer hier de details van deze synchronisatie.`}
                AI={false}
            />
             <div className="table-container dashboard-container">
              {syncs && syncs.map((sync) => (
                <div key={sync.ID}>
                  <div>
                    <h2>Project</h2>
                    <p>{<CompagnyMeta id={sync.Project}/>}</p>
                  </div>
                  <div>
                    <h2>Type</h2>
                    <p>{sync.Type}</p>
                  </div>
                  <div>
                    <h2>{sync.Type === 'Output' ? 'Output' : 'Effect'}</h2>
                    {sync.Type === 'Output' ?
                    <p>{<OutputMeta id={sync.Output}/>}</p>
                    :
                    <p>{<EffectMeta item={sync.Effect}/>}</p>
                    }
                  </div>
                  <div>
                    <h2>{sync.Type === 'Output' ? 'Gekoppelde output' : 'Gekoppeld effect'}</h2>
                    {sync.Type === 'Output' ?
                    <p>{<OutputMeta id={sync.ProjectOutput}/>}</p>
                    :
                    <p>{<EffectMeta item={sync.ProjectEffect}/>}</p>
                    }
                  </div>
                 <div>
                  <h2>Status</h2>
                  <p style={{ color: status(sync.Status).color }}>
                      {status(sync.Status).text}
                  </p>
                 </div>
                </div>
              ))}
            </div>
          </div>
    </div>
  )
}

export default SyncDetails