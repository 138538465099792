import { useState } from 'react';
import firebase from "firebase";
import saveFile from '../../hooks/SaveFile';
import { useEffect } from 'react';
import { db, timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import { client } from '../../hooks/Client';
import Location from '../../hooks/Location';
import { useFirestore, useFirestoreGeneral } from '../../firebase/useFirestore';

const AddOnstructuredInterview = () => {
    // State
    const [audioFile, setAudioFile] = useState(null);
    const [audioPreview, setAudioPreview] = useState(null);
    const [audioFilePath, setAudioFilePath] = useState('');
    const [text, setText] = useState('');
    const [structuredText, setStructuredText] = useState([]);
    const [momentId, setMomentId] = useState('');
    const [loading, setLoading] = useState('none');

    // Hooks
    const questionaireId = Location()[3];
    const researchId = Location()[4];

     // Firebase
     const moments = useFirestoreGeneral('MeasureMoments', 'ReseachID', researchId ? researchId : '');

    // Function to handle the file change
    const handleFileChange = (event) => {
        const file = event;
        const preview = event.target.files[0];
        if (file) {
            setAudioFile(file);
            setAudioPreview(URL.createObjectURL(preview));
        }
    };

    // Speech to text function
    const speech2Text = async () => {
        try {
            setLoading('block')
    
            const speech2Text = firebase.functions().httpsCallable('speech2Text')  

            console.log(audioFilePath)
    
            const result = await speech2Text({data: audioFilePath})
    
            const answer = result.data
    
            setLoading('none')
    
            console.log(answer.text)
    
            setText(answer.text)
    
        } catch (error) {
            console.log(error.message)
            setText('Er is iets fout gegaan. Probeer het opnieuw.')
        }
    };

    // Function to handle the upload
    const handleUpload = async () => {
        // Check if there is a file
        if (!audioFile) {
            alert('No file selected');
            return;
        }

        // Upload the file
        saveFile(audioFile, setAudioFilePath, null);

        // Call the speech2Text function
        if (audioFilePath) {
            speech2Text();
        }
    };

    const messages = [
        { role: "system", content: "Je bent een expert in het analyseren van ongestructureerde interviews en het scheiden van vragen en antwoorden." },
        { 
            role: "user", 
            content: `
            Analyseer deze tekst: "${text}".
            Identificeer de vragen die door de interviewer zijn gesteld en de antwoorden die door de geïnterviewde zijn gegeven.
            Structureer de output als een JSON-array waarin elk item een object bevat met een vraag en een antwoord. Zorg ervoor dat het correct geformatteerd is voor directe JSON-parsing.
    
            Het verwachte formaat:
            [
                {"question": "Vraag 1", "answer": "Antwoord 1"},
                {"question": "Vraag 2", "answer": "Antwoord 2"},
                ...
            ]
    
            Geef GEEN extra tekst, alleen het JSON-object.
            `
        }
    ];


    // Seperate questions from answers with AI
    const seperateQuestions = async (text) => {
        try {
            const completionOnCallOld = firebase.functions().httpsCallable('completionOnCallOld');
    
            const result = await completionOnCallOld({ messages: messages });
            
            // Parse the JSON content safely
            const answer = result.data;
    
            // Make sure it handles only valid JSON content
            const structuredData = JSON.parse(answer.content); 
    
            setStructuredText(structuredData);
            setLoading('none');
    
        } catch (error) {
            console.error("Error parsing JSON:", error);
            setStructuredText('Er is iets fout gegaan tijdens het analyseren van de antwoorden.');
            setLoading('none');
        }
    };
    

    useEffect(() => {
        if (text) {
            seperateQuestions(text)
        }
    }, [text])

    // Save the questions and answers to the database
    const saveQuestions = async () => {

        structuredText.forEach(async (set, index) => {

            const questionId = uuid();
            const formId = uuid();

            await db.collection('QuestionnaireFields')
            .doc()
            .set({
                Compagny: client,
                CompagnyID: client,
                ID: questionId,
                Timestamp: timestamp,
                QuestionnaireID: questionaireId,
                Type: 'paragraph',
                Question: set.question,
                Explainer: '',
                ReachStart: 0,
                ReachStartLable: '',
                ReachEnd: 5,
                ReachEndLabel: '',
                Key: uuid(),
                Position: index + 1,
                Multiple: [],
                SectionID: '',
                LiveReportHidden: false,
            })

            await db.collection('QuestionnairesResponses')
            .doc()
            .set({
                FieldID: questionId,
                Input: set.answer,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionannaireID: questionaireId,
                MomentID: momentId,
                ResearchID: researchId,
                FormID: formId,
                ID: uuid(),
                OptionType: '',
                Persona: '',
                RowTitle: '',
                FieldType: 'paragraph',
                ColumnID: '',
            })

        })

    };

    console.log(structuredText.length > 0)

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <h1>Ongestructureerd interview toevoegen</h1>
            </div>
            <div className="table-container dashboard-container">
                <h2>Upload audio bestand</h2>
                <input type="file" id="audio" name="audio" accept="audio/*" onChange={handleFileChange}/>
                {audioPreview && <audio controls src={audioPreview}></audio>}
                <div>
                    <button className='button-simple' onClick={handleUpload}>Upload</button>
                </div>
            </div>
            <div id='add-unsctructured-interviews-transcription-container'>
                <div className="table-container dashboard-container">
                <h2>Transcriptie</h2>
                    {loading === 'block' && <p>Loading...</p>}
                    {Array.isArray(structuredText) && structuredText.length > 0 && structuredText.map(set => (
                        <div key={set?.question}>
                            <p><b>{set?.question}</b></p>
                            <p>{set?.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
            {Array.isArray(structuredText) && structuredText.length > 0 && 
                <div>
                    <h2>Selecteer meetmoment</h2>
                    {moments && moments.map(moment => (
                        <div key={moment.ID}>
                            <input type="radio" id={moment.ID} name="moment" value={moment.ID} onClick={() => setMomentId(moment.ID)} />
                            <label htmlFor={moment.ID}>{moment.Name}</label>
                        </div>
                    ))}
                    <button onClick={saveQuestions}>Opslaan in vragenlijst</button>
                </div>
            }
        </div>
    </div>
  )
}

export default AddOnstructuredInterview