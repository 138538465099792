import { db } from '../../../firebase/config';

const tocData = async (
    sourceData,
    centralProblem,
    stakeholders,
    goals,
    activities,
    effects,
) => {
    const array = [{
        'Organisatie samenvatting': sourceData[0] && sourceData[0].Summary,
        'Maatschapelijke probleem': centralProblem && centralProblem[0]?.CentralProblem,
        'Stakeholders': [],
        'ImpactDoel': goals && goals[0]?.Title,
        'Activiteiten': [],
        'Effecten': [],
    }];

    // Process stakeholders
    if (stakeholders && stakeholders.length > 0) {
        for (const stakeholder of stakeholders) {
            if (!stakeholder) {
                console.error(`Stakeholder not found.`);
                continue;
            }
            array[0].Stakeholders.push(stakeholder.Organisation);
        }
    }

    // Process activities
    if (activities && activities.length > 0) {
        for (const activity of activities) {
            if (!activity) {
                console.error(`Activity not found.`);
                continue;
            }

            const object = {
                ActivityTitle: activity.Activity,
                Outputs: [],
            };

            const outputsSnapshot = await db
                .collection('Outputs')
                .where('ActivityID', '==', activity.ID)
                .get();

            for (const outputsDoc of outputsSnapshot.docs) {
                const outputsData = outputsDoc.data();
                object.Outputs.push(outputsData.Title);
            }

            array[0].Activiteiten.push(object);
        }
    }

    // Process effects
    if (effects && effects.length > 0) {
        for (const effect of effects) {
            if (!effect) {
                console.error(`Effect not found.`);
                continue;
            }

            const object = {
                EffectTitle: effect.Effect,
                Indicators: [],
            };

            const indicatorsSnapshot = await db
                .collection('QuestionnaireFields')
                .where('EffectId', 'array-contains', effect.ID)
                .get();

            for (const indicatorsDoc of indicatorsSnapshot.docs) {
                const indicatorsData = indicatorsDoc.data();
                object.Indicators.push(indicatorsData.Question);
            }

            array[0].Effecten.push(object);
        }
    }

    return array;
};

export default tocData;