import { client } from '../../hooks/Client';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import ParagraphDevelopmentBarChart from '../Visualisations/development/ParagraphDevelopmentBarChart';
import ParagraphDevelopmentLineChart from '../Visualisations/development/ParagraphDevelopmentLineChart';
import ParagraphDevelopmentPieChart from '../Visualisations/development/ParagraphDevelopmentPieChart';
import ParagraphMomentBarChart from '../Visualisations/moment/ParagraphMomentBarChart';
import ParagraphMomentLineChart from '../Visualisations/moment/ParagraphMomentLineChart';
import ParagraphMomentPieChart from '../Visualisations/moment/ParagraphMomentPieChart';

const ParagraphData = ({ field, researchId, startDate, endDate, graphType }) => {
    // State 
    const [data, setData] = useState([]);
    const [totalResponses, setTotalResponses] = useState(0);

     // Default dates
     const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
     const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

     // Query responses
     const queryResponses = async () => {
        // Fetch categories
        const categoriesSnapshot = await db.collection('AnalysisCategories')
            .where('CompagnyID', '==', client ? client : "")
            .where('FieldID', '==', field ? field : "")
            .get();
    
        const categories = categoriesSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                ID: data.ID,
                Categorie: data.Categorie,
                Color: data.Color // Assuming `Color` is a property in your database
            };
        });

        // Fetch total responses
        const responsesSnapshot = await db.collection('QuestionnairesResponses')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .where('FieldID', '==', field ? field : "")
            .where('Timestamp', '>=', startDate || defaultStartDate)
            .where('Timestamp', '<=', endDate || defaultEndDate)
            .get();

        setTotalResponses(responsesSnapshot.docs.length);
    
        // Fetch words
        const wordsSnapshot = await db.collection('AnalysisWords')
            .where('CompagnyID', '==', client ? client : "")
            .where('FieldID', '==', field ? field : "")
            .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
            .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
            .get();
    
        const words = wordsSnapshot.docs.map(doc => doc.data());
    
        // Fetch measure moments
        const momentsSnapshot = await db.collection('MeasureMoments')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .orderBy('Position', 'asc')
            .get();
    
        const moments = momentsSnapshot.docs.map((doc) => ({
            id: doc.data().ID,
            name: doc.data().Title,
        }));
    
        // Group words by MomentID
        const momentsMap = words.reduce((acc, word) => {
            if (!acc[word.MomentID]) {
                acc[word.MomentID] = [];
            }
            acc[word.MomentID].push(word);
            return acc;
        }, {});
    
        // Transform grouped data
        const groupedData = moments
            .map(moment => {
                const wordsForMoment = momentsMap[moment.id] || [];

                const categoriesForMoment = categories.map(category => {
                    const wordCount = wordsForMoment.filter(word => word.CategoryID === category.ID).length;
                    return {
                        Title: category.Categorie,
                        Words: wordCount,
                        Color: category.Color // Include the color from the category
                    };
                });

                // Calculate total words for the moment
                const totalWordsForMoment = categoriesForMoment.reduce((sum, category) => sum + category.Words, 0);

                return {
                    MomentName: moment.name, // Use the title of the measure moment
                    Categories: categoriesForMoment,
                    TotalWords: totalWordsForMoment // Add total words for filtering
                };
            })
            .filter(moment => moment.TotalWords > 0); // Exclude moments with no results

        setData(groupedData);
    
    };
    
    
    useEffect(() => {
        queryResponses();
    }, [field, researchId, startDate, endDate]);

  // Render the correct graph based on the data
  const graphComponents = {
    single: {
      line: <ParagraphMomentLineChart data={data} totalResponses={totalResponses}/>,
      bar: <ParagraphMomentBarChart data={data} totalResponses={totalResponses}/>,
      pie: <ParagraphMomentPieChart data={data} totalResponses={totalResponses}/>
    },
    multiple: {
      line: <ParagraphDevelopmentLineChart rawData={data} totalResponses={totalResponses}/>,
      bar: <ParagraphDevelopmentBarChart rawData={data} totalResponses={totalResponses}/>,
      pie: <ParagraphDevelopmentPieChart rawData={data} totalResponses={totalResponses}/>
    }
  };
  
  const isSingleData = data.length === 1;
  const typeOfGraph = isSingleData ? 'single' : 'multiple';

return (
    <>
         {graphComponents[typeOfGraph][graphType ? graphType : 'bar'] || null}
    </>
);
}

export default ParagraphData