import { useFirestoreGeneral, useFirestore } from "../firebase/useFirestore";
import { client } from './Client';
import { useEffect, useState } from 'react';
import { db } from "../firebase/config";

const TOCProgress = () => {
    // State
    const [sourceDataState, setSourceDataState] = useState(false)
    const [centralProblemState, setCentralProblemState] = useState(false)
    const [stakeholdersState, setStakeholdersState] = useState(false)
    const [goalsState, setGoalsState] = useState(false)
    const [activitiesState, setActivitiesState] = useState(false)
    const [outputsState, setOutputsState] = useState(false)
    const [effectsState, setEffectsState] = useState(false)
    const [indicatorsState, setIndicatorsState] = useState(false)


    // Firestore
    const sourceData = useFirestoreGeneral('SourceData', 'CompagnyID', client)
    const centralProblem = useFirestore('CentralProblem')
    const stakeholders = useFirestore('Stakeholders')
    const goals = useFirestore('Goals')
    const activities = useFirestore('Activities')
    const outputs = useFirestore('Outputs')
    const effects = useFirestore('OutputEffects')
    const indicators = useFirestore('QuestionnaireFields')

    // Check if which steps are completed and which not
    const progress = async () => {

        sourceData[0] && sourceData[0].Summary === ''  ? setSourceDataState(false) : setSourceDataState(true);
        centralProblem && centralProblem[0]?.CentralProblem === '' ? setCentralProblemState(false) : setCentralProblemState(true);
        stakeholders && stakeholders.length === 0 ? setStakeholdersState(false) : setStakeholdersState(true);
        goals && goals[0]?.Title === '' ? setGoalsState(false) : setGoalsState(true);
        activities && activities.length === 0 ? setActivitiesState(false) : setActivitiesState(true);
        outputs && outputs.length === 0 ? setOutputsState(false) : setOutputsState(true);
        effects && effects.length === 0 ? setEffectsState(false) : setEffectsState(true);
        indicators && indicators.length === 0 ? setIndicatorsState(false) : setIndicatorsState(true);
    }

    useEffect(() => {
        progress()
    }, [sourceData, centralProblem, stakeholders, goals, activities, outputs, effects, indicators])  

    // Calculate progress
    const progressArray = [sourceDataState, centralProblemState, stakeholdersState, goalsState, activitiesState, outputsState, effectsState, indicatorsState]

    // Filter to keep only true values
    const trueValues = progressArray.filter(values => values === true);

    const percentage = (trueValues.length / progressArray.length) * 100

    return percentage
    
}

export default TOCProgress