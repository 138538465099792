import { useFirestoreGeneralArrayContains } from '../../../firebase/useFirestore'
import IndicatorData from '../../Indicators/IndicatorData'

const PersonaEffectIndicators = ({effectId, personaId}) => {

    // Firestore
    const questionnaireField = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '')

  return (
    <div>
        {questionnaireField && questionnaireField.map(field => (
            <div key={field.ID}>
                <IndicatorData indicator={field} docid={field.docid} personaId={personaId} fieldType={field.Type} />
            </div>
        ))}
    </div>
  )
}

export default PersonaEffectIndicators