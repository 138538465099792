import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreGeneral, useFirestoreNoCompagnyThree } from '../../firebase/useFirestore'
import firebase from 'firebase/app'
import OutputMultiLineGraph from '../Visualisations/outputs/OutputMultiLineGraph'

const OutputData = ({activity, startDate, endDate, graphType}) => {
      // State
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [growth, setGrowth] = useState({ perOutput: {}, total: 0 });

  // Hooks
  const options = {year: 'numeric', month: 'numeric', day: 'numeric' };

  // Firestore
  const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activity ? activity : '')
  const activityData = useFirestoreGeneral('Activities', 'ID', activity? activity : '')

  // Default start and end date if dates are undefined
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

  // Helper function to get the results for the syncronisations if the client is a portfolio manager
  const getSyncResults = async (output) => {
  
    // 1. Get the synchronisations docs
    const syncsQuerySnapshot = await db
      .collection("Synchronisations")
      .where("Output", "==", output)
      .where("Type", "==", "Output")
      .where("Status", "==", "paired")
      .get();
  
    // If there are no syncs, return [0] early
    if (syncsQuerySnapshot.empty) {
      // Do nothing
    }
  
    const array = [];
  
    // 2. Iterate over the docs in the "Synchronisations" query
    for (const doc of syncsQuerySnapshot.docs) {
      const item = doc.data();
      const projectId = item.Project;
      const projectOutput = item.ProjectOutput;
  
      // 3. Query "Results" collection for each item
      const querySnapshot = await db
        .collection("Results")
        .where("CompagnyID", "==", projectId)
        .where("OutputID", "==", projectOutput)
        .where("Timestamp", ">=", startDate || defaultStartDate)
        .where("Timestamp", "<=", endDate || defaultEndDate)
        .get();
  
      // 4. Push data into the array
      if (querySnapshot.empty) {
        // Do nothing   
      } else {
        // Map over each doc to get its data
        const data = querySnapshot.docs.map((doc) => doc.data());
        array.push(data);
      }
    }

    return array.flat();
  };
  

  // Get the output results for each date
  const outputResults = async (output) => {
    const array = [];
  
    const querySnapshot = await db
      .collection('Results')
      .where('CompagnyID', '==', client)
      .where('OutputID', '==', output)
      .where('Timestamp', '>=', startDate || defaultStartDate)
      .where('Timestamp', '<=', endDate || defaultEndDate)
      .get();
  
    if (querySnapshot.empty) {
      // Do nothing
    } else {
      // Otherwise, push the docs' data
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });
    }
  
    return array;
  };
  

  // Query output results
  const queryResults = async () => {
    try {
      setIsLoading(true); // Start loading

      const array = [];
  
        // Query the local output
        const outputsSnapshot = await db.collection('Outputs')
          .where('CompagnyID', '==', client)
          .where('ActivityID', '==', activity)
          .get();
  
        // Get the results for each output
        await Promise.all(outputsSnapshot.docs.map(async (output) => {
          // Get the local results
          const results = await outputResults(output.data().ID);

          // Get the sync results
          const syncResults = await getSyncResults(output.data().ID);

          // Merge the results
          const mergedResults = results.concat(syncResults);

          // Push the merged results into the array
          array.push(...mergedResults);
        }));


      // 4) Now we have a flat array of all raw docs. Sort by Timestamp.
      array.sort((a, b) => a.Timestamp - b.Timestamp);

      // 5) Transform this flat array to the shape your second component expects
      //    => one object per date, `name: dateString`, plus each outputTitle as a key
      const shapedData = await transformData(array);

      // 6) Use shapedData with your existing logic
      shapedData.length > 0 && addResults(shapedData); // This does cumulative if line
      shapedData.length > 0 && calculateGrowth(shapedData);

      setData(shapedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
      // Handle error appropriately
    } finally {
      setIsLoading(false); // Stop loading whether there was an error or not
    }
  };

  // Helper to get the output title from the output ID
  const getOutputTitle = async (outputID) => {
    const outputSnapshot = await db.collection('Outputs')
    .where('ID', '==', outputID)
    .get();

    if (outputSnapshot.empty) {
      return 'Unknown Output';
    }

    return outputSnapshot.docs[0].data().Title;
  };

  // Helper to get the project name from the project ID
  const getProjectName = async (projectID) => {
    const projectSnapshot = await db.collection('CompagnyMeta')
    .where('ID', '==', projectID)
    .get();

    if (projectSnapshot.empty) {
      return 'Unknown Project';
    }

    return projectSnapshot.docs[0].data().Name;
  };

    // Helper to shape raw docs into the date-based objects
  const transformData = async (rawArray) => {
    const groupedByDate = {};

    // A) Collect all output titles
    const allOutputTitles = new Set(); 

    for (const docData of rawArray) {
      // Convert Firestore timestamp to a date
      const dateObj = docData.Timestamp.toDate();
      const dateString = dateObj.toLocaleDateString("nl-NL");

      // Map OutputID -> Title
      const title = await getOutputTitle(docData.OutputID);
      const projectName = await getProjectName(docData.CompagnyID);

      // Make sure we know this output title
      allOutputTitles.add(title);

      // The numeric value
      const numericValue = typeof docData.Result === 'number' ? docData.Result : 0;

      // Group by date
      if (!groupedByDate[dateString]) {
        groupedByDate[dateString] = { name: dateString };
      }

      // Sum within the same date/title
      if (!groupedByDate[dateString][title]) {
        groupedByDate[dateString][title] = numericValue;
      } else {
        groupedByDate[dateString][title] += numericValue;
      }
    }

    // B) Fill in missing outputs with 0 for each date
    for (const dateString in groupedByDate) {
      for (const title of allOutputTitles) {
        if (!Object.prototype.hasOwnProperty.call(groupedByDate[dateString], title)) {
          groupedByDate[dateString][title] = 0;
        }
      }
    }

    // Turn the grouped object into an array
    const shapedArray = Object.values(groupedByDate);

    // Sort by the actual date value
    shapedArray.sort((a, b) => {
      const [dayA, monthA, yearA] = a.name.split("-");
      const [dayB, monthB, yearB] = b.name.split("-");
      return (
        new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB)
      );
    });

    console.log(shapedArray);

    return shapedArray;
  };


  // This code is used to process data for different types of graphs: a line graph requires cumulative data, whereas a bar graph does not need this processing.
  const addResults = (array) => {

    if(graphType === 'line' && array.length > 0){
      // Add the results to the previous result
      for (let i = 1; i < array.length; i++) {
        for (const key in array[i]) {
          if (typeof array[i][key] === 'number') {
            array[i][key] += (array[i - 1][key] || 0);
          }
        }
      }
    } else if(graphType === 'bar' && array.length > 0){
      return
    } else{
      return
    }

  }

  // Helper function to calculate growth
  const calculateGrowth = (data) => {
    if (data.length === 0) return;

    const perOutputGrowth = {};
    const averageGrowthArray = [];

    const firstEntry = data[0]; // The first entry in the dataset
    const lastEntry = data[data.length - 1]; // The last entry in the dataset

    for (const [key, value] of Object.entries(lastEntry)) {
        if (key !== 'name' && typeof value === 'number') {
            const initialValue = firstEntry[key] || 0; // Get the initial value from the first entry

            // Handle infinity growth
            if (initialValue !== 0) {
                const growthPercentage = ((value - initialValue) / initialValue) * 100; // Calculate growth percentage
                perOutputGrowth[key] = growthPercentage;

                averageGrowthArray.push(growthPercentage);

            } else {
                const growthPercentage = ((value + 1 - 1) / 1) * 100; // Calculate growth percentage

                perOutputGrowth[key] = growthPercentage;
                averageGrowthArray.push(growthPercentage);
            }
        }
    }

    const averageGrowth = averageGrowthArray.reduce((a, b) => a + b, 0) / averageGrowthArray.length; // Calculate average growth

    setGrowth({ 
        perOutput: perOutputGrowth, 
        average: averageGrowth 
    });
};

  // Build the data array when the outputs, activity, activityData, graphType, startDate, or endDate change
  useEffect(() => {
          if (data) {
              queryResults();
          }
}, [outputs, activity, activityData, graphType, startDate, endDate]);

  return (
    <>
        <OutputMultiLineGraph data={data} isLoading={isLoading} outputs={outputs} graphType={graphType} growth={growth} height={400} />
    </>
  )
}

export default OutputData