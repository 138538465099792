import firebase from "firebase";

const sentimentAnalyse = async (prompt, setSuggestion, setLoading) => {

    try {
        setLoading('block')

        const completionOnCallOld = firebase.functions().httpsCallable('completionOnCallOld')  

        const result = await completionOnCallOld({messages: prompt})

        const answer = result.data

        setLoading('none')

        console.log(answer)

        setSuggestion(answer)

    } catch (error) {
        console.log(error.message)
        setSuggestion([{item: 'Er is iets fout gegaan. Probeer het opnieuw.'}])
    }

}

export default sentimentAnalyse