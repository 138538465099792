import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import { useState } from "react";
import useSettings from "../../hooks/Settings";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import * as XLSX from 'xlsx';

const ImportResults = () => {
    // State
    const [importType, setImportType] = useState('google');
    const [file, setFile] = useState(null);
    const [measureMoment, setMeasureMoment] = useState(null);
    const [type, setType] = useState({});

    // Hooks
    const researchId = Location()[3];
    const secundairyColor = useSettings().SecundairyColor;

    // Firestore
    const research = useFirestoreID('Research', researchId ? researchId : '');
    const measureMoments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchId ? researchId : '', 'Position', 'asc');

    const formatTimestamp = (excelSerialDate) => {

        const jsTimestamp = (excelSerialDate - 25569) * 86400000;
        const date = new Date(jsTimestamp);
    
        const pad = (n) => (n < 10 ? `0${n}` : n);
    
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1);
        const year = date.getFullYear();
    
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
    
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    // Google Forms upload handler
    const googleFormsUploadHandler = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });

                // Assuming you want to read the first sheet
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Convert the sheet to JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setFile(jsonData);
            };
            reader.readAsBinaryString(uploadedFile);
        }
    };

    // Select type
    const SelectType = ({questionKey}) => {

        const typeHandler = (e) => {

            const type = e.target.options[e.target.selectedIndex].value;
            
            setType((prevTypes) => ({
                ...prevTypes,
                [questionKey]: type,
            }));
        }

        return (
            <select 
                name="type" 
                id={`type-${questionKey}`} 
                onChange={typeHandler} 
                value={type[questionKey] || ''} 
            >
                <option value="">-- Selecteer type --</option>
                <option value="open">Open vraag</option>
                <option value="mulitple">Meerkeuze vraag</option>
                <option value="scale">Schaal vraag</option>
                <option value="matrix">Matrix vraag</option>
            </select>
        );
    };

    // Upload handler
    const uploadHandler = () => {
        if (!file || !measureMoment) {
            console.error("File or measure moment is not selected");
            return;
        }
    
        // Create a new array where each result is paired with its question type
        const pairedResults = file.map((row) => {
            const pairedRow = {};
    
            // Iterate through each column in the row
            Object.keys(row).forEach((key) => {
                pairedRow[key] = {
                    value: row[key],
                    type: type[key] || "undefined", // Pair with the selected type or default to 'undefined'
                };
            });
    
            return pairedRow;
        });

        console.log("Paired Results:", pairedResults);
    };



    // db.collection('QuestionnaireFields')
    //     .doc()
    //     .set({
    //         Compagny: client,
    //         CompagnyID: client,
    //         ID: uuid(),
    //         Timestamp: timestamp,
    //         QuestionnaireID: route,
    //         Type: 'paragraph',
    //         Question: 'Vraag ' + position,
    //         Explainer: '',
    //         ReachStart: 0,
    //         ReachStartLable: '',
    //         ReachEnd: 5,
    //         ReachEndLabel: '',
    //         Key: uuid(),
    //         Position: position,
    //         Multiple: [],
    //         SectionID: '',
    //         LiveReportHidden: false,
    //     })

    // db.collection('QuestionnairesResponses')
    //     .doc()
    //     .set({
    //     FieldID: response.FieldID,
    //     Input: response.Input,
    //     Timestamp: timestamp,
    //     CompagnyID: client,
    //     QuestionannaireID: questionaire.ID,
    //     MomentID: moment,
    //     ResearchID: research,
    //     FormID: formID,
    //     ID: uuid(),
    //     OptionType: response.OptionType ? response.OptionType : '',
    //     Persona: persona ? persona : '',
    //     RowTitle: response.RowTitle ? response.RowTitle : '',
    //     FieldType: response.FieldType ? response.FieldType : '',
    //     ColumnID: response.ColumnID ? response.ColumnID : '',
    // })

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Resultaten uploaden</h1>
                </div>
                <div className="select-activity-container select-activity-container-wizard">
                    <div className="select-activity-inner-container">
                        <div
                            className='activity-select-item-container'
                            onClick={() => setImportType('google')}
                            style={{ backgroundColor: importType === 'google' ? secundairyColor : 'white' }}
                        >
                            <p style={{ color: importType === 'google' ? 'white' : '#616161' }}>Google Forms</p>
                        </div>
                        <div
                            className='activity-select-item-container'
                            onClick={() => setImportType('microsoft')}
                            style={{ backgroundColor: importType === 'microsoft' ? secundairyColor : 'white' }}
                        >
                            <p style={{ color: importType === 'microsoft' ? 'white' : '#616161' }}>Microsoft Forms</p>
                        </div>
                    </div>
                </div>
                {importType === 'google' &&
                    <>
                    <div className='dashboard-container'>
                        <h2>Upload .xlsx document</h2>
                        <input type="file" onChange={googleFormsUploadHandler} />
                    </div>

<                     div id='import-results-google-forms-data-table-container' className="dashboard-container table-container">
                        <h2>Controleer resultaten en vul vraagtypen aan</h2>
                        {file && file.length > 0 && (() => {
                            const headerRow = file.reduce((maxRow, currentRow) =>
                                Object.keys(currentRow).length > Object.keys(maxRow).length ? currentRow : maxRow
                            , file[0]);

                            return (
                                <table>
                                    <thead>
                                        <tr>
                                            {Object.keys(headerRow).map((key, index) => (
                                                <th key={index}>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Add your new row here */}
                                        <tr>
                                            {Object.keys(headerRow).map((key, cellIndex) => (
                                                <td key={cellIndex}> {/* Replace this content with your desired data */}
                                                    {key === 'Tijdstempel' ? <p>Selecteer een vraagtype</p> : <SelectType questionKey={key}/>}
                                                </td>
                                            ))}
                                        </tr>

                                        {/* Existing rows */}
                                        {file.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {Object.keys(headerRow).map((key, cellIndex) => {
                                                    const value = row[key] || '';

                                                    // Check if the value is a timestamp and format it
                                                    const isTimestamp = typeof value === 'number' && String(value).length >= 10; // Simple timestamp check
                                                    const formattedValue = isTimestamp ? formatTimestamp(value) : value;

                                                    return <td key={cellIndex}>{formattedValue}</td>;
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            );
                        })()}
                        </div>

                        <div className='dashboard-container'>
                            <h2>Selecteer meetmoment</h2>
                            <select name="" id="" onChange={(e) => setMeasureMoment(e.target.options[e.target.selectedIndex].value)}>
                                <option value="">-- Selecteer meetmoment --</option>
                                {measureMoments && measureMoments.map((item, index) => (
                                    <option key={index} value={item.ID}>{item.Title}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <button onClick={uploadHandler}>Upload</button>
                        </div>
                    </>
                }

                {importType === 'microsoft' &&
                    <div className='table-container dashboard-container'>
                        <h2>Microsoft Forms</h2>
                        <div id="research-results-import-button-container">
                            <ConstructionOutlinedIcon />
                            <p>Wordt aan gewerkt</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ImportResults;
