import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import TotalResponses from '../TotalResponses';

const MultipleDevelopmentBarChart = ({ data, totalResponses, projectId }) => {
    const preprocessData = (dataset) => {
        // Transform the dataset into a flat structure
        return dataset.map((entry) => {
            const transformed = { name: entry.moment }; // Use 'moment' as the key for XAxis
            entry.responses.forEach((response) => {
                transformed[response.name] = response.count; // Set response name as key and count as value
            });
            return transformed;
        });
    };

    const extractOptions = (dataset) => {
        // Extract unique response types and their colors
        const uniqueOptions = {};
        dataset.forEach((entry) => {
            entry.responses.forEach((response) => {
                uniqueOptions[response.name] = response.color;
            });
        });
        return Object.entries(uniqueOptions).map(([name, color]) => ({ name, color }));
    };

    return (
        <>
            <TotalResponses totalResponses={totalResponses} />
            <ResponsiveContainer width="90%" height={400}>
                <BarChart
                    data={data ? preprocessData(data) : []}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {data && extractOptions(data).map((option, index) => (
                        <Bar
                            key={`bar-${index}`}
                            dataKey={option.name}
                            fill={option.color}
                            barSize={30}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default MultipleDevelopmentBarChart;
