import React from 'react'
import { useFirestoreID } from '../../../firebase/useFirestore'
import LiveReportDashboardMedia from '../LiveReportDashboardMedia'
import LiveReportResearchData from '../LiveReportResearchData'

const PrintLiveReportResearch = ({researchId, startDate, endDate}) => {

    // Firestore
    const research = useFirestoreID('Research', researchId ? researchId : '')

  return (
    <div className="report-activities-container">
        {research && research.map(item => (
            <div className="print-report-activity-item-container" style={{borderBottom: research.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="print-report-activity-item-description-container">
                    <h3>{item.Title}</h3>
                    <div id='print-description-content-container'>
                        <p>{item.Description}</p>
                        <LiveReportDashboardMedia parent={item}/>
                    </div>
                </div>
                <div className="print-report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <div className='print-graph-wrapper'>
                        <LiveReportResearchData questionnaireId={item.QuestionnaireID} researchId={item.ID} startDate={startDate} endDate={endDate}/>
                    </div>
                </div>
            </div>
        ))}
    </div>
  )
}

export default PrintLiveReportResearch