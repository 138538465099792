import React from 'react'
import { useState, useEffect } from 'react'
import { useFirestoreID } from '../../firebase/useFirestore'
import LiveReportDashboardMedia from './LiveReportDashboardMedia'
import LiveReportResearchData from './LiveReportResearchData'

const LiveReportResearch = ({researchId, startDate, endDate}) => {

    console.log(startDate, endDate)

    // Firestore
    const research = useFirestoreID('Research', researchId ? researchId : '')

  return (
    <div className="report-activities-container">
        {research && research.map(item => (
            <div className="report-activity-item-container" style={{borderBottom: research.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Title}</h3>
                    <p>{item.Description}</p>
                    <LiveReportDashboardMedia parent={item}/>
                </div>
                <div className="report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <LiveReportResearchData questionnaireId={item.QuestionnaireID} researchId={item.ID} startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        ))}
    </div>
  )
}

export default LiveReportResearch