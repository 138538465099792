import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import TotalResponses from '../TotalResponses';

const MatrixDevelopmentLineChart = ({ data, lowerScale, upperScale, totalResponses }) => {
  const [hoveredLine, setHoveredLine] = useState(null);

  if (!data || data.length === 0) {
    return <p>Geen data aanwezig</p>;
  }

  const uniqueRowTitles = Object.keys(data[0]).filter((key) => key !== "name");

  const calculateTotalGrowth = () => {
    const totalValues = data.map((item) =>
      uniqueRowTitles.reduce((sum, key) => {
        const value = item[key]?.value;
        const adjustedValue =
          item[key]?.negative && value !== undefined
            ? upperScale - value + lowerScale
            : value ?? 0;
        return sum + adjustedValue;
      }, 0)
    );

    if (totalValues.length > 1) {
      const initialTotal = totalValues[0];
      const finalTotal = totalValues[totalValues.length - 1];
      const maxTotal = uniqueRowTitles.length * (upperScale - lowerScale);

      return ((finalTotal - initialTotal) / maxTotal) * 100;
    }
    return 0;
  };

  const totalGrowth = calculateTotalGrowth().toFixed(1);

  const CustomizedLegend = ({ payload }) => (
    <ul>
      {payload.map((entry, index) => {
        const rowTitle = entry.dataKey;
        return (
          <li
            key={`item-${index}`}
            onMouseEnter={() => setHoveredLine(rowTitle)}
            onMouseLeave={() => setHoveredLine(null)}
            style={{
              cursor: "pointer",
              fontWeight: hoveredLine === rowTitle ? "bold" : "normal",
              color: entry.color,
            }}
          >
            {rowTitle}
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <h3>Totale groei: {totalGrowth > 0 ? "+" : ""}{totalGrowth}%</h3>
      <TotalResponses totalResponses={totalResponses} />
      <svg style={{ position: "absolute", width: 0, height: 0 }}>
        <defs>
          {uniqueRowTitles.map((rowTitle, index) => (
            <filter
              id={`glow-${index}`}
              key={`glow-${index}`}
              x="-50%"
              y="-50%"
              width="200%"
              height="200%"
            >
              <feGaussianBlur stdDeviation="15" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          ))}
        </defs>
      </svg>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data.map((item) =>
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => {
                if (key === "name") {
                  return [key, value];
                } else {
                  const adjustedValue =
                    value?.negative && value?.value !== undefined
                      ? upperScale - value.value + lowerScale
                      : value?.value ?? null;

                  return [key, adjustedValue];
                }
              })
            )
          )}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, upperScale]} />
          <Tooltip />
          <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
          {uniqueRowTitles.map((rowTitle, index) => (
            <Line
              key={`line-${index}`}
              type="monotone"
              dataKey={rowTitle}
              stroke={data[0][rowTitle]?.color || "#206c7f"}
              strokeWidth={2}
              activeDot={{ r: 8 }}
              strokeOpacity={hoveredLine && hoveredLine !== rowTitle ? 0.1 : 1}
              filter={
                hoveredLine === rowTitle
                  ? `url(#glow-${uniqueRowTitles.indexOf(rowTitle)})`
                  : undefined
              }
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default MatrixDevelopmentLineChart;


