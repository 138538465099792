import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import TotalResponses from '../TotalResponses';

const MultipleDevelopmentPieChart = ({ data, totalResponses }) => {
    const preprocessData = (dataset) => {
        // Transform the data into a format suitable for PieChart
        return dataset.map((entry) => {
            return {
                moment: entry.moment,
                data: entry.responses.map((response) => ({
                    name: response.name,
                    value: response.count,
                    color: response.color,
                })),
            };
        });
    };

    const pieData = data ? preprocessData(data) : [];

    // Custom Legend Renderer
    const renderCustomLegend = (props) => {
        const { payload } = props; // Access the legend items
        return (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{
                                width: 10,
                                height: 10,
                                backgroundColor: entry.color,
                                marginRight: 8,
                            }}
                        ></div>
                        <span>{`${entry.payload.name} (${entry.value})`}</span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>
            <TotalResponses totalResponses={totalResponses} />
            <ResponsiveContainer width="100%" height={450}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    {pieData.map((entry, index) => (
                        <div key={`pie-container-${index}`} style={{ textAlign: 'center', width: '45%' }}>
                            {/* Moment name */}
                            <h3 style={{ marginBottom: '10px' }}>{entry.moment}</h3>
                            {/* Pie chart */}
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                        data={entry.data}
                                        dataKey="value"
                                        nameKey="name"
                                        outerRadius={100}
                                        fill="#8884d8"
                                    >
                                        {entry.data.map((item, idx) => (
                                            <Cell key={`cell-${idx}`} fill={item.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend content={renderCustomLegend} payload={entry.data.map((d) => ({
                                        value: d.value,
                                        name: d.name,
                                        color: d.color,
                                        payload: d,
                                    }))} />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    ))}
                </div>
            </ResponsiveContainer>
        </>
    );
};

export default MultipleDevelopmentPieChart;
