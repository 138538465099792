import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import MatrixDevelopmentBarChart from '../Visualisations/development/MatrixDevelopmentBarChart';
import MatrixDevelopmentLineChart from '../Visualisations/development/MatrixDevelopmentLineChart';
import MatrixDevelopmentPieChart from '../Visualisations/development/MatrixDevelopmentPieChart';
import MatrixMomentBarChart from '../Visualisations/moment/MatrixMomentBarChart';
import MatrixMomentLineChart from '../Visualisations/moment/MatrixMomentLineChart';
import MatrixMomentPieChart from '../Visualisations/moment/MatrixMomentPieChart';

const MatrixData = ({field, researchId, startDate, endDate, graphType, personaId, matrixQuestionRows, projectId}) => {

  // State
    const [data, setData] = useState([]);
    const [lowerScale, setLowerScale] = useState(0);
    const [upperScale, setUpperScale] = useState(5);
    const [totalResponses, setTotalResponses] = useState(0);

  // Default start and end date if dates are undefined
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());


  // Function to fetch scale range from Firestore
  const getScale = async () => {
     const query = db.collection('QuestionnaireFields')
      .where('ID', '==', field);

    const fieldData = await query.get();

    fieldData.docs.forEach(doc => {
      setLowerScale(doc.data().ReachStart);
      setUpperScale(doc.data().ReachEnd);
    });
  };

  // Function to fetch data from Firestore
  const getData = async () => {
    const dataArray = [];
    const rowTitlesSet = new Set(); // To store unique row titles
     
  // Start the Firestore query by selecting the 'QuestionnairesResponses' collection and adding the 'FieldID' condition.
  let querySnapshot = db.collection('QuestionnairesResponses')
  .where('FieldID', '==', field)
  .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
  .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)

  // Conditionally add the 'PersonaID' filter if personaId is defined.
  if (personaId) {
    querySnapshot = querySnapshot.where('Persona', '==', personaId);
  }

  // Fetch row titles from Firebase and wait for the promise to resolve
  const rowTitlesSnapshot = await db.collection('MatrixQuestionRows')
  .where('Field', '==', field)
  .get();

  const rowTitles = rowTitlesSnapshot.docs.map((doc) => doc.data());

  // Execute the query to get the responses.
  const responses = await querySnapshot.get();

    // Collect all row titles first to ensure they are consistent across all moments
    responses && responses.docs.forEach( async response => {
      rowTitlesSet.add(response.data().RowTitle);
    });

    // Get the total number of responses
    setTotalResponses(responses.docs.length);

    const measureMoments = await db.collection('MeasureMoments')
      .where('ResearchID', '==', researchId)
      .orderBy('Position', 'asc')
      .get();

    measureMoments.docs.forEach((moment) => {
      const momentID = moment.data().ID;
      const momentTitle = moment.data().Title;

      const momentResponses = responses.docs.filter(response => response.data().MomentID === momentID);

      // Skip processing this measure moment if there are no responses
      if (momentResponses.length === 0) {
        return; // Continue to the next moment
      }

      const columnData = {};

      momentResponses.forEach(response => {
        const responseData = response.data(); // Access the actual document data
        const rowTitle = responseData.RowTitle; // Group by row title instead of columnID
        const input = parseInt(responseData.Input, 10); // Parse the input as an integer

        // Await rowTitleData to access its contents
        const rowTitleData = rowTitles.filter(
          row => row.Title === rowTitle
        );

        // Query the MSI API to find out whether the rowTitle is positive of negative
        const isNegative = rowTitleData && rowTitleData.length > 0 && rowTitleData[0].posNeg === 'negative';
      
        // Initialize if this is the first time encountering this rowTitle
        if (!columnData[rowTitle]) {
          columnData[rowTitle] = {
            sum: 0,
            count: 0,
            rowTitle: rowTitle,
            color: rowTitleData ? rowTitleData[0].Color : '#206c7f', // Add the color from rowTitleData
            negative: isNegative
          };
        }
      
        // Add the input to the sum and increment the count
        columnData[rowTitle].sum += input;
        columnData[rowTitle].count += 1;
      });

      const momentData = {
        name: momentTitle
      };

      // Ensure all row titles from rowTitlesSet are present in momentData
     // Loop through each row title in the set of unique row titles
    rowTitlesSet.forEach(rowTitle => {

      // Find the corresponding column entry for this row title in columnData
      const columnEntry = Object.values(columnData).find(entry => entry.rowTitle === rowTitle);

      // If an entry for this row title exists, calculate the average and add the color
      if (columnEntry) {
        const average = columnEntry.sum / columnEntry.count;
        momentData[rowTitle] = {
          value: average,
          color: columnEntry.color,
          negative: columnEntry.negative
        };
      } else {
        momentData[rowTitle] = {
          value: null,
          color: null, // Default to null if no data
          negative: null
        };
      }
    });

    // After processing all row titles, push the momentData object into the dataArray
    dataArray.push(momentData);
    });

    setData(dataArray);
  };

  // useEffect to trigger getData when field or researchID changes
  useEffect(() => {
    getData();
    getScale();
  }, [field, researchId, startDate, endDate, graphType, personaId, matrixQuestionRows]);

 // Render the correct graph based on the data
 const graphComponents = {
  single: {
    line: (
      <MatrixMomentLineChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
        projectId={projectId}
      />
    ),
    bar: (
      <MatrixMomentBarChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
      />
    ),
    pie: <MatrixMomentPieChart data={data} />,
  },
  multiple: {
    line: (
      <MatrixDevelopmentLineChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
      />
    ),
    bar: (
      <MatrixDevelopmentBarChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
        projectId={projectId}
      />
    ),
    pie: <MatrixDevelopmentPieChart 
      data={data}
      lowerScale={lowerScale}
      upperScale={upperScale} 
      totalResponses={totalResponses}
      />,
  },
};
  
  const isSingleData = data.length === 1;
  const typeOfGraph = isSingleData ? 'single' : 'multiple';

return (
    <>
        {graphComponents[typeOfGraph][graphType ? graphType : 'bar'] || null}
    </>
);
}

export default MatrixData