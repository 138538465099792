import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import { useFirestoreOrderBy} from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import Tooltip from "../../components/common/Tooltip";
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect, useState } from "react";
import { PersonasSystemMessage } from "../../hooks/impactAI/Prompts"
import deleteIcon from '../../images/icons/delete-icon.png'
import DummyPhoto from '../../images/dummy-profile-photo.jpeg'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { db, timestamp } from "../../firebase/config.js"
import uuid from "react-uuid";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import DeleteModal from "../../components/common/DeleteModal";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import RouteMeta from "../../components/personas/RouteMeta";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";

const Personas = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // State
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [selectedType, setSelectedType] = useState('active')

    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const id = uuid()

    // Firestore
    const personas = useFirestoreOrderBy('Personas', 'Position', 'asc')

    console.log(personas)

    //ImpactAI
    const pageSystemMessage = PersonasSystemMessage()
    const pageStartMessage = `Welkom bij de deelnemers, ${auth?.ForName}. Hier kun je deelnemers beheren.
    Kan ik je ergens mee helpen?`
    const pageExampleMessages = ['Wat zijn personas?']
    const pageType = 'personas'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages(pageExampleMessages)
    }, [auth])

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setDeleteModal(!deleteModal)
    }

    // Options
    const options = [
        { key: 'active', label: 'Actief' },
        { key: 'inactive', label: 'Inactief' },
        { key: 'exited', label: 'Uitgestroomd' },
        { key: 'pre-registration', label: 'Vooraanmelding' },
        { key: 'all', label: 'Alle' }
    ];

    const selectedPersonas = selectedType === 'all' ? personas : personas && personas.filter(persona => persona.Status === selectedType)

    // Add Persona
    const addPersona = () => {

        db.collection('Personas')
        .doc()
        .set({
            Name: '',
            ID: id,
            Photo: DummyPhoto,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Activities: [],
            Effects: [],
            Research: [],
            Position: personas.length + 1
        })
    }

    // Edit Persona
    const nameHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('Personas')
        .doc(docid)
        .update({
            Name: value
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Status Handler
    const statusHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Personas')
        .doc(docid)
        .update({
            Status: value
        })
        .then(() => {
            setSaved('flex')
         }
        )
    }

    // Delete Persona
    const deletePersona = () => {

        db.collection('Personas')
        .doc(deleteDocid)
        .delete()
    }

    // Months Left
    const monthsLeft = (endDate) => {

        const date = new Date(endDate)
        const currentDate = new Date()
        const months = Math.floor((date - currentDate) / 1000 / 60 / 60 / 24 / 30)

        if (months < 0) {
            return '0'
        }
        if (isNaN(months)) {
            return 'Geen einddatum ingevuld'
        }
        return months
    }

  return (
    <div className="main">
        <div className="main-container" >
        <div className='page-header'>
            <GroupsOutlinedIcon/>
            <h1>Deelnemers</h1>
        </div>
        <div className="select-activity-container select-activity-container-wizard">
            <div className="select-activity-inner-container">
                {options.map(option => (
                    <div 
                        key={option.key} 
                        className='activity-select-item-container' 
                        onClick={() => setSelectedType(option.key)} 
                        style={{ backgroundColor: selectedType === option.key ? secundairyColor : 'white' }}
                    >
                        <p 
                            onClick={() => setSelectedType(option.key)} 
                            style={{ color: selectedType === option.key ? 'white' : '#616161' }}
                        >
                            {option.label}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        <div className='table-container dashboard-container'>
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                    <th style={{backgroundColor: secundairyColor}}>
                        <div className="th-title-container">
                            <p>STATUS</p>
                            <UnfoldMoreOutlinedIcon/>
                        </div>
                    </th>
                    <th style={{backgroundColor: secundairyColor}}>TRAJECT</th>
                    <th style={{backgroundColor: secundairyColor}}>
                        <div className="th-title-container">
                            <p>MAANDEN TOT EIND</p>
                            <UnfoldMoreOutlinedIcon/>
                        </div>
                    </th>
                    <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                {selectedPersonas && selectedPersonas.map((item, index) => (
                        <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={item}
                        input={
                            <Tooltip content={item.Name} width='100%' top='-60px'>
                                <input type="text" defaultValue={item.Name} data-docid={item.docid} placeholder='Noteer hier de naam van de persona' onChange={nameHandler}/>
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'Personas'}
                        itemArray={personas}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={item.Name}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                            <td>
                                <select name="" id="" data-docid={item.docid} value={item.Status} onChange={statusHandler}>
                                    <option value="">-- Selecteer een status --</option>
                                    <option value="pre-registration">Vooraanmelding</option>
                                    <option value="active">Actief</option>
                                    <option value="inactive">Inactief</option>
                                    <option value="outflow">Uitgestroomd</option>
                                </select>
                            </td>
                            <td><RouteMeta routeId={item.RouteID}/></td>
                            <td>
                                {monthsLeft(item.EndDate)}
                            </td>
                            <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personabuilder/${item.ID}`)}/>
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} data-deletename={item.Name} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
            <AddItemTableRow toolTipContent={'Persona toevoegen'} onClick={addPersona}/>
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deletePersona} deleteName={deleteName} />
        </div>
        <Breadcrumb
        section={'Meten'}
        previousPage={'KPI"s'}
        previousPageUrl={'kpis'}
        nextPage={'Communiceren'}
        nextPageUrl={'communication'}
        counter={5}
        totalSteps={5}
        />
    </div>
</div>
  )
}

export default Personas