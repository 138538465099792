import { useFirestoreGeneral } from '../../firebase/useFirestore';
import PersonaGoalData from '../personas/PersonaGoalData';

const LiveReportPersonaGoals = ({personaId, startDate, endDate}) => {

    // Firestore
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', personaId ? personaId : '');

  return (
    <div>
        {goals && goals.map(goal => (
            <div key={goal.ID}>
                <PersonaGoalData goal={goal} personaId={personaId} startDate={startDate} endDate={endDate} />
            </div>
        ))}
    </div>
  )
}

export default LiveReportPersonaGoals