import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import { client } from '../../hooks/Client';
import ScaleMomentVisualisation from '../Visualisations/moment/ScaleMomentVisualisation';
import ScaleDevelopmentBarChart from '../Visualisations/development/ScaleDevelopmentBarChart';
import ScaleDevelopmentLineChart from '../Visualisations/development/ScaleDevelopmentLineChart';
import ScaleDevelopmentPieChart from '../Visualisations/development/ScaleDevelopmentPieChart';
import useSettings from '../../hooks/Settings';

const ScaleData = ({ field, researchId, startDate, endDate, graphType }) => {

    // State
    const [data, setData] = useState([]);
    const [totalResponses, setTotalResponses] = useState(0);
    const [scaleEnd, setScaleEnd] = useState(0);

    // Hooks
    const primaryColor = useSettings().PrimaryColor;
    const secundaryColor = useSettings().SecundairyColor;
    const tertiaryColor = useSettings().TertairyColor;

    const colors = [primaryColor, secundaryColor, tertiaryColor];

     // Default dates
     const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
     const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

      // Function to query and group responses by MeasureMoment with option type and color
      const groupResponses = async () => {
        // Fetch all responses matching the filters
        const responsesSnapshot = await db.collection('QuestionnairesResponses')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .where('FieldID', '==', field)
            .where('Timestamp', '>=', startDate || defaultStartDate)
            .where('Timestamp', '<=', endDate || defaultEndDate)
            .get();
    
        const responses = responsesSnapshot.docs.map((doc) => doc.data());
    
        // Calculate total number of responses
        const totalResponses = responses.length;
        setTotalResponses(responses.length);
    
        // Fetch all measure moments for the research
        const momentsSnapshot = await db.collection('MeasureMoments')
            .where('CompagnyID', '==', client)
            .where('ResearchID', '==', researchId)
            .orderBy('Moment', 'asc')
            .get();
    
        const moments = momentsSnapshot.docs.map((doc) => ({
            id: doc.data().ID,
            name: doc.data().Title,
        }));

        // Find the scale end for the field
        const fieldSnapshot = await db.collection('QuestionnaireFields')
        .where('CompagnyID', '==', client)
        .where('ID', '==', field)
        .get();

        const fieldData = fieldSnapshot.docs.map((doc) => doc.data());
        const scaleEnd = fieldData[0].ReachEnd;
        setScaleEnd(scaleEnd);
    
        // Map over each measure moment and calculate the average score
        const groupedData = moments
            .map((moment) => {
                // Filter responses for the current moment
                const momentResponses = responses.filter((response) => response.MomentID === moment.id);
    
                // Skip this moment if no responses are found
                if (momentResponses.length === 0) return null;
    
                // Calculate the total and count for averaging
                const total = momentResponses.reduce((sum, response) => sum + Number(response.Input || 0), 0);
                const count = momentResponses.length;
                const average = total / count;
    
                // Return an object with the measure moment name and the calculated average
                return {
                    moment: moment.name, // MeasureMoment title
                    average: average.toFixed(2), // Average rounded to 2 decimals
                };
            })
            .filter((item) => item !== null); // Remove null moments where there are no responses
    
        // Add the totalResponses at the end of the data
        return {
            data: groupedData,
            totalResponses,
        };
    };
    

    useEffect(() => {
        const fetchData = async () => {
            const { data: groupedData, totalResponses } = await groupResponses();
            setData({ groupedData, totalResponses });
        };
    
        fetchData();
    }, [field, researchId, startDate, endDate]);


   // Render the correct graph based on the data
   const graphComponents = {
    single: {
      line: <ScaleMomentVisualisation data={data} field={field} />,
      bar: <ScaleMomentVisualisation data={data} field={field}/>,
      pie: <ScaleMomentVisualisation data={data} field={field}/>
    },
    multiple: {
      line: <ScaleDevelopmentLineChart groupedData={data.groupedData} color={secundaryColor} totalResponses={totalResponses} scaleEnd={scaleEnd}/>,
      bar: <ScaleDevelopmentBarChart groupedData={data.groupedData} colors={colors} totalResponses={totalResponses} scaleEnd={scaleEnd}/>,
      pie: <ScaleDevelopmentPieChart groupedData={data.groupedData} colors={colors} totalResponses={totalResponses}/>
    }
  };
  
  const isSingleData = data?.groupedData?.length === 1;
  const typeOfGraph = isSingleData ? 'single' : 'multiple';

return (
    <>
         {graphComponents[typeOfGraph][graphType ? graphType : 'bar'] || null}
    </>
);
}

export default ScaleData