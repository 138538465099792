import Location from "../../hooks/Location"
import PageHeader from "../../components/layout/PageHeader.jsx";
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import { useFirestoreGeneral } from "../../firebase/useFirestore";

const EditInsight = () => {

   // Hooks
   const insightId = Location()[3]
   const history = useHistory()

   // Firestore
   const insights = useFirestoreGeneral('Insights', 'ID', insightId ? insightId : '')

   // Get the title of the insight
    const title = insights ? insights[0].Title : ''
    const type = insights ? insights[0].Type : ''

    const insightType = () => {

      switch(type) {
          case 'cross-research-question-comparison':
              return 'Vergelijking onderzoeken'
          case 'sentiment-analyse-researches':
              return 'AI analyse onderzoeken'
          case 'complete-progress-analysis':
              return 'AI voortgangsanalyse'
          case 'cross-research-question-comparison':
              return 'Vergelijking onderzoeken één vraag'
          case 'sentiment-analyse-open-question':
              return 'AI analyse open vraag'
          default:
              return 'Onbekend'
      }
  }

  const InsightContentType = () => {

    switch(type) {
        case 'cross-research-question-comparison':
            return <div>
                <p><b>Researches</b></p>
                <p>Researches</p>
                <p><b>Questions</b></p>
                <p>Questions</p>
            </div>
        case 'sentiment-analyse-researches':
            return <div>
                <p><b>Researches</b></p>
                <p>Researches</p>
                <p><b>Questions</b></p>
                <p>Questions</p>
            </div>
        case 'complete-progress-analysis':
            return <div>
                <p><b>Researches</b></p>
                <p>Researches</p>
                <p><b>Questions</b></p>
                <p>Questions</p>
            </div>
        case 'cross-research-question-comparison':
            return <div>
                <p><b>Researches</b></p>
                <p>Researches</p>
                <p><b>Questions</b></p>
                <p>Questions</p>
            </div>
        case 'sentiment-analyse-open-question':
            return <div>
                <p><b>Researches</b></p>
                <p>Researches</p>
                <p><b>Questions</b></p>
                <p>Questions</p>
            </div>
        default:
            return 'Onbekend'
    }
}


  return (
    <div className="main">
      <div className="main-container">
          <PageHeader 
              heading={'Inzicht aanpassen'}
              description={`Pas de instellingen van het inzicht aan van ${title}.`}
              AI={false}
          />
          <div className="table-container dashboard-container">
              <h1>{title}</h1>
              <p><b>Type</b></p>
              <p>{insightType()}</p>
              <div>
                <InsightContentType/>
              </div>
          </div>
      </div>
    </div>
  )
}

export default EditInsight