import { BarChart, Bar, Rectangle, LineChart, Line, XAxis, YAxis, Area, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import spinner from '../../../images/spinner-ripple.svg';
import React from 'react';

const CustomLegend = ({ payload, growth }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexWrap: 'wrap' }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ marginRight: 10 }}>
          <span
            style={{
              display: 'inline-block',
              marginRight: 5,
              width: 10,
              height: 10,
              backgroundColor: entry.color,
            }}
          ></span>
          {entry?.value || 0}: 
          {growth[entry?.value] !== undefined
            ? `${growth[entry.value] >= 0 ? '+' : ''}${growth[entry.value].toFixed(1)}%`
            : '0%'}
        </li>
      ))}
    </ul>
  );
};


const OutputMultiLineGraph = ({ data, isLoading, outputs, graphType, growth, height }) => {

  return (
    <>
      <h3>Gemiddelde groei: {growth?.average && growth?.average > 0 ? "+" : ""}{`${growth?.average?.toFixed(1)}%`}</h3>
      {isLoading ? (
        <div id="graph-loading-container">
          <img src={spinner} alt="spinner" className="spinner" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          {graphType === 'bar' ? (
            <BarChart
              width="100%"
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend content={<CustomLegend growth={growth?.perOutput} />} />
              {outputs &&
                outputs.map((output, index) => (
                  <Bar
                    key={`bar-${index}`}
                    dataKey={output.Title}
                    fill={output.Color}
                    activeBar={<Rectangle fill={output.Color} stroke={output.Color} />}
                  />
                ))}
            </BarChart>
          ) : (
            <LineChart
              width="100%"
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend content={<CustomLegend growth={growth.perOutput} />} />
              {outputs &&
                outputs.map((output, index) => (
                  <React.Fragment key={`line-${index}`}>
                    <Line
                      type="monotone"
                      dataKey={output.Title}
                      stroke={output.Color}
                      strokeWidth="3"
                    />
                    <Area
                      type="monotone"
                      dataKey={output.Title}
                      fill={'#8884d8'}
                      fillOpacity={1}
                    />
                  </React.Fragment>
                ))}
            </LineChart>
          )}
        </ResponsiveContainer>
      )}
    </>
  );
};

export default OutputMultiLineGraph;

