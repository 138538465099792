import { useFirestoreID } from '../../firebase/useFirestore'

const StakeholderMeta = ({stakeholder}) => {

    const stakholders = useFirestoreID('Stakeholders', stakeholder ? stakeholder : '')

  return (
    <>
        {stakholders && stakholders.map(item => (
          <p className="effects-meta-item-value" key={item.ID}>{item.Organisation}</p>
        ))}
    </>
  )
}

export default StakeholderMeta