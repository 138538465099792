import Fields from "./Fields"
import useTranslations from "../../hooks/useTranslations";
import { useMemo } from "react";

const TopLevelFields = ({field, index, setFieldIndex, fieldIndex, compagnyId, questionaire}) => {


  return (
    <div style={{display: index !== fieldIndex ? 'none' : 'flex' }} className="top-level-field-container">
        <Fields 
        field={field} 
        compagnyId={compagnyId} 
        questionaire={questionaire}
        />
        <div className="questionnaire-button-container">
            <button 
            style={{display: fieldIndex === 0 ? 'none' : 'block'}} 
            onClick={() => setFieldIndex(fieldIndex - 1)}>
              {"Terug"}
            </button>
            <button onClick={() => setFieldIndex(index + 1)}>
            {"Volgende"}
            </button>
        </div>
        
    </div>
  )
}

export default TopLevelFields