import Calendar from "../../components/common/Calender";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useState, useEffect } from "react";
import { useFirestore } from "../../firebase/useFirestore";
import { db } from "../../firebase/config";
import { format, subWeeks, nextDay, addWeeks } from 'date-fns';

const Agenda = () => {
  const [events, setEvents] = useState([]);

  const shedules = useFirestore('PersonaShedule');

  console.log(shedules);

  const eventTitle = async (personaDocid) => {
    let personaName = '';
    await db.collection('Personas')
      .doc(personaDocid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          personaName = doc.data().Name;
        } else {
          console.log('No such document!');
        }
      });
    return personaName;
  };

  const getNext7Weekdays = (startDate, weekday) => {
    let currentDay = nextDay(startDate, weekday);
    let previousDay = subWeeks(currentDay, 1);
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.unshift(format(previousDay, 'yyyy-MM-dd'));
      previousDay = subWeeks(previousDay, 1);
    }
    currentDay = nextDay(startDate, weekday);
    for (let i = 0; i < 7; i++) {
      weekDays.push(format(currentDay, 'yyyy-MM-dd'));
      currentDay = addWeeks(currentDay, 1);
    }
    return weekDays;
  };

  const createEvents = async (weekday, item, start, end) => {
    const next7Days = getNext7Weekdays(new Date(), weekday);
    const eventsToAdd = await Promise.all(next7Days.map(async day => {
      const title = await eventTitle(item.PersonaDocid);
      return {
        Title: `${title}`,
        Deadline: day,
        Time: `${start} - ${end}`
      };
    }));
    setEvents(prevEvents => [...prevEvents, ...eventsToAdd]);
  };

  const createSheduleEvents = () => {
    if (shedules) {
      for (let item of shedules) {
        const sheduleDay = item.Day || "";
        // Monday
        if (sheduleDay === 'monday') {
          createEvents(1, item, item.StartTime, item.EndTime);
        }
        // Tuesday
        if (sheduleDay === 'tuesday') {
          createEvents(2, item, item.StartTime, item.EndTime);
        }
        // Wednesday
        if (sheduleDay === 'wednesday') {
          createEvents(3, item, item.StartTime, item.EndTime);
        }
        // Thursday
        if (sheduleDay === 'thursday') {
          createEvents(4, item, item.StartTime, item.EndTime);
        }
        // Friday
        if (sheduleDay === 'friday') {
          createEvents(5, item, item.StartTime, item.EndTime);
        }
        // Saturday
        if (sheduleDay === 'saturday') {
          createEvents(6, item, item.StartTime, item.EndTime);
        }
        // Sunday
        if (sheduleDay === 'sunday') {
          createEvents(0, item, item.StartTime, item.EndTime);
      }
    }
  };
};

  useEffect(() => {
    if (shedules) {
      createSheduleEvents();
    }
  }, [shedules]);

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <DateRangeOutlinedIcon />
          <h1>Planning</h1>
        </div>
        <div id='agenda-calender-container'>
          <Calendar events={events} />
        </div>
      </div>
    </div>
  );
};

export default Agenda;