import LiveReportPersonaPresence from "./LiveReportPersonaPresence"
import LiveReportPersonaGoals from "./LiveReportPersonaGoals"
import { useFirestoreGeneral } from "../../firebase/useFirestore"

const ReportBuilderPersonas = ({personaId,startDate, endDate}) => {

    const personas = useFirestoreGeneral('Personas', 'ID', personaId)

  return (
    <div>
        <h2>Aanwezigheid</h2>
        <LiveReportPersonaPresence persona={personas && personas[0]} startDate={startDate} endDate={endDate} />
        <h2>Doelen</h2>
        <LiveReportPersonaGoals personaId={personaId} startDate={startDate} endDate={endDate}/>
    </div>
  )
}

export default ReportBuilderPersonas