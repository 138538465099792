import Modal from 'react-modal';
import { useFirestore} from '../../firebase/useFirestore';

const AddOutputsModal = ({ setAddOutputsModal, addOutputsModal, addOutput, selectedOutputs, setSelectedOutputs }) => {
    // Firestore
    const outputs = useFirestore('Outputs');
  
    // Modal settings
    Modal.setAppElement('#root');
  
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  
    // Handle selection and deselection of outputs
    const handleSelectChange = (e) => {
      const id = e.target.dataset.id;
      const standard = e.target.dataset.standard === 'true'; // Convert to boolean
      const output = outputs.find((effect) => effect.ID === id);
  
      if (!output) return;
  
      const updatedOutput = { ...output, standard };
  
      if (e.target.checked) {
        // Add output to the selected list if not already present
        setSelectedOutputs((prev) => {
          if (prev.some((item) => item.ID === id)) {
            return prev; // Avoid duplicates
          }
          return [...prev, updatedOutput];
        });
      } else {
        // Remove outputs from the selected list
        setSelectedOutputs((prev) => prev.filter((item) => item.ID !== id));
      }
    };
  
    return (
      <Modal
        isOpen={addOutputsModal}
        onRequestClose={() => setAddOutputsModal(false)}
        style={modalStyles}
        contentLabel="Add outputs"
      >
        <div>
          <div id="delete-modal-title-container">
            <h1>Outputs synchroniseren</h1>
          </div>
          <div>
            <h2>Selecteer de outputs die u wilt synchroniseren</h2>
            {outputs &&
              outputs.map((output) => (
                <div key={output.ID} id="portfolio-syncs-add-effect-modal-effect-input-container">
                  <input
                    type="checkbox"
                    data-id={output.ID}
                    onChange={handleSelectChange}
                    checked={selectedOutputs.some((selected) => selected.ID === output.ID)}
                  />
                  <label>{output.Title}</label>
                </div>
              ))}
          </div>
          <div id="delete-modal-button-container">
            <button onClick={() => setAddOutputsModal(false)}>Annuleren</button>
            <button id="delete-modal-delete-button" onClick={addOutput}>
              Opslaan
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  export default AddOutputsModal;
  