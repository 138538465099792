import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import ForkRightOutlinedIcon from '@mui/icons-material/ForkRightOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ErasorIcon from '../../images/icons/erasor-icon.png';
import XIcon from '../../images/icons/x-icon.png';
import StatusData from '../../components/personas/StatusData';
import RouteData from '../../components/personas/RouteData';
import { useEffect, useState, useContext } from 'react';
import { useFirestoreGeneral, useFirestore } from '../../firebase/useFirestore';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import useSettings from "../../hooks/Settings";
import PersonaNameMeta from '../../components/personas/PersonaNameMeta';
import { Auth } from '../../StateManagment/Auth'
import { timestamp, db } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CheckIcon from '../../images/icons/check-icon.png';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Tooltip from '../../components/common/Tooltip';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


const PersonaDashboard = () => {
  // Context
  const [auth] = useContext(Auth);

  // State
  const [outflowRange, setOutflowRange] = useState(3);
  const [outflowPersonas, setOutflowPersonas] = useState([]);
  const [displayCorrection, setDisplayCorrection] = useState(false);
  const [correctStartTime, setCorrectStartTime] = useState('');
  const [correctEndTime, setCorrectEndTime] = useState('');
  const [makeCorrection, setMakeCorrection] = useState(false);
  const [absent, setAbsent] = useState(false);
  const [absentReason, setAbsentReason] = useState('');
  const [correctionReason, setCorrectionReason] = useState('');
  const [hoverIndex, setHoverIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [presenceMonth, setPresenceMonth] = useState(new Date().getMonth() + 1);

  // Hooks
  const history = useHistory();
  const secundairyColor = useSettings().SecundairyColor;

  // Firestore
  const personas = useFirestoreGeneral('Personas', 'Status', 'active');
  const presenceReports = useFirestore('PersonaPresenceReports');
  const shedule = useFirestore('PersonaShedule');

  // Helper function to convert date format
  const convertDateFormat = (dateString) => {
    const parts = dateString.split('-');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };


  // Helper function to convert timestamp to dutch date
  const convertTimestampToDutchDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Get today's date in word format and long format
  const today = new Date();
  const dayName = new Intl.DateTimeFormat('en-EN', { weekday: 'long' }).format(today);
  const dutchDayName = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(today);
  const capitalizedDutchDayName = dutchDayName.charAt(0).toUpperCase() + dutchDayName.slice(1);
  const longName = new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(today);
  const hours = today.getHours();
  const minutes = today.getMinutes();

  // Get the current month
  const currentMonth = new Date().getMonth() + 1;

  // Helper function to convert a Dutch date to an English date
  const convertDutchDateToEnglish = (dutchDate) => {
    let convertedDate = dutchDate;

    if(typeof dutchDate === 'object') {
      convertedDate = convertTimestampToDutchDate(dutchDate);
    }

    // Map Dutch day names to English day names
    const dayNames = {
      'maandag': 'Monday',
      'dinsdag': 'Tuesday',
      'woensdag': 'Wednesday',
      'donderdag': 'Thursday',
      'vrijdag': 'Friday',
      'zaterdag': 'Saturday',
      'zondag': 'Sunday'
    };
  
    // Map Dutch months to English months
    const monthNames = {
      'januari': 'January',
      'februari': 'February',
      'maart': 'March',
      'april': 'April',
      'mei': 'May',
      'juni': 'June',
      'juli': 'July',
      'augustus': 'August',
      'september': 'September',
      'oktober': 'October',
      'november': 'November',
      'december': 'December'
    };
  
    // Replace day and month names
    const dayPattern = new RegExp(Object.keys(dayNames).join('|'), 'gi');
    const monthPattern = new RegExp(Object.keys(monthNames).join('|'), 'gi');
  
    let englishDate = dutchDate.replace(dayPattern, matched => dayNames[matched.toLowerCase()]);
    englishDate = englishDate.replace(monthPattern, matched => monthNames[matched.toLowerCase()]);
  
    return englishDate;
  };

   // Helper function to format the date to a Dutch string
   const formatDateToDutchString = (date) => {
    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} ${monthName} ${year}`;
  };

  // Filter presence for today
  const presenceToday = () => {
    const array = [];

    shedule && shedule.forEach(item => {

      if (item.Day.toLowerCase() === dayName.toLowerCase()) {
        const object = {
          name: item.PersonaID,
          start: item.StartTime,
          end: item.EndTime,
        };
        array.push(object);
      }
    });

    return array;
  };

  // Outflow range handler
  const outflowRangeHandler = (e) => {
    const value = e.target.options[e.target.selectedIndex].value;
    setOutflowRange(parseInt(value));
  };

  // Filter personas
  useEffect(() => {
    const array = [];

    personas && personas.forEach(persona => {
      const endDate = new Date(persona.EndDate);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - endDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= outflowRange * 30) {
        array.push(persona);
      }
    });

    setOutflowPersonas(array);
  }, [personas, outflowRange]);

  // Helper function to check for main activity
  const checkForMainActivity = async (personaId) => {

    const mainActivity = await db.collection('PersonaActivities')
      .where('PersonaID', '==', personaId)
      .where('Type', '==', 'main')
      .get();

    if (mainActivity.empty) {
      return 
    } else {
        mainActivity.forEach( async doc => {

          const eventId = uuid();

          await db.collection('Events').add({
            Title: '',
            Date: timestamp,
            Outputs: [doc.data().OutputId],
            Personas: [personaId],
            ActivityID: doc.data().ActivityID,
            ID: eventId,
            Compagny: client,
            CompagnyID: client,
        });

        await db.collection('Results')
          .add({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Result: Number(1),
            Timestamp: timestamp,
            OutputID: doc.data().OutputId,
            ActivityID: doc.data().ActivityID,
            EventID: eventId,
            Personas: [personaId],
          })
      });

    }
  };

  // Save correction
  const saveCorrection = (e) => {
    const personaId = e.target.dataset.personaid;
    const start = e.target.dataset.start;
    const end = e.target.dataset.end;

    const position = presenceReports?.filter(report => report.PersonaID === personaId).length + 1;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Start: start,
        End: end,
        CorrectedStart: correctStartTime,
        CorrectedEnd: correctEndTime,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        Reason: correctionReason,
        ID: uuid(),
        Type: 'correction',
        Author: auth.ID,
        Position: position
      })
      .then(() => {
        checkForMainActivity(personaId);
        setDisplayCorrection(false);
      });
  };

  // Save absent
  const saveAbsent = (e) => {
    const personaId = e.target.dataset.personaid;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        ID: uuid(),
        Type: 'absent',
        Reason: absentReason,
        Author: auth.ID,
      })
      .then(() => {
        setAbsent(false);
        setMakeCorrection(false);
      });
  
  }

  // Show checked
  const showChecked = (presence) => {
    return presenceReports?.some(report => {
      const reportDate = new Date(report.Date); // Ensure report.Date is converted to a Date object
      return report.PersonaID === presence.name && 
             reportDate.toDateString() === new Date().toDateString();
    }) || false;
  };

  // Presence month handler
  const presenceMonthHandler = (e) => {
    const month = e.target.options[e.target.selectedIndex].value;
    setPresenceMonth(month);
  };

  const personaPresenceData = (personaID) => {
    // Filter reports for the selected persona and matching the selected month
    const reports = presenceReports && presenceReports.filter(report => {
      const reportDate = new Date(report.Date); // Convert date to JavaScript Date object
      return (
        report.PersonaID === personaID && // Match persona ID
        reportDate.getMonth() + 1 === parseInt(presenceMonth) && // Match month (getMonth is zero-based)
        reportDate.getFullYear() === today.getFullYear() // Match year to the current year
      );
    });
  
    // Get the total hours worked by the persona
    const totalHours = reports && reports.reduce((total, report) => {
      const start = report.Start?.split(':');
      const end = report.End?.split(':');
      const startHours = parseInt(start && start[0]);
      const startMinutes = parseInt(start && start[1]);
      const endHours = parseInt(end && end[0]);
      const endMinutes = parseInt(end && end[1]);
      const hours = endHours - startHours;
      const minutes = endMinutes - startMinutes;
      return total + hours + minutes / 60;
    }, 0);

    return totalHours && totalHours.toFixed(0);
  };

  return (
    <div className="main">
      <div className="main-container" >
        <div className='page-header'>
          <AspectRatioOutlinedIcon />
          <h1>Overzicht</h1>
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CalendarTodayOutlinedIcon className='icon' />
            <h2>Vandaag aanwezig</h2>
          </div>
          <div id='persona-dashboard-presence-today-container'>
            <p id='persona-dashboard-day-name'>{capitalizedDutchDayName}</p>
            <p>{longName}</p>
            <div id='persona-dashboard-presence-today-time-container'>
              <p>{hours}:</p>
              <p>{minutes}</p>
            </div>
          </div>

          <div>
            {presenceToday().map((presence, index) => (
              <div key={index} id='dashboard-presence-container'>
                <div id='dashboard-presence-name-container'>
                  <PersonaNameMeta personaID={presence.name} />
                </div>

                <div id='dashboard-presence-times-container'>
                  <p>{presence.start}</p>
                  <p>-</p>
                  <p>{presence.end}</p>
                </div>

                <div id='check-precense-container'>
                  {showChecked(presence) ?
                    <div id='presence-is-checked-notice-container'>
                      <CheckOutlinedIcon />
                      <p>Gecontroleerd</p>
                    </div>
                    :
                    <div>
                      <div id='register-presence-container'>
                      <div 
                        className='register-precense-options-container' 
                        onClick={() => setMakeCorrection(prevState => ({ 
                          id: presence.name, 
                          makeCorrection: !prevState.makeCorrection 
                        }))}
                      >
                          <img src={ErasorIcon} alt="erasor"  />
                          <p>Correctie</p>
                        </div>
                        <div className='register-precense-options-container'>
                          <img src={XIcon} alt="x" onClick={() => setAbsent(!absent)} />
                          <p onClick={() => setAbsent(!absent)}>Afwezig</p>
                        </div>
                      </div>
                      <div id='register-presence-container' style={{ display: makeCorrection.makeCorrection && makeCorrection.id === presence.name ? 'flex' : 'none',   }}>
                        <p>Correctie</p>
                        <input type="time" onChange={(e) => setCorrectStartTime(e.target.value)} />
                        <p>-</p>
                        <input type="time" onChange={(e) => setCorrectEndTime(e.target.value)} />
                        <input type="text" placeholder='Reden correctie' onChange={(e) => setCorrectionReason(e.target.value) } />
                        <button className='button-simple' data-start={presence.start} data-end={presence.end} data-personaid={presence.name} onClick={saveCorrection}>Opslaan</button>
                      </div>
                      <div style={{ display: absent ? 'flex' : 'none' }}>
                        <p>Reden afwezigheid</p>
                        <select name="" id="" onChange={(e) => setAbsentReason(e.target.options[e.target.selectedIndex].value)}>
                          <option value="">-- Selecteer reden afwezigheid --</option>
                          <option value="ziek">Ziek</option>
                          <option value="verlof">Verlof</option>
                          <option value="overig">Overig</option>
                        </select>
                        <button className='button-simple' data-personaid={presence.name} onClick={saveAbsent}>Opslaan</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CalendarTodayOutlinedIcon className='icon' />
            <h2>Aanwezig totaal</h2>
          </div>
          <div className='table-container dashboard-container'>
            <div>
              <select name="" id="" defaultValue={new Date().getMonth() + 1} onChange={presenceMonthHandler}>
                <option value="1">Januari</option>
                <option value="2">Februari</option>
                <option value="3">Maart</option>
                <option value="4">April</option>
                <option value="5">Mei</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">Augustus</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                    <th style={{backgroundColor: secundairyColor}}>TOTALE UREN</th>
                    <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                </tr>
                {personas && personas.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <p>{item.Name}</p>
                        </td>
                        <td>
                            <p>{personaPresenceData(item.ID)}</p>
                        </td>
                        <td>
                            <Tooltip content='Details' >
                                <SearchOutlinedIcon />
                            </Tooltip>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
        </div>

        {/* <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CircleOutlinedIcon className='icon' />
            <h2>Doelen</h2>
          </div>
        </div> */}


        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CircleOutlinedIcon className='icon' />
            <h2>Status deelnemers</h2>
          </div>
          <StatusData />
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <RouteOutlinedIcon className='icon' />
            <h2>Trajecten</h2>
          </div>
          <RouteData />
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container dashboard-outflow-title-container">
            <ForkRightOutlinedIcon className='icon' />
            <h2>Uitstroom binnen
              <select name="" id="" onChange={outflowRangeHandler}>
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="12">12</option>
              </select>
              maanden
            </h2>
          </div>
          <div>
            <table className='dashboard-outflow-table'>
              <thead>
                <tr>
                  <th style={{ backgroundColor: secundairyColor }}>Naam</th>
                  <th style={{ backgroundColor: secundairyColor }}>Einddatum</th>
                </tr>
              </thead>
              <tbody>
                {outflowPersonas && outflowPersonas.map(persona => (
                  <tr key={persona.id} onClick={() => { history.push(`/${client}/personabuilder/${persona.ID}`) }}>
                    <td>{persona.Name}</td>
                    <td>{convertDateFormat(persona.EndDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaDashboard;