import printIcon from "../../images/icons/print-icon.png"
import arrowLeftIcon from "../../images/icons/arrow-left-icon.png"
import { useHistory } from "react-router-dom";
import { useFirestoreID, useFirestore } from '../../firebase/useFirestore';
import Location from '../../hooks/Location';
import { useEffect, useState } from "react";
import PrintLiveReportActivities from "../../components/liveReport/printLiveReport/PrintLiveReportActivities";
import PrintLiveReportEffects from "../../components/liveReport/printLiveReport/PrintLiveReportEffects";
import LiveReportTargetgroupsDashboard from "../../components/liveReport/LiveReportTargetgroupsDashboard";
import PrintLiveReportResearch from "../../components/liveReport/printLiveReport/PrintLiveReportResearch";
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import personIcon from '../../images/icons/person-icon.png'
import activityIcon from '../../images/icons/activity-icon.png'
import trajectIcon from '../../images/icons/traject-icon.png'
import useSettings from "../../hooks/Settings";

const PrintLiveReport = () => {
     // State
     const [report, setReport] = useState('')
     const [client, setClient] = useState('')
    const [logo, setLogo] = useState('')
 
     // Hooks
     const history = useHistory()
     const secundairyColor = useSettings().SecundairyColor
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
 
     // Url parameters
     const clientUrl = Location()[1]
     const reportUrl = Location()[3]

     useEffect(() => {
        setClient(clientUrl)
        setReport(reportUrl)

    }, [clientUrl, reportUrl])

    // Firestore
    const reports = useFirestoreID('LiveReports', report ? report : '123')
    const compagny = useFirestore('CompagnyMeta')

    // Set compagny logo
    useEffect(() => {
        compagny && compagny.map(item => (
        setLogo(item.Logo)
        ))
    }, [compagny])

  return (
    <div className="main">
        <div className="main-container">
            <div className="print-go-back-container" onClick={() => history.push(`/${client}/livereports`)}>
                <img src={arrowLeftIcon}/>
                <p>Terug</p>
            </div>
            <div className="print-icon-container" onClick={() => window.print()}>
                <img src={printIcon} alt="print icon" className="print-icon" />
                <p>Print live rapportage</p>
            </div>
            <div id='print-live-report-container'>
                {reports && reports.map(item => (
                    <div>
                        {/* Banner, logo and title */}
                        <div>
                            <img className="report-banner" src={item.Banner} alt="" />
                            <div id='live-report-logo-period-container'>
                                {/* Logo */}
                                <img className="report-logo" src={logo} alt="" />

                                {/* Periode */}
                                <div id="live-report-open-period-container">
                                    <p>{item.Start ? item.Start.toDate().toLocaleDateString("nl-NL", options) : ''}</p>
                                    <p>&nbsp; - &nbsp;</p>
                                    <p>{item.End ? item.End.toDate().toLocaleDateString("nl-NL", options) : 'Alles'}</p>
                                </div>
                            </div>
                            <h1 className="report-title" style={{color: secundairyColor}}>{item.Title}</h1>
                        </div>

                        {/* Description */}
                        <div className="report-inner-container">
                            <div className="report-head">
                            <p>{item.Description}</p>
                            </div>

                            {/* Targetgroups */}
                            {item.Targetgroups?.length > 0 &&
                            <div className="report-section report-section-main">
                                <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                                    <img src={personIcon} alt="" />
                                    <h2 style={{color: secundairyColor}}>{item.TargetgroupTitle}</h2>
                                </div>
                                <div className="report-activities-container">
                                {item.Targetgroups?.map(targetgroup => (
                                    <LiveReportTargetgroupsDashboard report={targetgroup}/>
                                ))}
                                </div>
                            </div>
                            }

                            {/* Activities */}
                            {item.Activities?.length > 0 &&
                            <div className="report-section report-section-main">
                                <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                                    <img src={activityIcon} alt="" />
                                    <h2 style={{color: secundairyColor}}>{item.ActivityTitle}</h2>
                                </div>
                                {item.Activities?.map(activity => (
                                <PrintLiveReportActivities activity={activity} startDate={item.Start} endDate={item.End}/>
                                ))}
                            </div>
                            }

                            {/* Effects */}
                            {item.KPIs?.length > 0 && 
                            <div className="report-section report-section-main">
                                <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                                    <img src={trajectIcon} alt="" />
                                    <h2 style={{color: secundairyColor}}>{item.KPITitle}</h2>
                                </div>
                                {item.KPIs?.map(effect => (
                                    <PrintLiveReportEffects effect={effect} startDate={item.Start} endDate={item.End}/>
                                ))}
                            </div>
                            }

                            {/* Research */}
                            {item.Researches?.length > 0 && 
                            <div className="report-section report-section-main">
                                <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                                    <BiotechOutlinedIcon />
                                    <h2 style={{color: secundairyColor}}>{item.ResearchTitle}</h2>
                                </div>
                                {item.Researches?.map(research => (
                                    <PrintLiveReportResearch researchId={research} startDate={item.Start} endDate={item.End}/>
                                ))}
                            </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default PrintLiveReport