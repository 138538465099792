import { client } from '../../hooks/Client';
import Location from "../../hooks/Location"
import { useFirestoreGeneralTwo, useFirestoreGeneral, useFirestoreGeneralOrderBy, useFirestoreID } from "../../firebase/useFirestore";
import { useState, useContext, useEffect } from "react";
import deleteIcon from '../../images/icons/delete-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import useSettings from "../../hooks/Settings";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import uuid from 'react-uuid';
import ButtonClicked from "../../hooks/ButtonClicked";
import Tooltip from "../../components/common/Tooltip";
import plusButton from '../../images/icons/plus-icon.png'
import CategoryWords from "../../components/reserachAnalyses/CategoryWords";
import CategoryAnalysisTitle from "../../components/reserachAnalyses/measureMoment/CategoryAnalysisTitle";
import AnalyseResponse from "../../components/reserachAnalyses/measureMoment/AnalyseResponse";
import { Auth } from "../../StateManagment/Auth";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import ScrollToTop from "../../hooks/ScrollToTop";
import { ResearchOpenQuestionCategoriesSystemMessage, ResearchOpenQuestionCategoriesToolCallContent, ResearchOpenQuestionCategoriesSaveMessage } from "../../hooks/impactAI/Prompts"
import Breadcrumb from "../../components/common/Breadcrumb";
import ColorPickerHousestyle from '../../components/common/ColorPickerHousestyle.jsx';

const ResearchResultsOpenQuestionAnalysis = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // State
    const [categoryID, setCategoryID] = useState('')
    const [color, setColor] = useState('')
    const [momentID, setMomentID] = useState('')
    const [question, setQuestion] = useState('')
    const [resultsArray, setResultsArray] = useState([])

     // Hooks
    ScrollToTop()
    const fieldID = Location()[3]
    const researchID = Location()[4]
    const secundairyColor = useSettings().SecundairyColor
 
     // Firestore
     const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'FieldID', fieldID ? fieldID : '', 'MomentID', momentID ? momentID : '')
     const categories = useFirestoreGeneral('AnalysisCategories', 'FieldID', fieldID ? fieldID : '')
     const moments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchID ? researchID : '', 'Position', 'asc')
     const fields = useFirestoreID('QuestionnaireFields', fieldID ? fieldID : '')

    // ImpactAI
    const pageSystemMessage = ResearchOpenQuestionCategoriesSystemMessage(resultsArray, question)
    const pageToolCallContent = ResearchOpenQuestionCategoriesToolCallContent()
    const pageSaveMessage = ResearchOpenQuestionCategoriesSaveMessage()
    const pageDocid = ''
    const pageStartMessage = `Hoi, ${auth?.ForName}. Op deze pagina gaan we de open vraag '${question}' categoriseren zodat we de resultaten kunnen analyseren. 
    Zullen we starten?`
    const pageExampleMessages = []
    const pageCollection = 'AnalysisCategories'
    const pageField = 'Categorie'
    const pageParentId = fieldID
    const pageType = 'categorizeResponse'
    
    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth, question])

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

     // Hightlight impactAI
     const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, laten we starten!')
    }

    // Create an array of all the results
    useEffect(() => {
        const array = []
        results && results.forEach(result => {
            array.push(result.Input)
        })
        setResultsArray(array)
    },[results])

     // Set default moment in state
    useEffect(() => {
        setMomentID(moments[0]?.ID)
    },[moments])

    // Set question in state
    useEffect(() => {
        fields && fields.forEach(field => {
            setQuestion(field.Question)
        })
    },[fields])

     // Select a category
     const selectCategory = (e) => {
        const id = e.target.dataset.id
        const color = e.target.dataset.color

        setCategoryID(id)
        setColor(color)

    }

    // Delete category
    const deleteCategory = (e) => {
        const docid = e.target.dataset.docid

        db.collection('AnalysisCategories')
        .doc(docid)
        .delete()
    }

    // Add category
    const addCategory = (e) => {

        ButtonClicked(e, 'Opgeslagen')

        db.collection('AnalysisCategories')
        .doc()
        .set({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            FieldID: fieldID,
            ResearchID: researchID,
            Categorie: '',
            Color: secundairyColor,
            Position: categories.length + 1
        })
    }
 


  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>Categoriseer</h1>
                <p>{question}</p>
            </div>
            <div className="select-activity-container select-activity-container-personas">
                <div className="select-activity-inner-container">
                    {moments && moments.map(moment => (
                        <div className='activity-select-item-container' onClick={() => setMomentID(moment.ID)} style={{backgroundColor: momentID === moment.ID ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setMomentID(moment.ID)} style={{color: momentID === moment.ID ? 'white' : '#616161'}}>{moment.Title}</p>
                        </div>
                    ))}
                </div>
            </div> 
            <div className="table-container dashboard-container">
                <p><b>Categorien</b></p>
                <div className='list-top-row-container'>
                    <Tooltip content={'Voeg een categorie toe'} width='30px' top='35px'>
                        <img src={plusButton} alt="" onClick={addCategory}/>
                    </Tooltip>
                </div>
                    <div className='table-container'>
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>SELECTEER</th>
                                <th style={{backgroundColor: secundairyColor}}>CATEGORIE</th>
                                <th style={{backgroundColor: secundairyColor}}>GESELECTEERDE WOORDEN</th>
                                <th style={{backgroundColor: secundairyColor}}>KLEUR</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                            {categories && categories.map(item => (
                                <tr key={item.ID}>
                                    <td>
                                        <input type="checkbox" name='selectCategory' data-color={item.Color} data-docid={item.docid} value={item.ID} data-id={item.ID} checked={categoryID === item.ID} onChange={selectCategory} />
                                        <label htmlFor={item.ID}></label>
                                    </td>
                                    <td>
                                        <CategoryAnalysisTitle item={item}/>
                                       
                                    </td>
                                    <td>
                                        <div id='analysis-words-suggestion-container'>
                                            <CategoryWords category={item} field={fieldID} moment={momentID} />
                                        </div>
                                    </td>
                                    <td>
                                        <ColorPickerHousestyle item={item}/>
                                    </td>
                                    <td>
                                        <Tooltip content='Categorie verwijderen' top='-60px'>
                                            <img className='table-delete-icon' data-docid={item.docid} onClick={deleteCategory} src={deleteIcon} alt="" />
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
                <div className="table-container dashboard-container">
                    <p><b>Responses</b></p>
                    <div>
                        {results && results.map(item => (
                            <AnalyseResponse item={item} setSaved={setSaved} categoryID={categoryID} moment={momentID} field={fieldID} color={color} />
                        ))}
                    </div>
                </div>
                <Breadcrumb
                section={'Meten'}
                previousPage={'Onderzoeksresultaten'}
                previousPageUrl={`researchresults/${researchID}`}
                nextPage={''}
                nextPageUrl={''}
                counter={2}
                totalSteps={5}
                />
            </div>
         {/* <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteAllResults} deleteName={deleteName} /> */}
    </div>
  )
}

export default ResearchResultsOpenQuestionAnalysis