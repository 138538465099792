import { useFirestoreID } from "../../firebase/useFirestore"
import LiveReportMedia from "./LiveReportMedia";
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from "../../firebase/config";
import firebase from 'firebase'
import Tooltip from "../common/Tooltip";
import OutputData from "../graphData/OutputData";

const ReportBuilderActivities = ({activity, docid, startDate, endDate}) => {

    // Firestore
    const activities = useFirestoreID('Activities', activity)

    const deleteActivity = (e) => {
        const id = e.target.dataset.id

        db.collection('LiveReports')
        .doc(docid)
        .update({
            Activities: firebase.firestore.FieldValue.arrayRemove(id)
        })
    }

    const activityDescriptionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Activities')
        .doc(docid)
        .update({
            Description: e.target.value
        })
    }

  return (
    <div className="report-activities-container">
        {activities && activities.map(item => (
                <div className="report-activity-item-container" style={{borderBottom: activities.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Activity}</h3>
                    <textarea cols="30" data-docid={item.docid} defaultValue={item.Description} placeholder="Voeg een omschrijving toe van de activiteit" rows="10" onChange={activityDescriptionHandler}></textarea>
                    <LiveReportMedia parent={item}/>
                </div>
                <div className="report-activity-item-graph-container">
                    <h3>Aantal</h3>
                    <OutputData activity={item.ID} startDate={startDate} endDate={endDate} graphType='line' />
                </div>
                <Tooltip content="Activiteit verwijderen" width='4%' top='-60px'>
                    <img className="delete-icon-report-builder" src={deleteIcon} alt="" data-id={item.ID} onClick={deleteActivity}/>
                </Tooltip>
            </div>
        ))}
        
    </div> 
  )
}

export default ReportBuilderActivities