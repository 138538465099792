import {
    PieChart as RechartsPieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Legend,
    Tooltip,
  } from 'recharts';
  import TotalResponses from '../TotalResponses';

const ScaleDevelopmentPieChart = ({ groupedData, colors, totalResponses }) => {

   // Transform groupedData into the pie chart format
   const pieData =
   groupedData && groupedData.length > 0
     ? groupedData.map((item) => ({
         name: item.moment, // Use 'moment' as the name
         value: parseFloat(item.average), // Convert 'average' to a number
       }))
     : [{ name: 'No data', value: 1 }];

 // Custom tooltip content
 const CustomTooltip = ({ active, payload }) => {
   if (active && payload && payload.length) {
     const { name, value } = payload[0].payload;
     return (
       <div
         style={{
           backgroundColor: 'white',
           padding: '10px',
           border: '1px solid #ccc',
           borderRadius: '5px',
         }}
       >
         <p style={{ margin: 0, fontWeight: 'bold' }}>{name}</p>
         <p style={{ margin: 0 }}>{`Gemiddelde score: ${value}`}</p>
       </div>
     );
   }
   return null;
 };

 return (
   <div
     style={{
       display: 'block',
       justifyContent: 'center',
       alignItems: 'center',
       width: '100%',
     }}
   >
      <TotalResponses totalResponses={totalResponses} />
     <ResponsiveContainer width="100%" height={300}>
       <RechartsPieChart>
         <Pie
           data={pieData}
           cx="40%"
           cy="50%"
           innerRadius={0}
           outerRadius={120}
           paddingAngle={0}
           dataKey="value"
           labelLine={false}
          //  label={({ name, value }) => `${name} (${value})`}
         >
           {pieData.map((entry, index) => (
             <Cell
               key={`cell-${index}`}
               fill={colors[index % colors.length]} // Cycle through COLORS
             />
           ))}
         </Pie>
         <Tooltip content={<CustomTooltip />} />
         <Legend
           layout="vertical"
           align="right"
           verticalAlign="middle"
           wrapperStyle={{
             paddingLeft: '10px',
           }}
         />
       </RechartsPieChart>
     </ResponsiveContainer>
   </div>
 );
}

export default ScaleDevelopmentPieChart