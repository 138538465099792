import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';

const TotalResponses = ({totalResponses}) => {
  return (
    <div className='graph-total-responses-container'>
        <Groups2OutlinedIcon />
        <p>{totalResponses || 'onbekend'} responses</p>
    </div>
  )
}

export default TotalResponses