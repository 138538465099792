import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import useSettings from "../../hooks/Settings";
import plusButton from '../../images/icons/plus-icon.png'
import robotImage from "../../images/icons/robot-icon.png"
import { useHistory } from "react-router-dom";
import { client, type } from "../../hooks/Client"
import Tooltip from '../../components/common/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFirestore, useFirestoreOrderBy } from '../../firebase/useFirestore';
import { db } from '../../firebase/config';
import deleteIcon from '../../images/icons/delete-icon.png'

const Insights = () => {

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Hooks
    const options = { day: 'numeric', month: 'numeric', year: 'numeric'};

    // Firestore
    const insights = useFirestoreOrderBy('Insights', 'Position', 'asc')

    // Title Handler
    const titleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Insights').doc(docid).update({
            Title: e.target.value
        })
    }

    // Delete Insight
    const deleteInsight = (e) => {
        const docid = e.target.dataset.docid
        
        db.collection('Insights').doc(docid).delete()
    }

    const type = (type) => {

        switch(type) {
            case 'cross-research-question-comparison':
                return 'Vergelijking onderzoeken'
            case 'sentiment-analyse-researches':
                return 'AI analyse onderzoeken'
            case 'complete-progress-analysis':
                return 'AI voortgangsanalyse'
            case 'cross-research-question-comparison':
                return 'Vergelijking onderzoeken één vraag'
            case 'sentiment-analyse-open-question':
                return 'AI analyse open vraag'
            default:
                return 'Onbekend'
        }
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Inzichten</h1>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content={'Inzicht toevoegen'} width={'30px'} top='35px'>
                        <img  src={plusButton} alt="" onClick={() => history.push(`/${client}/addinsight`)} />
                    </Tooltip>
                    <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                        {/* <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/> */}
                    </Tooltip>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                        <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                        <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>

                    </tr>
                    {insights && insights.map(insight => (
                        <tr key={insight.ID}>
                            <input type="text" data-docid={insight.docid} defaultValue={insight.Title} onChange={titleHandler}/>
                            <td>{type(insight.Type)}</td>
                            <td>{insight.Timestamp.toDate().toLocaleDateString("nl-NL", options)}</td>
                            <td>
                                <Tooltip content={'Inzicht instellingen'} width='80%' top='-60px'>
                                    <EditOutlinedIcon className='table-edit-icon' 
                                    onClick={() => history.push(`/${client}/editinsight/${insight.ID}`)}/>
                                </Tooltip>
                            </td>
                            <td>
                                <Tooltip content={'Verwijder inzicht'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-docid={insight.docid} onClick={deleteInsight} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default Insights