import { useEffect, useState } from "react";
import { useFirestore} from "../../firebase/useFirestore";
import OutputMultiLineGraph from '../../components/Visualisations/outputs/OutputMultiLineGraph'
import useSettings from "../../hooks/Settings";
import { db } from "../../firebase/config";
import { client } from '../../hooks/Client';

const RouteData = () => {
    // State
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    // Hooks
    const primaryColor = useSettings().PrimaryColor;

    // Firestore
    const routes = useFirestore('PersonaRoutes')

    useEffect(() => {
        const fetchData = async () => {
            if (!routes) return;
            setIsLoading(true);
    
            const promises = routes.map(async (route) => {
                let length = 0;
    
                await db.collection('Personas')
                .where('CompagnyID', '==', client)
                .where('RouteID', '==', route.ID)
                .get()
                .then((querySnapshot) => {
                    length = querySnapshot.size;
                });
    
                return {
                    name: route.Title,
                    value: length,
                };
            });
    
            // Wait for all promises to resolve
            const results = await Promise.all(promises);
    
            // Now update the state once with all the results
            setData(results);
            setIsLoading(false);
        };
    
        fetchData();
    }, [routes]);

    const bars = [
        { Title: 'value', Color: primaryColor },
    ]

  return (
    <OutputMultiLineGraph
        isLoading={isLoading}
        data={data}
        graphType={'bar'}
        outputs={bars}
        growth={{ average: 0, perOutput: {} }}
        height={200}
    />
  )
}

export default RouteData