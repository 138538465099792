import { db } from '../../../firebase/config';

const activityData = async (activities) => {

    const options = { month: 'numeric', year: 'numeric'};

    try {
        const array = [];

        // Check if there is activities to process
        if (activities && activities.length > 0) {
            // Map over each selected activity and process it
            const activitiesPromises = activities.map(async (activity) => {

                if (!activity) {
                    console.error(`Activity not found.`);
                    return null;
                }

                // Create the base structure for storing activity details
                const object = {
                    ActivityTitle: activity.Activity,
                    Outputs: [],
                };

                // Fetch all outputs associated with the activity
                const outputsSnapshot = await db
                    .collection('Outputs')
                    .where('ActivityID', '==', activity.ID)
                    .get();

                // Process each output
                const outputsPromises = outputsSnapshot.docs.map(async (outputsDoc) => {
                    const outputsData = outputsDoc.data();

                    // Create the structure for the output
                    const outputsObject = {
                        OutputTitle: outputsData.Title,
                        Results: [],
                    };

                    // Fetch all results for the current output
                    const resultsSnapshot = await db
                        .collection('Results')
                        .where('OutputID', '==', outputsData.ID)
                        .where('ActivityID', '==', outputsData.ActivityID)
                        .get();

                    // Process each result in the output
                    const resultsPromises = resultsSnapshot.docs.map(async (resultsDoc) => {
                        const resultsData = resultsDoc.data();

                        // Create a result object
                        const resultsObject = {
                            Result: resultsData.Result,
                            Date: resultsData.Timestamp.toDate().toLocaleDateString("nl-NL", options),
                        };

                        // Add the result to the output object
                        outputsObject.Results.push(resultsObject);

                    });

                    // Wait for all responses to be processed for the output
                    await Promise.all(resultsPromises);
                    object.Outputs.push(outputsObject);
                });

                // Wait for all measure moments to be processed for the research
                await Promise.all(outputsPromises);

                // Return the completed research object
                return object;
            });

            // Wait for all research processing promises
            const results = await Promise.all(activitiesPromises);

            // Filter out any null results and add them to the array
            array.push(...results.filter((result) => result !== null));
        }

        // Return the final array of research analysis results
        return array;
       
    } catch (error) {
        // Handle and log any errors encountered during processing
        console.error('Error creating activitiesdata:', error);
    } 
}

export default activityData;