// General
import "../CSS/index.css";
import { Switch, Route } from "react-router-dom";
import { client } from "../hooks/Client";
import Layout from "../components/layout/Layout";

// Layout
import Home from "../pages/layout/Home";
import Helpdesk from "../pages/layout/Helpdesk";
import Dashboard from "../pages/layout/Dashboard";
import SROI from "../pages/measurementPlan/SROI";
import QualityCheck from "../pages/layout/QualityCheck";
import Impactmarket from "../pages/financing/Impactmarket";
import Notifications from "../pages/layout/Notifications";

// Portfolio
import Portfolio from "../pages/portfolio/Portfolio";
import Projects from "../pages/portfolio/Projects";
import SynchronisationsPortfolio from "../pages/portfolio/Synchronisations";
import ProjectDetails from "../pages/portfolio/ProjectDetails";
import SyncDetails from "../pages/portfolio/SyncDetails";

// ImpactStrategy
import Impactstrategy from "../pages/impactStrategy/Impactstrategy";
import Goals from "../pages/impactStrategy/Goals";
import ProblemAnalysis from "../pages/impactStrategy/ProblemAnalyse";
import OrganisationContext from "../pages/impactStrategy/OrganisationContext";
import Stakeholders from "../pages/impactStrategy/Stakeholders";
import Activities from "../pages/impactStrategy/Activities";
import TheoryOfChange from "../pages/impactStrategy/TheoryOfChange";
import Effects from "../pages/impactStrategy/Effects";
import OutputSettings from "../pages/impactStrategy/Outputs";
import PrintOutputs from "../pages/impactStrategy/PrintOutputs";

// Measurementplan
import SelectKPIs from "../pages/measurementPlan/SelectKPIs";
import SelectKPIsOutputs from "../pages/measurementPlan/SelectKPIsOutputs";
import Indicators from "../pages/measurementPlan/Indicators";
import ResearchAnalysis from "../pages/measurementPlan/ResearchAnalysis";
import ResearchResultsOpenQuestionAnalysis from "../pages/measurementPlan/ResearchResultsOpenQuestionAnalysis";
import KPIs from "../pages/measurementPlan/KPIs";
import QuestionaireBuilder from "../pages/measurementPlan/QuestionaireBuilder";
import ResearchBuilder from "../pages/measurementPlan/ResearchBuilder";
import Measurementplan from "../pages/measurementPlan/Measurementplan";
import Questionnaires from "../pages/measurementPlan/Questionnaires";
import ResearchResults from "../pages/measurementPlan/ResearchResults";
import ResearchResponses from "../pages/measurementPlan/ResearchResponses";
import Research from "../pages/measurementPlan/Research";
import OutputResults from "../pages/measurementPlan/OutputResults";
import AddResults from "../pages/measurementPlan/AddResults";
import ShareQuestionnaire from "../pages/measurementPlan/ShareQuestionnaire";
import IndicatorQuestionDetail from "../pages/measurementPlan/IndicatorQuestionDetail";
import AddIndicatorsToQuestionaire from "../pages/measurementPlan/AddIndicatorsToQuestionaire";
import AddIndicators from "../pages/measurementPlan/AddIndicators";
import PrintQuestionaire from "../pages/measurementPlan/PrintQuestionaire";
import Qrcode from "../pages/measurementPlan/Qrcode";
import AddStandardEffectsToQuestionaire from "../pages/measurementPlan/AddStandardEffectsToQuestionaire";
import Storytelling from "../pages/measurementPlan/Storytelling";
import AddEvent from "../pages/measurementPlan/AddEvent";
import Insights from "../pages/measurementPlan/Insights";
import AddInsight from "../pages/measurementPlan/AddInsight";
import EditInsight from "../pages/measurementPlan/EditInsight";
import ImportResults from "../pages/measurementPlan/ImportResults";
import AddOnstructuredInterview from "../pages/measurementPlan/AddOnstructuredInterview";
import AIInterviewer from "../pages/measurementPlan/AIInterviewer";

// Communication
import Communication from "../pages/communication/Communication";
import LiveReportBuilder from "../pages/communication/LiveReportBuilder";
import LiveReports from "../pages/communication/LiveReports";
import Synchronisations from "../pages/communication/Synchronisations";
import SynchronisationBuilder from "../pages/communication/SynchronisationBuilder";
import PrintLiveReport from "../pages/communication/PrintLiveReport";
import AIAnalysis from "../pages/communication/AIAnalysis";

// Financing
import FundDetails from "../pages/financing/FundDetails";
import Funds from "../pages/financing/Funds";
import FundsDatabase from "../pages/financing/FundsDatabase";
import Financing from "../pages/financing/Financing";
import AllFunds from "../pages/financing/AllFunds";

// Personas
import PersonaDashboard from "../pages/personas/PersonaDashboard";
import Personas from "../pages/personas/Personas";
import PersonasExplainer from "../pages/personas/PersonasExplainer";
import ReserachResultsPersonas from "../pages/personas/ReserachResultsPersonas";
import ResearchResponsesPersonas from "../pages/personas/ResearchResponsesPersonas";
import ResearchResultsPersonasForms from "../pages/personas/ResearchResultsPersonasForms";
import Agenda from "../pages/personas/Agenda";
import PersonaSettings from "../pages/personas/PersonaSettings";
import GoalDetail from "../pages/personas/GoalDetail";
import PersonaReportDetail from "../pages/personas/PersonaReportDetail";
import PersonaBuilder from "../pages/personas/PersonaBuilder";
import PersonaPresenceReportDetails from "../pages/personas/PersonaPresenceReportDetails";
import PresenceReport from "../pages/personas/PresenceReport";
import PrintPresenceReport from "../pages/personas/PrintPresenceReport";
import PersonaGoalProgress from "../pages/personas/PersonaGoalProgress";
import PersonaMethodeDetails from "../pages/personas/PersonaMethodeDetails";
import ReportStandardDetails from "../pages/personas/ReportStandardDetails";

// Admin
import Subscription from "../pages/admin/Subscription";
import UserRoles from "../pages/admin/UserRoles";
import Settings from "../pages/admin/Settings";
import Members from "../pages/admin/Members";
import Data from "../pages/admin/Data";

// Superadmin
import AdminFunds from "../pages/superadmin/AdminFunds";
import FundBuilder from "../pages/superadmin/FundBuilder";
import MetaData from "../pages/superadmin/MetaData";

// Auth
import NotApproved from "../pages/auth/NotApproved";
import Login from "../pages/auth/Login";
import Profile from "../pages/auth/Profile";
import CreateAccount from "../pages/auth/CreateAccount";
import Register from "../pages/auth/Register";
import RegisterInvitation from "../pages/auth/RegisterInvitation";

// Open
import LiveReportOpen from "../pages/open/LiveReportOpen";
import QuestionnaireOpen from "../pages/open/QuestionnaireOpen";
import Impactplan from "../pages/open/Impactplan";


const Main = () => {
  return (
    <>
      <Switch>

        // Auth
        <Route exact path={`/`}>
          <Login />
        </Route>
        <Route path={`/login`}>
          <Login />
        </Route>
        <Route path={`/${client}/NotApproved`}>
          <NotApproved />
        </Route>
        <Route path={`/:id/Register`}>
          <Register />
        </Route>
        <Route path={`/createAccount`}>
          <CreateAccount />
        </Route>
        <Route path={`/${client}/RegisterInvitation`}>
          <RegisterInvitation />
        </Route>

        // Layout
        <Route exact path={`/${client}`}>
          <Layout>
            <Home />
          </Layout>
        </Route>
        <Route path={`/${client}/dashboard`}>
          <Layout>
            <Dashboard />
          </Layout>
        </Route>
        <Route path={`/${client}/notifications`}>
          <Layout>
            <Notifications />
          </Layout>
        </Route>
        <Route path={`/${client}/home`}>
          <Layout>
            <Home/>
          </Layout>
        </Route>
        <Route path={`/${client}/helpdesk`}>
          <Layout>
            <Helpdesk />
          </Layout>
        </Route>
        <Route path={`/${client}/qualitycheck`}>
          <Layout>
            <QualityCheck />
          </Layout>
        </Route>

        // Portfolio
        <Route path={`/${client}/portfolio`}>
          <Layout>
            <Portfolio />
          </Layout>
        </Route>
        <Route path={`/${client}/projects`}>
          <Layout>
            <Projects />
          </Layout>
        </Route>
        <Route path={`/${client}/synchronisationsportfolio`}>
          <Layout>
            <SynchronisationsPortfolio />
          </Layout>
        </Route>
        <Route path={`/${client}/portfolioprojectdetails`}>
          <Layout>
            <ProjectDetails />
          </Layout>
        </Route>
        <Route path={`/${client}/portfoliosyncdetails`}>
          <Layout>
            <SyncDetails />
          </Layout>
        </Route>

        // ImpactStrategy
        <Route path={`/${client}/Goals`}>
          <Layout>
            <Goals />
          </Layout>
        </Route>
        <Route path={`/${client}/Activities`}>
          <Layout>
            <Activities />
          </Layout>
        </Route>
        <Route path={`/${client}/Outputs`}>
          <Layout>
            <OutputSettings />
          </Layout>
        </Route>
        <Route path={`/${client}/Stakeholders`}>
          <Layout>
            <Stakeholders />
          </Layout>
        </Route>
        <Route path={`/${client}/problemanalysis`}>
          <Layout>
            <ProblemAnalysis />
          </Layout>
        </Route>
        <Route path={`/${client}/Agenda`}>
          <Layout>
            <Agenda />
          </Layout>
        </Route>
        <Route path={`/${client}/TheoryOfChange`}>
          <Layout>
            <TheoryOfChange />
          </Layout>
        </Route>
        <Route path={`/${client}/effects`}>
          <Layout>
            <Effects />
          </Layout>
        </Route>
        <Route path={`/${client}/organisationcontext`}>
          <Layout>
            <OrganisationContext />
          </Layout>
        </Route>
        <Route path={`/${client}/impactstrategy`}>
          <Layout>
            <Impactstrategy />
          </Layout>
        </Route>
        <Route path={`/${client}/printoutputs`}>
          <PrintOutputs />
        </Route>
        

        // Measurementplan
        <Route path={`/${client}/indicators`}>
          <Layout>
            <Indicators />
          </Layout>
        </Route>
        <Route path={`/${client}/selectkpis`}>
          <Layout>
            <SelectKPIs />
          </Layout>
        </Route>
        <Route path={`/${client}/selectkpisoutputs`}>
          <Layout>
            <SelectKPIsOutputs />
          </Layout>
        </Route>
        <Route path={`/${client}/researchbuilder`}>
          <Layout>
            <ResearchBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/measurementplan`}>
          <Layout>
            <Measurementplan />
          </Layout>
        </Route>
        <Route path={`/${client}/researchanalysis`}>
          <Layout>
            <ResearchAnalysis />
          </Layout>
        </Route>
        <Route path={`/${client}/questionairebuilder`}>
          <Layout>
            <QuestionaireBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/kpis`}>
          <Layout>
            <KPIs />
          </Layout>
        </Route>
        <Route path={`/${client}/researchresultsopenquestionanalysis`}>
          <Layout>
            <ResearchResultsOpenQuestionAnalysis />
          </Layout>
        </Route>
        <Route path={`/${client}/sharequestionnaire`}>
          <Layout>
            <ShareQuestionnaire />
          </Layout>
        </Route>
        <Route path={`/${client}/research`}>
          <Layout>
            <Research />
          </Layout>
        </Route>
        <Route path={`/${client}/addresults`}>
          <Layout>
            <AddResults />
          </Layout>
        </Route>
        <Route path={`/${client}/outputresults`}>
          <Layout>
            <OutputResults />
          </Layout>
        </Route>
        <Route path={`/${client}/researchresults`}>
          <Layout>
            <ResearchResults />
          </Layout>
        </Route>
        <Route path={`/${client}/researchresponses`}>
          <Layout>
            <ResearchResponses />
          </Layout>
        </Route>
        <Route path={`/${client}/questionnaires`}>
          <Layout>
            <Questionnaires />
          </Layout>
        </Route>
        <Route path={`/${client}/SROI`}>
          <Layout>
            <SROI />
          </Layout>
        </Route>
        <Route path={`/${client}/indicatorquestiondetail`}>
          <Layout>
            <IndicatorQuestionDetail />
          </Layout>
        </Route>
        <Route path={`/${client}/addindicatorstoquestionaire`}>
          <Layout>
            <AddIndicatorsToQuestionaire />
          </Layout>
        </Route>
        <Route path={`/${client}/addindicators`}>
          <Layout>
            <AddIndicators />
          </Layout>
        </Route>
        <Route path={`/${client}/addstandardeffectstoquestionaire`}>
          <Layout>
            <AddStandardEffectsToQuestionaire />
          </Layout>
        </Route>
        <Route path={`/${client}/storytelling`}>
          <Layout>
            <Storytelling />
          </Layout>
        </Route>
        <Route path={`/${client}/addevent`}>
          <Layout>
            <AddEvent />
          </Layout>
        </Route>
        <Route path={`/${client}/insights`}>
          <Layout>
            <Insights />
          </Layout>
        </Route>
        <Route path={`/${client}/addinsight`}>
          <Layout>
            <AddInsight />
          </Layout>
        </Route>
        <Route path={`/${client}/editinsight`}>
          <Layout>
            <EditInsight />
          </Layout>
        </Route>
        <Route path={`/${client}/importresearchresults`}>
          <Layout>
            <ImportResults />
          </Layout>
        </Route>
        <Route path={`/${client}/addonstructuredinterview`}>
          <Layout>
            <AddOnstructuredInterview />
          </Layout>
        </Route>
        <Route path={`/${client}/aiinterviewer`}>
          <Layout>
            <AIInterviewer />
          </Layout>
        </Route>

        // Personas
        <Route path={`/${client}/personadashboard`}>
          <Layout>
            <PersonaDashboard />
          </Layout>
        </Route>
        <Route path={`/${client}/personas`}>
          <Layout>
            <Personas />
          </Layout>
        </Route>
        <Route path={`/${client}/researchresultspersonasforms`}>
          <Layout>
            <ResearchResultsPersonasForms />
          </Layout>
        </Route>
        <Route path={`/${client}/researchresponsespersonas`}>
          <Layout>
            <ResearchResponsesPersonas/>
          </Layout>
        </Route>
        <Route path={`/${client}/researchresultspersonas`}>
          <Layout>
            <ReserachResultsPersonas />
          </Layout>
        </Route>
        <Route path={`/${client}/personasexplainer`}>
          <Layout>
            <PersonasExplainer />
          </Layout>
        </Route>
        <Route path={`/${client}/personasettings`}>
          <Layout>
            <PersonaSettings />
          </Layout>
        </Route>
        <Route path={`/${client}/personagoaldetail`}>
          <Layout>
            <GoalDetail />
          </Layout>
        </Route>
        <Route path={`/${client}/personareportdetail`}>
          <Layout>
            <PersonaReportDetail />
          </Layout>
        </Route>
        <Route path={`/${client}/personabuilder`}>
          <Layout>
            <PersonaBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/personapresencereportdetail`}>
          <Layout>
            <PersonaPresenceReportDetails />
          </Layout>
        </Route>
        <Route path={`/${client}/presencereport`}>
          <Layout>
            <PresenceReport />
          </Layout>
        </Route>
        <Route path={`/${client}/printpresencereport`}>
          <PrintPresenceReport />
        </Route>
        <Route path={`/${client}/personagoalprogress`}>
          <Layout>
            <PersonaGoalProgress />
          </Layout>
        </Route>
        <Route path={`/${client}/personamethodedetails`}>
          <Layout>
            <PersonaMethodeDetails />
          </Layout>
        </Route>
        <Route path={`/${client}/personareportstandarddetails`}>
          <Layout>
            <ReportStandardDetails />
          </Layout>
        </Route>

        // Communication
        <Route path={`/${client}/communication`}>
          <Layout>
            <Communication />
          </Layout>
        </Route>
        <Route path={`/${client}/livereportbuilder`}>
          <Layout>
            <LiveReportBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/livereports`}>
          <Layout>
            <LiveReports />
          </Layout>
        </Route>
        <Route path={`/${client}/synchronisations`}>
          <Layout>
            <Synchronisations />
          </Layout>
        </Route>
        <Route path={`/${client}/synchronisationbuilder`}>
          <Layout>
            <SynchronisationBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/printlivereport`}>
          <PrintLiveReport />
        </Route>
        <Route path={`/${client}/aianalysis`}>
          <Layout>
            <AIAnalysis />
          </Layout>
        </Route>

        // Financing
        <Route path={`/${client}/funddetails`}>
          <Layout>
            <FundDetails />
          </Layout>
        </Route>
        <Route path={`/${client}/funds`}>
          <Layout>
            <Funds />
          </Layout>
        </Route>
        <Route path={`/${client}/fundsdatabase`}>
          <Layout>
            <FundsDatabase />
          </Layout>
        </Route>
        <Route path={`/${client}/financing`}>
          <Layout>
            <Financing />
          </Layout>
        </Route>
        <Route path={`/${client}/impactmarket`}>
          <Layout>
            <Impactmarket />
          </Layout>
        </Route>
        <Route path={`/${client}/allfunds`}>
          <Layout>
            <AllFunds />
          </Layout>
        </Route>

        // Admin & profile
        <Route path={`/${client}/subscription`}>
          <Layout>
            <Subscription />
          </Layout>
        </Route>
        <Route path={`/${client}/data`}>
          <Layout>
            <Data />
          </Layout>
        </Route>
        <Route path={`/${client}/UserRoles`}>
          <Layout>
            <UserRoles />
          </Layout>
        </Route>
        <Route path={`/${client}/Settings`}>
          <Layout>
            <Settings />
          </Layout>
        </Route>
        <Route path={`/${client}/Members`}>
          <Layout>
            <Members />
          </Layout>
        </Route>
        <Route path={`/${client}/Profile`}>
          <Layout>
            <Profile />
          </Layout>
        </Route>

        // Superadmin
        <Route path={`/${client}/adminfunds`}>
          <Layout>
            <AdminFunds />
          </Layout>
        </Route>
        <Route path={`/${client}/fundbuilder`}>
          <Layout>
            <FundBuilder />
          </Layout>
        </Route>
        <Route path={`/${client}/metadata`}>
          <Layout>
            <MetaData />
          </Layout>
        </Route>


        // Open
        <Route path={`/${client}/livereportopen`}>
          <LiveReportOpen />
        </Route>
        <Route path={`/${client}/questionnaireopen`}>
          <QuestionnaireOpen />
        </Route>
        <Route path={`/impactplan`}>
          <Impactplan />
        </Route>

        // Print
        <Route path={`/${client}/qrcode`}>
          <Qrcode />
        </Route>
        <Route path={`/${client}/printquestionaire`}>
          <PrintQuestionaire />
        </Route>

      </Switch>
    </>
  );
};

export default Main;

