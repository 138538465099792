import useSettings from "../../hooks/Settings";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import sentimentAnalyse from '../../components/AI/SentimentAnalyse';
import { useHistory } from "react-router-dom";
import researchData from '../../hooks/impactAI/datasets/researchData';
import activityData from '../../hooks/impactAI/datasets/activityData';
import tocData from '../../hooks/impactAI/datasets/tocData';
import { useState } from 'react';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client";
import { useFirestoreGeneral, useFirestoreOrderBy, useFirestore } from '../../firebase/useFirestore';
import { v4 as uuid } from 'uuid';

const AIProgressAnalysis = ({navigation}) => {
    // State
    const [suggestion, setSuggestion] = useState([]);
    const [loading, setLoading] = useState('none');

    // Hooks
    const secundairyColor = useSettings().SecundairyColor;
    const history = useHistory();

    // Firestore
    const insights = useFirestore('Insights')
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc');
    const sourceData = useFirestoreGeneral('SourceData', 'CompagnyID', client)
    const centralProblem = useFirestore('CentralProblem')
    const stakeholders = useFirestore('Stakeholders')
    const goals = useFirestore('Goals')
    const effects = useFirestore('OutputEffects')
    const allResearch = useFirestoreOrderBy('Research', 'Position', 'asc');

    // Onclick function to create progress analyse
    const createProgressAnalyse = async () => {

        const activityDataResult = await activityData(activities);
        const stringyfiedActivityData = JSON.stringify(activityDataResult);
        const researchDataResult = await researchData(allResearch);
        const stringyfiedResearchData = JSON.stringify(researchDataResult);
        const tocDataResult = await tocData(sourceData, centralProblem, stakeholders, goals, activities, effects);
        const stringyfiedTocData = JSON.stringify(tocDataResult);

        const prompt = [
            { "role": "system", "content": "Je bent een expert op het gebied van voortgangsanalyses." },
            { "role": "user", "content": `Ik wil graag een voortgangsanalyse maken.
                Dit is the impactstrategie van mijn sociale organisatie: ${stringyfiedTocData}.
                In hoeverre lukt het ons om onze doelen te behalen op basis van de resulaten onze activiteiten: ${stringyfiedActivityData} 
                en de resulaten van onze onderzoeken: ${stringyfiedResearchData}?
                Kun je aanbevelingen geven om onze doelen beter te bereiken?
                Geef een beknopt antwoord.
                ` }
        ];

        sentimentAnalyse(prompt, setSuggestion, setLoading);
    };

    // Function to save analysis
    const saveAnalysis = (e) => {
        const type = e.target.dataset.type;


        db.collection('Insights')
            .add({
                Content: suggestion.content ? suggestion.content : '',
                AverageInput: '', 
                CrossResearchResults: '',
                Type: type,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionnaireID: '',
                QuestionFieldID: '',
                ID: uuid(),
                Title: 'Inzicht ' + (insights.length + 1),
                Position: insights.length + 1,
            })
            .then(() => {
                history.push(`/${client}/insights`);
            })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
    };

  return (
     <div className='insights-main-type-container' style={{ display: navigation === 'analysis' ? 'block' : 'none' }}>
        <h2>Voortgangsanalyse</h2>
        <div class="table-container dashboard-container">
            <p>Creeer een analyse van je impact strategie op basis van alle onderzoeks- en outputresultaten.</p>
            <div className='start-insights-button-container' onClick={createProgressAnalyse}>
                <KeyboardDoubleArrowRightOutlinedIcon style={{fill: secundairyColor ? secundairyColor : 'black'}}/>
                <p style={{color: secundairyColor ? secundairyColor : 'black'}}>Maak voortgangsanalyse</p>
            </div>
            <h3>Analyse</h3>
            <div className='insights-analysis-content-container'>
                <p style={{ display: loading }}>Analyseren...</p>
                <p>{suggestion.content}</p>
            </div>
        
            <div>
                <button data-type='complete-progress-analysis' onClick={saveAnalysis}>Sla op</button>
            </div>
        </div>
    </div> 
  )
}

export default AIProgressAnalysis