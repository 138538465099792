import { db } from '../../firebase/config'
import { client } from '../../hooks/Client'
import { useEffect, useState } from 'react';
import DashboardLineGraphSmall from '../Visualisations/outputs/DashboardLineGraphSmall';
import DashboardBarchartSmall from '../Visualisations/outputs/DashboardBarchartSmall';
import OutputMeta from '../outputs/OutputMeta';

const DashboardOutputResultsDetail = ({outputID, startDate, endDate, color, graphType}) => {
    // State
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

     // Hooks
  const options = {year: 'numeric', month: 'numeric', day: 'numeric' };

    // Get the output results
    const getOutputResults = async (outputID, startDate, endDate) => {

        setIsLoading(true)

        const querySnapshot = await db.collection('Results')
        .where('CompagnyID', '==', client)
        .where('OutputID', '==', outputID)
        .where('Timestamp', '>=', startDate)
        .where('Timestamp', '<=', endDate)
        .get()

        const array = []

        querySnapshot.docs.map(async (doc) => {

            array.push({
                title: doc.data().OutputID,
                output: doc.data().OutputID,
                Resultaat: doc.data().Result,
                name: doc.data().Timestamp.toDate().toLocaleDateString("nl-NL", options)
            })

        })

        // Return 0 if there are no results
        if(querySnapshot.empty){
            array.push({
                title: outputID,
                output: outputID,
                Resultaat: 0,
            })
        }

        addResults(array)
        setData(array)
        setIsLoading(false)

    }

    // Add the results
    const addResults = (array) => {

        if(graphType === 'line' && array.length > 0){
          // Add the results to the previous result
          for (let i = 1; i < array.length; i++) {
            for (const key in array[i]) {
              if (typeof array[i][key] === 'number') {
                array[i][key] += array[i - 1][key];
              }
            }
          }
        } else if(graphType === 'bar'){
          return
        }
    
      }

    useEffect(() => {

        getOutputResults(outputID, startDate, endDate)

    }, [outputID, startDate, endDate, graphType])

  return (
    <div className='dashboard-output-detail-item-container'>
        <p><b><OutputMeta id={outputID}/></b></p>
        {graphType === 'bar' 
        ? 
        <DashboardBarchartSmall data={data} isLoading={isLoading} color={color}/> 
        : 
        <DashboardLineGraphSmall data={data} isLoading={isLoading} color={color}/>
        }
    </div>
    
  )
}

export default DashboardOutputResultsDetail