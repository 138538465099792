import { useState, useContext } from 'react';
import { useFirestore } from '../../../firebase/useFirestore';
import XIcon from '../../../images/icons/x-icon.png';
import { timestamp, db } from '../../../firebase/config';
import { v4 as uuid } from 'uuid';
import { client } from "../../../hooks/Client"
import ErasorIcon from '../../../images/icons/erasor-icon.png';
import { Auth } from '../../../StateManagment/Auth';
import Modal from 'react-modal';
import PersonaNameMeta from '../../../components/personas/PersonaNameMeta';

const DashboardPresenceCheckComponent = ({shedule}) => {
    // Context
    const [ auth ] = useContext(Auth)

    // State
    const [correctStartTime, setCorrectStartTime] = useState('');
    const [correctEndTime, setCorrectEndTime] = useState('');
    const [correctionReason, setCorrectionReason] = useState('');
    const [absent, setAbsent] = useState(false);
    const [absentReason, setAbsentReason] = useState('');
    const [correctionModalOpen, setCorrectionModalOpen] = useState(false);
    const [absentModalOpen, setAbsentModalOpen] = useState(false);

    // Firestore
    const presenceReports = useFirestore('PersonaPresenceReports');

    // Modal
    Modal.setAppElement('#root');

    const modalStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },
    };

    // Hooks
    const today = new Date();
    const dayName = new Intl.DateTimeFormat('en-EN', { weekday: 'long' }).format(today);
    const dayNameLowerCase = dayName.toLowerCase();
    const dutchDayName = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(today);
    const capitalizedDutchDayName = dutchDayName.charAt(0).toUpperCase() + dutchDayName.slice(1);
    const longName = new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(today);

     // Helper function to format the date to a Dutch string
   const formatDateToDutchString = (date) => {
    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} ${monthName} ${year}`;
  };


     // Helper function to check for main activity
  const checkForMainActivity = async (personaId) => {

    const mainActivity = await db.collection('PersonaActivities')
      .where('PersonaID', '==', personaId)
      .where('Type', '==', 'main')
      .get();

    if (mainActivity.empty) {
      return 
    } else {
        mainActivity.forEach( async doc => {

          const eventId = uuid();

          await db.collection('Events').add({
            Title: '',
            Date: timestamp,
            Outputs: [doc.data().OutputId],
            Personas: [personaId],
            ActivityID: doc.data().ActivityID,
            ID: eventId,
            Compagny: client,
            CompagnyID: client,
        });

        await db.collection('Results')
          .add({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Result: Number(1),
            Timestamp: timestamp,
            OutputID: doc.data().OutputId,
            ActivityID: doc.data().ActivityID,
            EventID: eventId,
            Personas: personaId,
          })
      });

    }
  };

    // Save correction
  const saveCorrection = (e) => {
    const personaId = e.target.dataset.personaid;
    const start = e.target.dataset.start;
    const end = e.target.dataset.end;

    const position = presenceReports?.filter(report => report.PersonaID === personaId).length + 1;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Start: start,
        End: end,
        CorrectedStart: correctStartTime,
        CorrectedEnd: correctEndTime,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        Reason: correctionReason,
        ID: uuid(),
        Type: 'correction',
        Author: auth.ID,
        Position: position
      })
      .then(() => {
        checkForMainActivity(personaId);
        setCorrectionModalOpen(false);
      });
  };

  // Save absent
  const saveAbsent = (e) => {

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: shedule.PersonaID,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        ID: uuid(),
        Type: 'absent',
        Reason: absentReason,
        Author: auth.ID,
      })
      .then(() => {
        setAbsent(false);
        setAbsentModalOpen(false);
      });
  
  }

  return (
    <div>
        <div id='register-presence-container'>
            <div 
            className='register-precense-options-container' 
            onClick={() => setCorrectionModalOpen(true)}
            >
                <img src={ErasorIcon} alt="erasor"  />
                <p>Correctie</p>
            </div>
            <div 
            className='register-precense-options-container'
            onClick={() => setAbsentModalOpen(true)}
            >
                <img src={XIcon} alt="x" onClick={() => setAbsent(!absent)} />
                <p>Afwezig</p>
            </div>
        </div>
        
        {/* Correction modal */}
        <Modal
        isOpen={correctionModalOpen}
        onRequestClose={correctionModalOpen}
        style={modalStyles}
        contentLabel="Aanwezigheid aanpassen"
        >
            <div>
              <h2>Correctie</h2>
              <div className='register-presence-time-container'>
                <p>Start</p>
                <input type="time" onChange={(e) => setCorrectStartTime(e.target.value)} />
              </div>
              <div className='register-presence-time-container'>
                <p>Eind</p>
                <input type="time" onChange={(e) => setCorrectEndTime(e.target.value)} />
              </div>
              <input id='register-presence-reason-correction-input' type="text" placeholder='Reden correctie' onChange={(e) => setCorrectionReason(e.target.value) } />
              <div id='delete-modal-button-container'>
                  <button onClick={() => setCorrectionModalOpen(false)}>Annuleren</button>
                  <button id='delete-modal-delete-button' data-start={shedule.StartTime} data-end={shedule.EndTime} data-personaid={shedule.PersonaID} onClick={saveCorrection} >Opslaan</button>
              </div>
            </div>
        </Modal>

        {console.log(shedule)}

        {/* Absent modal */}
        <Modal
        isOpen={absentModalOpen}
        onRequestClose={absentModalOpen}
        style={modalStyles}
        contentLabel="Definitief verwijderen"
        >
            <div>
                <div>
                    <h2>Afwezig</h2>
                    <div id='dashboard-presence-name-container'>
                        <PersonaNameMeta personaID={shedule.PersonaID} />
                    </div>
                    <div id='persona-dashboard-presence-today-container'>
                        <p id='persona-dashboard-day-name'>{capitalizedDutchDayName}</p>
                        <p>{longName}</p>
                    </div>
                </div>

                {/* <div id='dashboard-presence-times-container'>
                  <p>{presence.StartTime}</p>
                  <p>-</p>
                  <p>{presence.EndTime}</p>
                </div> */}
               
                <p>Reden afwezigheid</p>
                <select name="" id="" onChange={(e) => setAbsentReason(e.target.options[e.target.selectedIndex].value)}>
                    <option value="">-- Selecteer reden afwezigheid --</option>
                    <option value="ziek">Ziek</option>
                    <option value="verlof">Verlof</option>
                    <option value="overig">Overig</option>
                </select>
            </div>
            <div id='delete-modal-button-container'>
                <button onClick={() => setAbsentModalOpen(false)}>Annuleren</button>
                <button id='delete-modal-delete-button' data-personaid={shedule.name} onClick={saveAbsent} >Opslaan</button>
            </div>
        </Modal>
    </div>
  )
}

export default DashboardPresenceCheckComponent