import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import TotalResponses from '../TotalResponses';

const MatrixDevelopmentPieChart = ({ data, lowerScale, upperScale, totalResponses }) => {
  const [hoveredSlice, setHoveredSlice] = useState(null);

  // Ensure data is not empty before processing
  if (!data || data.length === 0) {
    return <p>Geen data aanwezig</p>;
  }

  // Extract unique keys for dynamic data
  const uniqueRowTitles = Object.keys(data[0]).filter((key) => key !== "name");

  // Transform data for PieChart
  const processData = (dataItem) => {
    return Object.keys(dataItem)
      .filter((key) => key !== "name")
      .map((key) => ({
        name: key,
        value: dataItem[key].value,
        color: dataItem[key].color,
      }));
  };

  // Calculate total growth normalized to the scale
  const calculateTotalGrowth = () => {
    const totalValues = data.map((item) =>
      uniqueRowTitles.reduce((sum, key) => {
        const value = item[key]?.value;
        const adjustedValue =
          item[key]?.negative && value !== undefined
            ? upperScale - value + lowerScale
            : value ?? 0;
        return sum + adjustedValue;
      }, 0)
    );

    if (totalValues.length > 1) {
      const initialTotal = totalValues[0];
      const finalTotal = totalValues[totalValues.length - 1];
      const maxTotal = uniqueRowTitles.length * (upperScale - lowerScale);

      return ((finalTotal - initialTotal) / maxTotal) * 100;
    }
    return 0;
  };

  const totalGrowth = calculateTotalGrowth().toFixed(1);

  // Custom Legend
  const CustomizedLegend = ({ payload }) => (
    <ul>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          onMouseEnter={() => setHoveredSlice(entry.payload.name)}
          onMouseLeave={() => setHoveredSlice(null)}
          style={{
            cursor: "pointer",
            fontWeight: hoveredSlice === entry.payload.name ? "bold" : "normal",
            color: entry.color,
          }}
        >
           {entry.payload.name} ({entry.payload.value})
        </li>
      ))}
    </ul>
  );

  // Custom tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid #cccccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p>{payload[0].name}</p>
          <p>
            <strong>Gemiddelde:</strong> {payload[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <h2>Totale groei: {totalGrowth}%</h2>
      <TotalResponses totalResponses={totalResponses} />
      <div style={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
        {data.map((chartData, index) => {
          const processedData = processData(chartData);
          return (
            <div key={index}>
              <h3 style={{ textAlign: "center" }}>{chartData.name}</h3>
              <PieChart width={300} height={650}>
                <Pie
                  data={processedData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                >
                  {processedData.map((entry, idx) => (
                    <Cell key={`cell-${idx}`} fill={entry.color}  fillOpacity={hoveredSlice && hoveredSlice !== entry.name ? 0.3 : 1}/>
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
              </PieChart>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MatrixDevelopmentPieChart;
