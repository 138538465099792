import { db, bucket } from "../../firebase/config.js"
import { useFirestore } from "../../firebase/useFirestore"
import firebase from 'firebase'
import { useContext } from "react";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const Settings = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    ScrollToTop()

    // Firestore
    const compagny = useFirestore("CompagnyMeta")

    const LogoHandler = (e) => {

        const logo = e.target.files[0]

        const storageRef = bucket.ref("/ProfilePhotos/" + logo.name);
        const uploadTask = storageRef.put(logo)

        uploadTask.then(() => {
          
            uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING:
                console.log('Upload is running');
                break;
            }
            }, (err) => {
                alert(err)
            }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log('File available at', downloadURL);

            saveLogo(downloadURL)

                })
            })
        })
    }

    const saveLogo = (banner) => {
        compagny && compagny.forEach(comp => {
            db.collection("CompagnyMeta")
            .doc(comp.docid)
            .update({
                Logo: banner
            })
        })
    }

    const saveName = (e) => {

        const communityName = e.target.value
        const docid = e.target.dataset.docid

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            CommunityName: communityName
        })
        .then(() => {
            setSaved('flex')
         })
    }

    const notificationHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value 
        const docid = e.target.dataset.docid

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            UpdateEmail: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    const primairyColorHandler = (e) => {

        const value = e.target.value
        const docid = e.target.dataset.docid

        console.log(value)

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            PrimaryColor: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    const secundairyColorHandler = (e) => {

        const value = e.target.value
        const docid = e.target.dataset.docid

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            SecundairyColor: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    const tertairyColorHandler = (e) => {

        const value = e.target.value
        const docid = e.target.dataset.docid

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            TertairyColor: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Website
    const WebsiteHandler = (e) => {

        const value = e.target.value
        const docid = e.target.dataset.docid

        db.collection("CompagnyMeta")
        .doc(docid)
        .update({
            Website: value
        })
        .then(() => {
            setSaved('flex')
         })
    }

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Instellingen</h1>
                </div>  
                {compagny && compagny.map(comp => (
                <div className="profile-inner-container" key={comp.ID}>
                    <div className="divider">
                        <h2>Bedrijfsnaam</h2>
                        <input className="input-classic" type="text" data-docid={comp.docid} defaultValue={comp.CommunityName} onChange={saveName} />
                    </div>
                    <div className="divider logo-container">
                        <h2>Logo</h2>
                        <img src={comp.Logo} alt="" />
                        <input className="input-classic" type="file" onChange={LogoHandler} />
                    </div>
                    <div className="divider logo-container">
                        <h2>Website</h2>
                        <input className="input-classic" type="text" data-docid={comp.docid} defaultValue={comp.Website} onChange={WebsiteHandler} />
                    </div>
                    <div className="divider logo-container">
                        <h2>Huisstijl</h2>
                        <div className="settings-color-container">
                            <div className="settings-color-picker-container">
                                <p>Primair</p>
                                <div className="settings-color-box" style={{backgroundColor: comp.PrimaryColor}}></div>
                                <input className="setting-color-picker" type="color" defaultValue={comp.PrimaryColor} data-docid={comp.docid} onChange={primairyColorHandler} />
                                <div className="color-explainer-container">
                                    <p>Gebruikt voor:</p>
                                    <ul>
                                        <li>Grafieken</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="settings-color-picker-container">
                                <p>Secundair</p>
                                <div className="settings-color-box" style={{backgroundColor: comp.SecundairyColor}}></div>
                                <input className="setting-color-picker" type="color" defaultValue={comp.SecundairyColor} data-docid={comp.docid} onChange={secundairyColorHandler} />
                                <div className="color-explainer-container">
                                    <p>Gebruikt voor:</p>
                                    <p className="settings-color-tip-text">Tip: gebruik een donkere kleur</p>
                                    <ul>
                                        <li>Actieve menu item</li>
                                        <li>Pagina titel balk</li>
                                        <li>Tabel titel balk</li>
                                        <li>Grafieken</li>
                                        <li>Knoppen</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="settings-color-picker-container">
                                <p>Tertiair</p>
                                <div className="settings-color-box" style={{backgroundColor: comp.TertairyColor}}></div>
                                <input className="setting-color-picker" type="color" defaultValue={comp.TertairyColor} data-docid={comp.docid} onChange={tertairyColorHandler} />
                                <div className="color-explainer-container">
                                    <p>Gebruikt voor:</p>
                                    <ul>
                                        <li>Grafieken</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="divider">
                        <h2>Notificaties</h2>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>Projectbeheer update email</b></p>
                                <p>De projectbeheer update email herinnert je eraan om resultaten in te vullen en geeft een overzicht van de onderzoeksplanning.</p>
                            </div>
                            <select onChange={notificationHandler} data-docid={comp.docid} defaultValue={comp.Notification}>
                                <option value='never'>Nooit</option>
                                <option value='weekly'>Wekelijks</option>
                                <option value='monthly'>Maandelijks</option>
                                <option value='quarterly'>Per kwartaaal</option>
                                <option value='yearly'>Jaarlijks</option>
                            </select>
                        </div>
                    </div > */}
                    {/* <div className="divider">
                        <h2>Extra functionaliteiten</h2>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>MKBA</b></p>
                                <p>Creëer een maatschappelijke kosten/baten analyse.</p>
                            </div>
                            <ToggleButton collection={'CompagnyMeta'} docid={comp.docid} id={'MKBA'} value={comp.MKBA}/>
                        </div>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>Personas</b></p>
                                <p>Vertel persoonlijke impact verhalen.</p>
                            </div>
                            <ToggleButton collection={'CompagnyMeta'} name={"Personas"} docid={comp.docid} id={'Personas'} value={comp.Personas}/>
                        </div>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>SDG's</b></p>
                                <p>Koppel je impact aan de Social Development Goals van de Verenigde Naties.</p>
                            </div>
                            <ToggleButton collection={'CompagnyMeta'} name={"SDG's"} docid={comp.docid} id={'SDGs'} value={comp.SDGs}/>
                        </div>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>Live rapportage</b></p>
                                <p>Met de live rapportage krijg je een deelbaar rapport met daarin je realtime impact data.</p>
                            </div>
                            <ToggleButton collection={'CompagnyMeta'} name={"Report"} docid={comp.docid} id={'Report'} value={comp.Report}/>
                        </div>
                    </div > */}
                    {/* <div className="divider">
                        <h2>Beta functionaliteiten</h2>
                        <div className="settings-notification-container">
                            <div className="settings-notifications-title-container">
                                <p><b>ImpactAI</b></p>
                                <p>Laat onze impactAI je begeleiden gedurende het gehele impact management proces</p>
                            </div>
                            <ToggleButton collection={'CompagnyMeta'} docid={comp.docid} id={'ImpactAI'} value={comp.ImpactAI}/>
                        </div>
                    </div > */}
                </div>
                ))}
            </div>
        </div>
    )
}

export default Settings
