import Location from "../../hooks/Location"
import { useFirestoreID, useFirestore} from "../../firebase/useFirestore"
import ScrollToTop from "../../hooks/ScrollToTop";
import personIcon from '../../images/icons/person-icon.png'
import activityIcon from '../../images/icons/activity-icon.png'
import trajectIcon from '../../images/icons/traject-icon.png'
import useSettings from "../../hooks/Settings";
import { useState, useEffect } from 'react'
import LiveReportActivities from "../../components/liveReport/LiveReportActivities";
import LiveReportEffects from "../../components/liveReport/LiveReportEffects";
import LiveReportTargetgroupsDashboard from "../../components/liveReport/LiveReportTargetgroupsDashboard";
import LiveReportResearch from "../../components/liveReport/LiveReportResearch";
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';

const LiveReportOpen = () => {
  // State
  const [logo, setLogo] = useState('')

  // Hooks
  const route = Location()[3]
  ScrollToTop()
  const secundairyColor = useSettings().SecundairyColor
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  // Firestore
  const report = useFirestoreID('LiveReports', route)
  const compagny = useFirestore('CompagnyMeta')

  // Set compagny logo
  useEffect(() => {
    compagny && compagny.map(item => (
      setLogo(item.Logo)
    ))
  }, [compagny])

  console.log(report)


  return (
    <>
    {report && report.map(item => (
        <div>

            {/* Banner, logo and title */}
            <div>
                <img className="report-banner" src={item.Banner} alt="" />
                <div id='live-report-logo-period-container'>
                    {/* Logo */}
                    <img className="report-logo" src={logo} alt="" />

                     {/* Periode */}
                     <div id="live-report-open-period-container">
                        <p>{item.Start ? item.Start.toDate().toLocaleDateString("nl-NL", options) : ''}</p>
                        <p>&nbsp; - &nbsp;</p>
                        <p>{item.End ? item.End.toDate().toLocaleDateString("nl-NL", options) : 'Alles'}</p>
                    </div>
                </div>
                <h1 className="report-title" style={{color: secundairyColor}}>{item.Title}</h1>
            </div>

            {/* Description */}
            <div className="report-inner-container">
                <div className="report-head">
                  <p>{item.Description}</p>
                </div>

                {/* Targetgroups */}
                {report[0].Targetgroups?.length > 0 &&
                  <div className="report-section report-section-main">
                      <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                          <img src={personIcon} alt="" />
                          <h2 style={{color: secundairyColor}}>{item.TargetgroupTitle}</h2>
                      </div>
                      <div className="report-activities-container">
                      {report[0].Targetgroups?.map(targetgroup => (
                          <LiveReportTargetgroupsDashboard report={targetgroup}/>
                      ))}
                      </div>
                  </div>
                }

                {/* Activities */}
                {report[0].Activities?.length > 0 &&
                  <div className="report-section report-section-main">
                      <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                          <img src={activityIcon} alt="" />
                          <h2 style={{color: secundairyColor}}>{item.ActivityTitle}</h2>
                      </div>
                      {report[0].Activities?.map(activity => (
                      <LiveReportActivities activity={activity} startDate={item.Start} endDate={item.End}/>
                      ))}
                  </div>
                }

                {/* Effects */}
                {report[0].KPIs?.length > 0 && 
                  <div className="report-section report-section-main">
                      <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                          <img src={trajectIcon} alt="" />
                          <h2 style={{color: secundairyColor}}>{item.KPITitle}</h2>
                      </div>
                      {report[0].KPIs?.map(item => (
                          <LiveReportEffects effect={item} startDate={report[0].Start} endDate={report[0].End}/>
                      ))}
                  </div>
                }

                 {/* Research */}
                 {report[0].Researches?.length > 0 && 
                  <div className="report-section report-section-main">
                      <div className="report-section-title-container" style={{borderBottom: `3px solid lightgray`}}>
                          <BiotechOutlinedIcon />
                          <h2 style={{color: secundairyColor}}>{item.ResearchTitle}</h2>
                      </div>
                      {report[0].Researches?.map(item => (
                        <>
                          {console.log(item)}
                          <LiveReportResearch researchId={item} startDate={report[0].Start} endDate={report[0].End}/>
                          </>
                      ))}
                  </div>
                }
            </div>
        </div>
      ))}
    </> 
  )
}

export default LiveReportOpen