import React from 'react'
import { useFirestoreGeneralArrayContains } from '../../../firebase/useFirestore'
import FieldType from '../../open/FieldType'

const PersonaIndicators = ({effectId}) => {

    const indicators = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '')

  return (
    <div>
        {indicators && indicators.map(item => (
            <div key={item.docid} id='persona-report-indicators-fieldtype-container'>
                {item.Question}
                <FieldType field={item} />
            </div>
        )) }
    </div>
  )
}

export default PersonaIndicators