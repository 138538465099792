import React from 'react'
import { useFirestore } from '../../firebase/useFirestore'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Tooltip from "../../components/common/Tooltip";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useState, useContext } from 'react'
import { db } from "../../firebase/config";
import PersonaMeta from '../personas/PersonaMeta';
import firebase from 'firebase'
import deleteIcon from '../../images/icons/delete-icon.png'

const SelectPersonas = ({event}) => {
   // Context
   const [saved, setSaved] = useContext(SavedIcon)

   // State
   const [editPersona, setEditPersona] = useState(false)

   // Firestore
   const personas = useFirestore('Personas')

   // Update the stakeholder
   const personaHandler = (e) => {

       const value = e.target.options[e.target.selectedIndex].value
       const docid = e.target.dataset.docid

       console.log(value, docid)

       db.collection('Events')
       .doc(docid)
       .update({
           Personas: firebase.firestore.FieldValue.arrayUnion(value)
       })
       .then(() => {
           setSaved('flex')
           setEditPersona(false)
        })

   }

   // Delete the stakeholder
   const deleteHandler = (e) => {

       const persona = e.target.dataset.persona
       const docid = event.docid

       db.collection('Events')
       .doc(docid)
       .update({
           Personas: firebase.firestore.FieldValue.arrayRemove(persona)
       })
       .then(() => {
           setSaved('flex')
        })

   }


 return (
   <div className="table-edit-container">
       <div>
           <div>
               <ul>
               {Array.isArray(event.Personas) ? 
                   event.Personas.length > 0 ? 
                    event.Personas.map(persona => (
                           <li key={persona}>
                               <div id='effects-targetgroups-container'>
                                   <PersonaMeta personaId={persona} />
                                   <img src={deleteIcon} alt="" data-persona={persona} onClick={deleteHandler}/>
                               </div>
                           </li>
                       )) 
                       : 'Geen deelnemers toegevoegd' 
                   : 'Geen deelnemers toegevoegd'
               }
               </ul>
           </div>
           <div className="effect-meta-select-stakeholder-container" style={{display: editPersona ? 'flex' : 'none'}}> 
               <select name="" id="" data-docid={event.docid} onChange={personaHandler} defaultValue={event.Personas}>
                   <option value="">-- Selecteer deelnemer --</option>
                   {personas && personas.map( item => (
                       <option key={item.ID} value={item.ID}>{item.Name}</option>
                   ))}
               </select>
           </div>
       </div>
       <Tooltip content={'Deelnemer aanpassen'} top='-60px' width={'10%'}>
           <CreateOutlinedIcon onClick={() => setEditPersona(!editPersona)}/>
       </Tooltip>
   </div>
 )
}

export default SelectPersonas