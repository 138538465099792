import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import TotalResponses from '../TotalResponses';

const MatrixMomentPieChart = ({ data, totalResponses }) => {
  const [hoveredSlice, setHoveredSlice] = useState(null);

  // Ensure data is not empty before processing
  if (!data || data.length === 0) {
    return <p>Geen data aanwezig</p>;
  }

  // Extract unique keys for dynamic data
  const uniqueRowTitles = Object.keys(data[0]).filter((key) => key !== "name");

  // Aggregate data for the pie chart
  const aggregatedData = uniqueRowTitles.map((rowTitle) => {
    const totalValue = data.reduce((sum, item) => sum + (item[rowTitle]?.value || 0), 0);
    return {
      name: rowTitle,
      value: totalValue,
      color: data[0][rowTitle]?.color || "#206c7f",
    };
  });

  // Custom legend component
  const CustomizedLegend = ({ payload }) => (
    <ul>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          onMouseEnter={() => setHoveredSlice(entry.payload.name)}
          onMouseLeave={() => setHoveredSlice(null)}
          style={{
            cursor: "pointer",
            fontWeight: hoveredSlice === entry.payload.name ? "bold" : "normal",
            color: entry.color,
          }}
        >
          {entry.value} - {entry.payload.name}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <TotalResponses totalResponses={totalResponses} />
      <h3>{data ? data[0].name : ''}</h3>
      <ResponsiveContainer width="100%" height={600}>
        <PieChart>
          <Tooltip />
          <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
          <Pie
            data={aggregatedData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            innerRadius={70}
            onMouseEnter={(data, index) => setHoveredSlice(data.name)}
            onMouseLeave={() => setHoveredSlice(null)}
            isAnimationActive={false}
          >
            {aggregatedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                fillOpacity={hoveredSlice && hoveredSlice !== entry.name ? 0.3 : 1}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default MatrixMomentPieChart;

