import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import TotalResponses from '../TotalResponses';

const ParagraphMomentBarChart = ({ data, totalResponses }) => {

  // Convert the Categories array into the format required for the bar chart
  const barData =
    data && data[0].Categories && data[0].Categories.length > 0
      ? data[0].Categories.map((category, index) => ({
          name: category.Title,
          value: category.Words,
          color: category.Color,
          index: index + 1, // Add an index for the X-axis
        }))
      : [{ name: 'No data', value: 1, color: '#ccc', index: 0 }];

  // Custom tooltip content
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, color } = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <p style={{ margin: 0, fontWeight: 'bold' }}>{name}</p>
          <p style={{ margin: 0, color }}>{`Value: ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Custom legend
  const CustomLegend = ({ payload }) => {
    return (
      <ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <li
            key={`legend-item-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginRight: 10, marginBottom: 5 }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                marginRight: 8,
              }}
            ></div>
            <span>{`${entry.payload.name} (${entry.payload.value})`}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={300}>
        <RechartsBarChart data={barData}>
          <XAxis dataKey="index"/>
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            barSize={30}
          >
            {barData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
      <CustomLegend payload={barData.map((entry) => ({
        color: entry.color,
        payload: entry,
      }))} />
    </div>
  );
};

export default ParagraphMomentBarChart;
