import PageHeader from '../../components/layout/PageHeader';
import CompagnyMeta from '../../components/portfolio/CompagnyMeta';
import Location from "../../hooks/Location"

const ProjectDetails = () => {

  // Hooks
    const projectId = Location()[3]

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Projectdetails'}
                description={`Bekijk de details van je project.`}
                AI={false}
            />
            <div className="table-container dashboard-container">
              <h1>{<CompagnyMeta id={projectId}/>}</h1>
            </div>
        </div>
    </div>
  )
}

export default ProjectDetails