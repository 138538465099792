import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useFirestoreGeneral, useFirestore } from '../../../firebase/useFirestore';
import PersonaNameMeta from '../../../components/personas/PersonaNameMeta';
import PresenceCheckedComponent from './PresenceCheckedComponent';
import { useHistory } from "react-router-dom";
import { client } from "../../../hooks/Client"

const PresenceToday = () => {

    // Hooks
    const today = new Date();
    const dayName = new Intl.DateTimeFormat('en-EN', { weekday: 'long' }).format(today);
    const dayNameLowerCase = dayName.toLowerCase();
    const dutchDayName = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(today);
    const capitalizedDutchDayName = dutchDayName.charAt(0).toUpperCase() + dutchDayName.slice(1);
    const longName = new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(today);
    const history = useHistory()

    // Firestore
    const shedule = useFirestoreGeneral('PersonaShedule', 'Day', dayNameLowerCase);
   

  return (
    <div>
          <div className="title-edit-container home-section-title-container">
            <CalendarTodayOutlinedIcon className='icon' />
            <h2>Vandaag aanwezig</h2>
          </div>
          <div id='persona-dashboard-presence-today-container'>
            <p id='persona-dashboard-day-name'>{capitalizedDutchDayName}</p>
            <p>{longName}</p>
            <div id='persona-dashboard-presence-today-time-container'>
            </div>
          </div>

          <div>
            {shedule && shedule.map((shedule, index) => (
              <div key={index} id='dashboard-presence-container'>
                <div id='dashboard-presence-name-container' onClick={() => history.push(`/${client}/personabuilder/${shedule.PersonaID}`)}>
                  <PersonaNameMeta personaID={shedule.PersonaID}/>
                </div>

                <div id='dashboard-presence-times-container'>
                  <p>{shedule.StartTime}</p>
                  <p>-</p>
                  <p>{shedule.EndTime}</p>
                </div>
                <PresenceCheckedComponent shedule={shedule}/>
              </div>
            ))}
          </div>
        </div>
  )
}

export default PresenceToday