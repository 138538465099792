import { useFirestoreID } from '../../firebase/useFirestore'

const ActivityMeta = ({id}) => {

    const activities = useFirestoreID('Activities', id ? id : '')

  return (
    <>
        {activities && activities.map((item) => (
            <p>{item.Activity}</p>
        ))}
    </>
  )
}

export default ActivityMeta