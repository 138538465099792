import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client";
import CompagnyMeta from '../../components/portfolio/CompagnyMeta'
import { useFirestoreNoCompagnyGeneralTwoOrderBy } from '../../firebase/useFirestore';
import EffectMeta from '../../components/effects/EffectMeta';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import OutputMeta from '../../components/outputs/OutputMeta';

const Notifications = () => {
  // State

  // Hooks
  const history = useHistory()

  // Firestore
  const notifications = useFirestoreNoCompagnyGeneralTwoOrderBy('Notifications', 'Reciever', client, 'Read', false, 'Timestamp', 'desc');

  console.log(notifications)

  // Show sync request
  const showSyncRequest = async (e) => {

    const docid = e.target.dataset.docid
    const id = e.target.dataset.id

    await db.collection('Notifications').doc(docid).update({
      Read: true
    })

    history.push(`/${client}/synchronisationbuilder/${id}`)
  }

  // Accept parent request
  const acceptParentRequest = async (e) => {

    const docid = e.target.dataset.docid
    const parent = e.target.dataset.parent
    
    const compagnySnapshot = await db.collection('CompagnyMeta')
    .where('CompagnyID', '==', client)
    .get()

    compagnySnapshot.docs.forEach(doc => {
      db.collection('CompagnyMeta').doc(doc.id).update({
        Parent: firebase.firestore.FieldValue.arrayUnion(parent)
      })
    })

    await db.collection('Notifications').doc(docid).update({
      Read: true
    })
  }

  // Notification type component
  const NotificationType = ({ type, parent, id, effectId, outputId, syncType, docid }) => {
    if (type === "parent-request") {
      return (
        <div className='notification-item'>
          <h2>Portfolio aanvraag</h2>
          <p>{<CompagnyMeta id={parent}/>} wil {<CompagnyMeta id={client}/>} toevoegen aan zijn porfolio</p>
          <button data-docid={docid} data-parent={parent} onClick={acceptParentRequest}>Accepteren</button>
          <button>Weigeren</button>
        </div>
      );
    }  else if (type === "delete-project") {
      return (
        <div className='notification-item'>
          <h2>Portfolio verwijder verzoek</h2>
          <p>{<CompagnyMeta id={parent}/>} wil {<CompagnyMeta id={client}/>} verwijderen uit zijn portfolio</p>
          <button>Verzoek behandelen</button>
        </div>
      )
    } else if (type === "sync-request") {
      return (
        <div className='notification-item'>
          <h2>Synchronisatie verzoek</h2>
          <p>{<CompagnyMeta id={parent}/>} wil een synchronisatie maken voor zijn {syncType}: {syncType === 'Effect' ? <EffectMeta item={effectId}/> : <OutputMeta id={outputId}/> }</p>
          <button data-id={id} data-docid={docid} onClick={showSyncRequest}>Verzoek behandelen</button>
        </div>
      )
    } else {
      return (
        <div>
         
        </div>
      )
    }
  };

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <NotificationsNoneOutlinedIcon />
                <h1>Notificaties</h1>
            </div>
            <div id='notifications-container'>
              {notifications && notifications.map((notification) => (
                <div key={notification.ID} className='notification-item-container'>
                  {<NotificationType 
                  type={notification.Type} 
                  parent={notification.Sender} 
                  id={notification.SyncId}
                  effectId={notification.Effect}
                  outputId={notification.Output}
                  syncType={notification.SyncType}
                  docid={notification.docid}
                  />
                  }
                </div>
              ))}
            </div>
        </div>
    </div>
  )
}

export default Notifications