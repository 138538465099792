import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import spinner from '../../../images/spinner-ripple.svg'

const DashboardLineGraphSmall = ({data, isLoading, color}) => {

  return (
    <>
      {isLoading ? 
        <div id="graph-loading-container">
            <img src={spinner} alt="spinner" className="spinner"/>
        </div>
      :
        <ResponsiveContainer width='100%' height={200}>
          <LineChart
          width={125}
          height={100}
          data={data && data}
          margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
          }}
          >
              
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip/>
                <Line type="monotone" dataKey='Resultaat' stroke={color} strokeWidth='3'/>
          </LineChart>
        </ResponsiveContainer>
    }
    </>
  )
}

export default DashboardLineGraphSmall